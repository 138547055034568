import { dispatcher } from "../App";
import { HStore } from "../data/abc/record";

export type ProgressEvent = (progress: number) => void;

export type NamespaceMeta = {
    oid: number
    name: string
    etag: string
}

export const RELKIND_TABLE = "r"
export const RELKIND_VIEW = "v"
export const RELKIND_INDEX = "i"
 
export type RelationKind =  typeof RELKIND_TABLE | typeof RELKIND_VIEW| typeof RELKIND_INDEX

export const ON_UPDATE_NO_ACTION = "a"
export const ON_UPDATE_RESTRICT = "r"
export const ON_UPDATE_CASCADE = "c"
export const ON_UPDATE_SET_NULL = "n"
export const ON_UPDATE_SET_DEFAULT = "d"

export type FkRule = typeof ON_UPDATE_NO_ACTION 
    | typeof ON_UPDATE_RESTRICT 
    | typeof ON_UPDATE_CASCADE 
    | typeof ON_UPDATE_SET_NULL 
    | typeof ON_UPDATE_SET_DEFAULT 

type RelationMetaRaw = {
    schema_oid: number
    oid: number
    kind: RelationKind
    name: string
    full_name: string
    attrs: AttributeMetaRaw[]
    references: number[] // oids
    referenced_by: number[] // oids
    etag: string

    title: HStore
}

type IndexByName = { [name:string] : number }
type IndexByColno = { [colno:number] : number }

export class RelationMeta {
    public readonly schema_oid: number
    public readonly oid: number
    public readonly kind: RelationKind
    public readonly name: string
    public readonly full_name: string
    public readonly attrs: AttributeMeta[]
    public readonly references: FkMeta[]
    public readonly referenced_by: FkMeta[]
    public readonly etag: string

    title: HStore

    // calculated from RelationMetaRaw
    public readonly attr_nti : IndexByName // attribute name to idx
    public readonly attr_cnti : IndexByColno // attribute colno to idx
    public readonly show_attr_idxs: number[] // contains a list of show_index>0 attributes, ordered by show_index

    public readonly db_meta : Meta

    constructor(raw: RelationMetaRaw, db_meta : Meta) {
        this.schema_oid = raw.schema_oid;
        this.oid = raw.oid;
        this.kind = raw.kind;
        this.name = raw.name;
        this.full_name = raw.full_name;
        this.attrs = raw.attrs.map(amr => new AttributeMeta(amr, db_meta));
        this.references = raw.references.map(fkoid => db_meta.get_fk_meta(fkoid));
        this.referenced_by = raw.referenced_by.map(fkoid => db_meta.get_fk_meta(fkoid));
        this.etag = raw.etag;
        this.title = raw.title
        let attr_nti : IndexByName = {}
        let attr_cnti : IndexByColno = {}
        let sidx = []
        for (let i=0; i<this.attrs.length; i++) {
            const attr = this.attrs[i];
            attr_nti[attr.name] = i;
            attr_cnti[attr.no] = i;
            if (attr.show_index && attr.show_index>0) {
                sidx.push([attr.show_index, i])
            }
        }
        this.attr_nti = attr_nti
        this.attr_cnti = attr_cnti
        this.show_attr_idxs = sidx.sort((a,b)=>(a[0]-b[0])).map(i=>i[1])

        this.db_meta = db_meta
    }

    public attr_idx(name:string) : number|null {
        const idx = this.attr_nti[name]
        return (idx===undefined)?null:idx
    }

    public attr(name: string) : AttributeMeta | null {
        const idx = this.attr_idx(name)
        return idx===null?null:this.attrs[idx]
    }

    public has_attr(name: string) : boolean {
        return this.attr_idx(name) !== null
    }

    // TODO: make this dependent on the current language!
    public get displaylabel() : string {
        if (this.title && this.title['en']) {
            return this.title['en']
        } else {
            return this.name
        }
    }
}

type AttributeMetaRaw = {
    relation_oid: number
    name: string
    pg_name: string
    type: string
    n_dims: number
    not_null: boolean
    no: number
    references: number | null // oid
    title: HStore
    show_index : number|null
}

export class AttributeMeta {
    db_meta: Meta

    relation_oid: number
    name: string
    pg_name: string
    type: string
    n_dims: number
    not_null: boolean
    no: number
    references: FkMeta | null
    title: HStore
    show_index : number|null    


    constructor(raw: AttributeMetaRaw, db_meta: Meta) {
        this.db_meta = db_meta

        this.relation_oid = raw.relation_oid;
        this.name = raw.name;
        this.pg_name = raw.pg_name;
        this.type = raw.type;
        this.n_dims = raw.n_dims;
        this.not_null = raw.not_null;
        this.no = raw.no;
        this.references = raw.references?db_meta.get_fk_meta(raw.references):null;
        this.title = raw.title;
        this.show_index = raw.show_index;
    }

    // TODO: make this dependent on the current language!
    public get displaylabel() : string {
        if (this.title && this.title['en']) {
            return this.title['en']
        } else {
            return this.name
        }
    }

    // TODO: store this in meta.attr and get back in AttributeMeta!
    public get description() : string {
        return ""
    }

    // TODO: store this in meta.attr and get back in AttributeMeta!
    public get hint() : string {
        return ""
    }

    // TODO: store this in meta.attr and get back in AttributeMeta!
    public get readonly(): boolean {
        return false;
    }

    // TODO: store this in meta.attr and get back in AttributeMeta!
    public get immutable(): boolean {
        return false;
    }

    // TODO: this should return an auto-view for the table!
    public get lookupView(): number  {
        return this.relation_oid;
    }    

    // TODO!
    public get lookupfilter(): string {
        return ""
    }

}

type FkMetaRaw = {
    oid: number
    name: string
    referring: number
    referenced: number
    index_oid: number | null
    is_deferrable: boolean
    on_update: FkRule
    on_delete: FkRule
    referring_colnos: number[]
    referred_colnos: number[]
    etag: string
}

export class FkMeta {
    db_meta: Meta

    oid: number
    name: string
    referring: number
    referenced: number
    index_oid: number | null
    is_deferrable: boolean
    on_update: FkRule
    on_delete: FkRule
    referring_colnos: number[]
    referred_colnos: number[]
    etag: string


    constructor(raw: FkMetaRaw, db_meta: Meta) {
        this.db_meta = db_meta

        this.oid = raw.oid
        this.name = raw.name
        this.referring = raw.referring
        this.referenced = raw.referenced
        this.index_oid = raw.index_oid
        this.is_deferrable = raw.is_deferrable
        this.on_update = raw.on_update
        this.on_delete = raw.on_delete
        this.referring_colnos = raw.referring_colnos
        this.referred_colnos = raw.referred_colnos
        this.etag = raw.etag

    }

    private _refattrs(reloid:number, colnos:number[]) : AttributeMeta[] {
        const rel = this.db_meta.get_relation_meta(reloid)
        return colnos.map(colno => rel.attrs[rel.attr_cnti[colno]])
    }

    public referring_attrs() : AttributeMeta[] {
        return this._refattrs(this.referring, this.referring_colnos)
    }

    public referred_attrs() : AttributeMeta[] {
        return this._refattrs(this.referenced, this.referred_colnos)
    }

}

type EtagByOid = { [oid: number]: string }

export class Meta {
    private _ns_cache: Map<number, NamespaceMeta>
    private _rel_cache: Map<number, RelationMeta>
    private _fk_cache: Map<number, FkMeta>

    private _rel_oid_by_name: Map<string, number>

    constructor() {
        this._ns_cache = new Map();
        this._rel_cache = new Map();
        this._fk_cache = new Map();
        this._rel_oid_by_name = new Map();
    }

    /* Metas are preloaded via the sync_all method. */

    public get_namespace_meta(oid: number) : NamespaceMeta {
        const result = this._ns_cache.get(oid);
        // Fail early.
        if (!result) {
            throw new Error(`Meta.get_namespace_meta: no such relation: ${oid}`);
        }
        return result;
    }

    private loadNamespaceMetaFromLocalStorage(oid: number): NamespaceMeta | null {
        const key = "meta.ns." + oid;
        const dump = localStorage.getItem(key);
        if (dump) {
            return JSON.parse(dump) as NamespaceMeta;
        } else {
            return null;
        }
    }    

    private saveNamespaceMetaToLocalStorage(meta: NamespaceMeta) {
        const key = "meta.ns." + meta.oid;
        localStorage.setItem(key, JSON.stringify(meta));
    }


    public get_relation_meta(oid: number) : RelationMeta {
        const result = this._rel_cache.get(oid);
        // Fail early.
        if (!result) {
            throw new Error(`Meta.get_relation_meta: no such relation: ${oid}`);
        }
        return result;
    }

    public get_relation_oid_by_name(full_name: string) : number {
        const oid = this._rel_oid_by_name.get(full_name);
        // Fail early.
        if (!oid) {
            throw new Error(`Meta.get_relation_oid_by_name: no such relation: ${full_name}`);
        }
        return oid;
    }

    public get_relation_meta_by_full_name(full_name: string) : RelationMeta {
        return this.get_relation_meta(this.get_relation_oid_by_name(full_name));
    }

    private loadRelationMetaFromLocalStorage(oid: number): RelationMetaRaw | null {
        const key = "meta.rel." + oid;
        const dump = localStorage.getItem(key);
        if (dump) {
            return JSON.parse(dump) as RelationMetaRaw;
        } else {
            return null;
        }
    }    

    private saveRelationMetaToLocalStorage(meta: RelationMetaRaw) {
        const key = "meta.rel." + meta.oid;
        localStorage.setItem(key, JSON.stringify(meta));
    }


    public get_fk_meta(oid: number) : FkMeta {
        const result = this._fk_cache.get(oid);
        // Fail early.
        if (!result) {
            throw new Error(`Meta.get_fk_meta: no such fk: ${oid}`);
        }
        return result;
    }

    private loadFkMetaFromLocalStorage(oid: number): FkMetaRaw | null {
        const key = "meta.fk." + oid;
        const dump = localStorage.getItem(key);
        if (dump) {
            return JSON.parse(dump) as FkMeta;
        } else {
            return null;
        }
    }    

    private saveFkMetaToLocalStorage(meta: FkMetaRaw) {
        const key = "meta.fk." + meta.oid;
        localStorage.setItem(key, JSON.stringify(meta));
    }

    /* Synchronize table structures with the server. */
    public sync_all = async (onProgress:ProgressEvent): Promise<void> => {
        try {
            /* First we load the expired fk metas */
            const fk_etags: EtagByOid = await dispatcher.call<EtagByOid>("meta.get_fk_etags")
            const fk_oids : number[] = [];
            this._fk_cache.clear()
            for (let s_oid in fk_etags) {
                const etag: string = fk_etags[s_oid];
                let expired: boolean = true;
                let oid = parseInt(s_oid)
                const cached = this.loadFkMetaFromLocalStorage(oid);
                if (cached) {
                    expired = (cached.etag !== etag);
                }
                if (expired) {
                    fk_oids.push(oid);
                } else {
                    this._fk_cache.set(oid, new FkMeta(cached!, this));
                }
            }
            let total = fk_oids.length;
            let processed = 0.0;

            if (fk_oids.length) {                
                const raw_metas = new Map<number, FkMetaRaw>();
                for (let i=0; i<fk_oids.length; i++) {
                    processed += 1;
                    onProgress(processed / total);
                    const oid = fk_oids[i];
                    const meta = await dispatcher.call<FkMetaRaw>("meta.get_fk_meta", {oid} );
                    raw_metas.set(oid, meta)
                }
                for (let raw_meta of raw_metas.values()) {
                    this.saveFkMetaToLocalStorage(raw_meta);
                    const meta = new FkMeta(raw_meta, this)
                    this._fk_cache.set(meta.oid, meta);
                }
            }   


            /* Then we can load the expired rel metas, they reference fk metas when created */
            const ns_etags: EtagByOid = await dispatcher.call<EtagByOid>("meta.get_namespace_etags")
            const rel_etags: EtagByOid = await dispatcher.call<EtagByOid>("meta.get_relation_etags")

            const ns_oids : number[] = [];
            this._ns_cache.clear()
            for (let s_oid in ns_etags) {
                const etag: string = ns_etags[s_oid];
                let expired: boolean = true;
                let oid = parseInt(s_oid)
                const cached = this.loadNamespaceMetaFromLocalStorage(oid);
                if (cached) {
                    expired = (cached.etag !== etag);
                }
                if (expired) {
                    ns_oids.push(oid);
                } else {
                    this._ns_cache.set(oid, cached!);
                }
            }
            const rel_oids : number[] = [];
            this._rel_cache.clear()
            this._rel_oid_by_name.clear()
            for (let s_oid in rel_etags) {
                const etag: string = rel_etags[s_oid];
                let expired: boolean = true;
                let oid = parseInt(s_oid)
                const cached = this.loadRelationMetaFromLocalStorage(oid);
                if (cached) {
                    expired = (cached.etag !== etag);
                }
                if (expired) {
                    rel_oids.push(oid);
                } else {
                    const rm = new RelationMeta(cached!, this);
                    this._rel_cache.set(oid, rm);
                    this._rel_oid_by_name.set(cached!.full_name, oid)
                }
            }

            total = ns_oids.length + rel_oids.length;
            processed = 0.0;

            if (ns_oids.length) {                
                const metas = new Map<number, NamespaceMeta>();
                for (let i=0; i<ns_oids.length; i++) {
                    processed += 1;
                    onProgress(processed / total);
                    const oid = ns_oids[i];
                    const meta = await dispatcher.call<NamespaceMeta>("meta.get_namespace_meta", {oid} );
                    metas.set(oid, meta)
                }
                for (let meta of metas.values()) {
                    this.saveNamespaceMetaToLocalStorage(meta);
                    this._ns_cache.set(meta.oid, meta);
                }
            }

         

            if (rel_oids.length) {                
                const raw_metas = new Map<number, RelationMetaRaw>();
                for (let i=0; i<rel_oids.length; i++) {
                    processed += 1;
                    onProgress(processed / total);
                    const oid = rel_oids[i];
                    const raw_meta = await dispatcher.call<RelationMetaRaw>("meta.get_relation_meta", {oid} );
                    raw_metas.set(oid, raw_meta)
                }
                for (let raw_meta of raw_metas.values()) {
                    this.saveRelationMetaToLocalStorage(raw_meta);
                    let meta = new RelationMeta(raw_meta, this);
                    this._rel_cache.set(meta.oid, meta);
                    this._rel_oid_by_name.set(meta.full_name, meta.oid);
                }
            }        
            
        } catch (error) {
            return Promise.reject(error);
        }
    }    


}
