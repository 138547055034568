
import { Alignment, Button, Classes, MaybeElement, Menu, MenuItem, Navbar, Popover } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import React from 'react';
import { ROUTE_MJ_DL_QUEUE, ROUTE_MJ_REV1, ROUTE_PRODUCT_COMMUNITY_REVIEW, ROUTE_PRODUCT_KEYPHRASE, ROUTE_PRODUCT_MASS_KEYPHRASE, ROUTE_PRODUCT_PROMPT, ROUTE_PROFILE, ROUTE_SEARCH_PRODUCT, ROUTE_SEARCH_PRODUCT_PRICE, ROUTE_SYS_USER } from './Routes';

import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { appStore } from '../store/AppStore';
import { rootNavigate } from './CustomRouter';

interface MainHeaderProps {
  onLogout: () => void;
}

interface INavButton {
  text: string;
  title?: string;
  icon: IconName | MaybeElement;
  to?: string | null;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}
const NavButton: React.FC<INavButton> = ({ title, text, icon, to, onClick }) =>
  <Button
    className={Classes.BUTTON} large={true} minimal={true}
    icon={icon} title={title}
    onClick={
      (event: React.MouseEvent<HTMLElement>) => {
        if (to) {
          window.history.pushState(undefined, "", to);
        } else if (onClick) {
          onClick(event);
        }
      }
    }><span className="header-button-text">{text}</span></Button>;

/*
interface INavItem {
  text: string;
  title?: string;
  icon: IconName | MaybeElement;
  to?: string | null;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}
*/

const NavItem: React.FC<INavButton> = ({ title, text, icon, to, onClick }) => {
  return <MenuItem
    text={text} icon={icon} title={title}
    onClick={(event: React.MouseEvent<HTMLElement>) => {
      if (to) {
        rootNavigate(to);
      } else if (onClick) {
        onClick(event);
      }
    }} />;
}

@observer
export default class MainHeader extends React.PureComponent<MainHeaderProps> {

  constructor(props: MainHeaderProps) {
    super(props)
    makeObservable(this, {})
  }

  // TODO: auto login after inactivity, add countdown!
  render() {
    return <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading title="IMA">
          <span className="orbitron">IMA</span>
        </Navbar.Heading>
        <Navbar.Divider />
        <Popover content={
          <Menu large={true}>
            <NavItem text="Review MidJourney Images" icon={IconNames.FLOW_REVIEW} to={ROUTE_MJ_REV1} />
            <NavItem text="Search products" icon={IconNames.SEARCH} to={ROUTE_SEARCH_PRODUCT} />
            <NavItem text="Search prices" icon={IconNames.SEARCH} to={ROUTE_SEARCH_PRODUCT_PRICE} />
            <NavItem text="Review Community" icon={IconNames.EYE_OPEN} to={ROUTE_PRODUCT_COMMUNITY_REVIEW} />
            <NavItem text="MJ Download queue" icon={IconNames.LIST_DETAIL_VIEW} to={ROUTE_MJ_DL_QUEUE} />
            <NavItem text="Keyphrase" icon={IconNames.TAG} to={ROUTE_PRODUCT_KEYPHRASE} />
            <NavItem text="Mass keyphrase add" icon={IconNames.TEXT_HIGHLIGHT} to={ROUTE_PRODUCT_MASS_KEYPHRASE} />
            <NavItem text="Prompts" icon={IconNames.TEXT_HIGHLIGHT} to={ROUTE_PRODUCT_PROMPT} />



            {/*
            <MenuDivider />
            <NavItem text="SMTP servers" icon={Api.smtpServerCrud.icon()} to={ROUTE_SMTP_SERVER} />
            <NavItem text="IMAP servers" icon={Api.imapServerCrud.icon()} to={ROUTE_IMAP_SERVER} />
            <NavItem text="Filter chains" icon={Api.filterChainCrud.icon()} to={ROUTE_FILTER_CHAIN} />
            <NavItem text="Kanboard accounts" icon={Api.kanboardAccountCrud.icon()} to={ROUTE_KANBOARD_ACCOUNT} />
            <MenuDivider />
            <NavItem text="IMAP folders" icon={Api.imapFolderCrud.icon()} to={ROUTE_IMAP_FOLDER} />
            <MenuDivider />
        */}
          </Menu>
        }>
          <NavButton text="Product" icon={IconNames.BARCODE} />
        </Popover>
        <Popover content={
          <Menu large={true}>
            <NavItem text="Users" icon={IconNames.USER} to={ROUTE_SYS_USER} />
          </Menu>
        }>
          <NavButton text="Sys" icon={IconNames.APPLICATION} />
        </Popover>
        <Navbar.Divider />
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Popover content={
          <Menu large={true}>
            <NavItem text={appStore.me ? appStore.me.fullname : "Profile"} icon={IconNames.USER} to={ROUTE_PROFILE} />
            <MenuItem text="Logout"
              title="Logout"
              icon={IconNames.LOG_OUT} onClick={this.props.onLogout} />
          </Menu>
        }
        >
          <NavButton text="" icon={IconNames.USER} />
        </Popover>

      </Navbar.Group>
    </Navbar>
  }
}
