import React from "react";

import { Intent, Toaster } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

const toaster = Toaster.create();
export const showError = (error: JSX.Element | string | null | unknown, title?: string) => {
    let errorMessage : string;
    if (error && (typeof error === "object") && error.hasOwnProperty("message") && (error as any).message ) {
        errorMessage = (error as any).message;
    } else {
        errorMessage = "" + error;
    }
    toaster.show({
        action: {
            icon: IconNames.WARNING_SIGN,
            intent: Intent.DANGER,
        },
        intent: Intent.DANGER,
        message: <p>{title?<><strong>{title}</strong><br/></>:null}{errorMessage}</p>,
    });
};

export const showSuccess = (message: JSX.Element | string | null, title?: string) => {
    toaster.show({
        action: {
            icon: IconNames.TICK,
            intent: Intent.SUCCESS,
        },
        intent: Intent.SUCCESS,
        message: <p>{title?<><strong>{title}</strong><br/></>:null}{message}</p>,
    });
};

export const showWarning = (message: JSX.Element | string | null, title?: string) => {
    toaster.show({
        action: {
            icon: IconNames.WARNING_SIGN,
            intent: Intent.WARNING,
        },
        intent: Intent.WARNING,
        message: <p>{title?<><strong>{title}</strong><br/></>:null}{message}</p>,
    });
};
