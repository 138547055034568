import * as React from "react";
import RecordSource from "../../../store/record/RecordSource";
import { appStore } from "../../../store/AppStore";
import { showSuccess } from "../../../dialog/Notification";
import { ConfirmDialog } from "../../../dialog/ConfirmDialog";
import { IComponentCollection } from "../layout/ComponentCollection";
import { RelationMeta } from "../../../api/Meta";

export interface IRecordSourceRelatedProps {
    oid: number;
    /** 
     * Use storagePath to define an alternative storage path.
     * It determines the location of the underlying RecordSource object as well
     * as the configuration key prefix for local RecordSource configuration.
     */
    storagePath?: string;
    /** Inject components. */
    components?: IComponentCollection;
}

export class RecordSourceRelated extends React.Component<IRecordSourceRelatedProps, {}> {
    constructor(props: IRecordSourceRelatedProps) {
        super(props);
        // This should be a PureComponent, but it seems that MobX generates shouldComponentUpdate methods
        // for observables. For this reason, we HAVE TO inherit from React.Component and create
        // a dummy state.
        this.state = {};
    }


    /** Get storage path for the connected recordSource. It also determines the configuration key prefix. */
    public get storagePath(): string {
        return this.props.storagePath || "" + this.props.oid;
    }

    protected get recordSource(): RecordSource {
        return appStore.getRecordSource(this.storagePath, this.props.oid);
    }

    protected get meta(): RelationMeta {
        return this.recordSource.meta;
    }

    protected get record() {
        return this.recordSource.record;
    }

    protected get recordId() {
        return this.recordSource.recordId;
    }

    protected get recordExists() {
        return this.recordSource.exists;
    }

    protected get hasIsActive() {
        return this.recordSource.hasIsActive;
    }

    protected get isActive() {
        return this.recordSource.isActive;
    }

    protected get hasChange() {
        return this.recordSource.hasChange;
    }

    protected saveChanges = () => {
        this.recordSource.saveRecord().then(() => { showSuccess(null, "Adatok elmentve."); });
    }

    protected deleteRecord = async () => {
        if (await ConfirmDialog.open("Megerősítés", "Biztos benne, hogy törölni akarja ezt a tételt?")) {
            this.recordSource.deleteRecord();
        }
    }

    protected undeleteRecord = async () => {
        if (await ConfirmDialog.open("Megerősítés", "Biztos benne, hogy újra aktiválja ezt a tételt?")) {
            this.recordSource.undeleteRecord();
        }
    }

}
