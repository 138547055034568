import * as React from 'react';
import { observer } from 'mobx-react';
import { ViewSourceRelated, IViewSourceRelatedProps } from './ViewSourceRelated';
import { InputGroup, Button, Intent, Popover, Menu, MenuItem, Callout, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { showSuccess } from '../../../dialog/Notification';
import { KEY_UP, KEY_DOWN } from '../../../keyboard';
import { toJS } from 'mobx';

export interface IViewFilterFrameProps extends IViewSourceRelatedProps {
    onItemSelected?: () => void;
    onEscPressed?: () => void;
}

@observer
export class ViewFilterFrame extends ViewSourceRelated<IViewFilterFrameProps> {
    private keyboardListening: boolean = false;

    private onSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.viewSource.simpleSearch = event.target.value;
    };

    private setActiveFilter = (value: boolean | null) => {
        this.viewSource.activeFilter = value;
        this.viewSource.saveConfig().then(() => showSuccess(null, "Filters saved."));
    }

    private getActiveMenu = () => {
        if (this.viewSource.hasIsActiveColumn) {
            const activeFilter = this.viewSource.activeFilter;
            const icon =
                activeFilter === true ? IconNames.EYE_ON
                    : activeFilter === false ? IconNames.EYE_OFF
                        : IconNames.EYE_OPEN;
            const iconTitle =
                activeFilter === true ? "Only active rows are shown"
                    : activeFilter === false ? "Only inactive rows are shown"
                        : "Active and inactive rows are both shown";

            const iconIntent =
                activeFilter === true ? Intent.SUCCESS
                    : activeFilter === false ? Intent.DANGER
                        : Intent.WARNING;
            return <Popover content={
                <Menu large={true} key="active-filter-button">
                    <MenuItem text={"Show active rows only" + (activeFilter === true ? " ✔" : "")} icon={IconNames.EYE_ON} intent={Intent.SUCCESS}
                        onClick={() => { this.setActiveFilter(true); }}
                    />
                    <MenuItem text={"Show inactive rows only" + (activeFilter === false ? " ✔" : "")} icon={IconNames.EYE_OFF} intent={Intent.DANGER}
                        onClick={() => { this.setActiveFilter(false); }}
                    />
                    <MenuItem text={"Show both active and inactive rows" + (activeFilter === null ? " ✔" : "")} icon={IconNames.EYE_OPEN} intent={Intent.WARNING}
                        onClick={() => { this.setActiveFilter(null); }}
                    />
                </Menu>
            }>
                <Button
                    icon={icon}
                    title={iconTitle}
                    intent={iconIntent}
                    minimal={true}
                />
            </Popover>;
        } else {
            return null;
        }
    }

    private onKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === KEY_UP) {
            this.viewSource.prev();
            event.stopPropagation()
        } else if (event.keyCode === KEY_DOWN) {
            this.viewSource.next();
            event.stopPropagation()
        } else if (event.key.toLowerCase() === "enter") {
            if (this.props.onItemSelected) {
                this.props.onItemSelected();
            }
            event.stopPropagation()
        } else if (event.key.toLowerCase() === "escape") {
            if (this.props.onEscPressed) {
                this.props.onEscPressed();
            }
            event.stopPropagation()
        }/* else {
            console.log(event);
            console.log(event.key);
            console.log(event.keyCode);
        }*/
    }

    public render() {
        if (this.viewSource.needMasterFilterColumn && !this.viewSource.masterIsValid) {
            const vm = this.meta
            const cm = vm.attrs[vm.attr_nti[this.viewSource.needMasterFilterColumn]]
            const detailLabel = cm.displaylabel || 'main record';

            return <Callout intent={Intent.WARNING}>
                This interface can only be opened if there is a connected
            &nbsp; <Tag intent={Intent.PRIMARY}>{detailLabel}</Tag>
            </Callout>;
        }

        const refreshButton = <Button
            icon={IconNames.REFRESH}
            title="Reload table"
            intent={Intent.NONE}
            minimal={true}
            onClick={() => this.viewSource.reload().then(() => showSuccess(null, "Table reloaded."))}
        />

        const actions = <>
            {this.getActiveMenu()}
            {refreshButton}
        </>;

        // InputGroup is not observer...
        const value = toJS(this.viewSource.simpleSearch);
        return <InputGroup
            leftIcon={IconNames.SEARCH}
            large={true}
            placeholder="Type in text to search"
            type="text"
            value={value}
            onChange={this.onSearchText}
            rightElement={actions}
            autoFocus={true}
            onKeyDown={this.onKeyDown}
        />;


    }
}
// onFocus={this.captureKeys} onBlur={this.unCaptureKeys}