import { observer } from "mobx-react";
import * as React from "react";
import { WithRouterProps, withRouter } from "../../navigation/WithRouter";

import { IconNames } from '@blueprintjs/icons';

import { Button, Callout, EditableText, FormGroup, Intent, Tag } from "@blueprintjs/core";
import { observable, reaction, runInAction } from "mobx";
import { Col, Container, Row } from "react-grid-system";
import { dispatcher } from "../../App";
import { Api } from "../../api/Api";
import { RecordId } from "../../api/Record";
import { IViewFilter } from "../../api/View";
import { ViewGridFrame } from "../../components/frames/view/ViewGridFrame";
import { AsyncViewSelect } from "../../components/inputs/AsyncViewSelect";
import { ConfirmDialog } from "../../dialog/ConfirmDialog";
import { showError, showSuccess, showWarning } from "../../dialog/Notification";
import { appStore } from "../../store/AppStore";
import "./ProductDetailPage.scss";

type PromptProblem = {
    problem: string
    prompt: string
}

const keyphraseId = observable.box<RecordId | null>(null);
const promptsFilter = observable.box<IViewFilter>({ equals: {} })
const promptLines = observable.box<string>("")
const problems = observable.box<PromptProblem[]>([])
const saving = observable.box<boolean>(false)
const setSaving = (value: boolean) => { runInAction(() => saving.set(value)) }

const masterFilter = observable.box<IViewFilter>({ equals: {} });


const VIEWSOURCE_PATH = "product_prompt"

const get_prompts_view_oid = () => {
    return Api.meta.get_relation_oid_by_name("product.av_prompt")
}

const getViewSource = () => {
    return appStore.getViewSource(VIEWSOURCE_PATH, get_prompts_view_oid(), masterFilter.get(), { autoReload: false })
}

reaction(
    () => keyphraseId.get(),
    () => {
        console.log(keyphraseId.get())
        runInAction(() => {
            console.log("keyphraseId changed")
            const mf = masterFilter.get();
            mf.equals = { keyphrase_id: keyphraseId.get() };
            masterFilter.set(mf);
            getViewSource().reload()
        })
    }
)


const canRemovePrompt = () => {
    const vs = getViewSource()
    const row = vs.currentRow
    if (!row) { return false }
    if (row.started) { return false }
    return true;
}


const removePrompt = async () => {
    if (! await ConfirmDialog.open(
        "Confirm",
        "Do you wish to remove this prompt?")) {
        return
    }
    const vs = getViewSource()
    const row = vs.currentRow
    if (!row) { return }

    try {
        setSaving(true)
        await dispatcher.call("product.prompt_remove", { prompt_id: row.id })
        runInAction(() => {
            setSaving(false)
            showWarning("Prompt deleted")
            getViewSource().reload()
        })
    } catch (error) {
        setSaving(false)
        showError(error)
    }
}



interface Params { }
type ProductPromptProps = WithRouterProps<Params>;


@observer
class ProductPromptPageInner extends React.PureComponent<ProductPromptProps> {

    PromptLinesChanged = (value: string) => {
        runInAction(() => {
            promptLines.set(value);
        })
    }

    addAllPrompts = async () => {
        let prompts = promptLines.get().split("\n").map(s => s.trim()).filter(s => !!s)
        if (!prompts.length) {
            showError("Please type in some prompts")
            return
        }
        try {
            const prb = await dispatcher.call<PromptProblem[]>("product.add_prompts", {
                keyphrase_id: keyphraseId.get(),
                prompts
            })
            runInAction(() => {
                promptLines.set("")
                problems.set(prb)
            })
            getViewSource().reload()
            if (prb.length === 0) {
                showSuccess("Prompt(s) added")
            } else {
                showWarning(`There were ${prb.length} problems`)
            }

        } catch (e) {
            showError(e)
        }
    }

    render(): React.ReactNode {
        return <>
            <Container fluid>
                <Row>
                    <Col lg={6}>
                        <FormGroup
                            helperText="Select keyphrase"
                            label="Keyphrase"
                            labelFor="keyphrase-selector"
                            labelInfo="(required)"
                        >
                            <AsyncViewSelect
                                id="keyphrase-selector"
                                oid={Api.meta.get_relation_oid_by_name("product.v_keyphrase_en")}
                                value={keyphraseId.get()}
                                onChange={value => keyphraseId.set(value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={2}>
                        <FormGroup
                            helperText="Remove"
                            label="Remove"
                            labelFor="remove-prompt"
                        >
                            <Button
                                id="remove-prompt"
                                text="Remove prompt"
                                icon={IconNames.DELETE}
                                intent={Intent.DANGER}
                                disabled={!canRemovePrompt()}
                                onClick={removePrompt}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={2}>
                        <FormGroup
                            helperText="Reload table data"
                            label="Refresh"
                            labelFor="refresh"
                        >
                            <Button
                                id="refresh"
                                text="Refresh"
                                icon={IconNames.RESET}
                                intent={Intent.NONE}
                                onClick={getViewSource().reload}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {keyphraseId.get()
                            ? <ViewGridFrame
                                oid={get_prompts_view_oid()}
                                storagePath={VIEWSOURCE_PATH}
                                masterFilter={promptsFilter.get()}
                            />
                            : <Callout intent={Intent.WARNING}>Please select a keyphrase to diplay its prompts</Callout>
                        }
                    </Col>
                </Row>
                {keyphraseId.get() && <Row>
                    <Col sm={12} lg={12}>
                        <EditableText multiline={true} minLines={3} maxLines={25}
                            placeholder="Paste multiple prompts here, one prompt per line"
                            value={promptLines.get()}
                            onChange={this.PromptLinesChanged}
                        />
                    </Col>
                    <Col sm={12} lg={12}>
                        <Button
                            id="add"
                            text="Add all"
                            icon={IconNames.ADD}
                            intent={Intent.PRIMARY}
                            onClick={this.addAllPrompts}
                        />
                    </Col>
                </Row>
                }
                {(problems.get() && problems.get().length) ?
                    <Row>
                        <table>
                            {problems.get().map((problem) => {
                                return <tr><td><Tag intent={Intent.DANGER} icon={IconNames.ERROR} >{problem.problem}</Tag></td><td>{problem.prompt}</td></tr>
                            })}
                        </table>
                    </Row>
                    : null
                }
            </Container>
        </>
    }
}

export const ProductPromptPage = withRouter(ProductPromptPageInner)
