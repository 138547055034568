import { Button, Classes, Intent, Popover } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Api } from '../../api/Api';
import { IRecord, RecordId } from '../../api/Record';
import { showError } from '../../dialog/Notification';
import { IViewSourceConfiguration } from '../../store/view/ViewSourceConfiguration';
import { anyToText } from '../../util';
import { ViewFilterFrame } from '../frames/view/ViewFilterFrame';
import { ViewGridFrame } from '../frames/view/ViewGridFrame';
import { IViewSourceRelatedProps, ViewSourceRelated } from '../frames/view/ViewSourceRelated';

const ASYNCVIEWSELECT_VIEWSOURCE_LIMIT = 20;

interface IAsyncViewSelectProps extends IViewSourceRelatedProps {
    id?: string | number;
    value: RecordId | null;
    intent?: Intent;
    disabled?: boolean;
    showFilterFrame?: boolean;
    /**
     * Please note that autoReload will only set the autoReload flag of the ViewSource.
     * It will NOT actually reload the ViewSource, unless somebody is watching it
     * (e.g. it has a mounted renderer) and it has not been loaded OR the parameters 
     * has been changed.
     * 
     */
    autoReload?: boolean;
    /** Called when the user selects an item from the list, passes the whole record. */
    onItemSelect?: (item: IRecord, event?: React.SyntheticEvent<HTMLElement>) => void;
    /** Called when the user selects an item from the list, passes the id value. */
    onChange?: (value: RecordId | null) => void;
    /** 
     * Called when a record is loaded. This is the record that is used to display the text
     * on the closed selector component. You can use this callback to get access to the
     * underlying record. This callback will be called either after an item has been selected,
     * manually, or loaded because of a value change.
     */
    onItemLoaded?: (item: IRecord | null) => void;
}

const EMPTY_TEXT = '<Select...>';

@observer
export class AsyncViewSelect extends ViewSourceRelated<IAsyncViewSelectProps> {
    /** This component does not directly renders the rows of its ViewSource. */
    protected get renderRows(): boolean { return false; }

    @observable isOpen: boolean = false;
    @observable textLoading: boolean = false;
    @observable text: string = EMPTY_TEXT;

    static defaultProps = { showFilterFrame: true, autoReload: true };

    constructor(props: IAsyncViewSelectProps) {
        super(props);
        if (this.props.autoReload !== undefined) {
            this.viewSource.autoReload = this.props.autoReload;
        }
        if (this.props.value !== null) {
            this.loadText();
        }
        makeObservable(this)
    }

    public componentDidUpdate(prevProps: IAsyncViewSelectProps) {
        super.componentDidUpdate(prevProps);
        if (prevProps.value !== this.props.value) {
            this.loadText();
        }
    }

    /* TODO: move this to a global function and cache loaded records! */
    protected async loadText() {
        if (this.props.value === null || this.props.value === undefined) {
            runInAction(() => { this.text = EMPTY_TEXT });
            if (this.props.onItemLoaded) {
                this.props.onItemLoaded(null);
            }
        } else {
            runInAction(() => { this.textLoading = true });
            try {
                const record = await Api.view.loadCached(this.oid, this.props.value);
                if (this.props.onItemLoaded) {
                    this.props.onItemLoaded(record);
                }
                runInAction(() => {
                    if (record === null) {
                        return "id=" + this.props.value;
                    } else {
                        const vs = this.meta;
                        const parts: string[] = [];
                        vs.show_attr_idxs.forEach(aidx => {
                            const attr = vs.attrs[aidx];
                            const value = (record as any)[attr.name];
                            if (value) {
                                parts.push(anyToText(value));
                            }
                        });
                        if (parts.length > 0) {
                            this.text = parts.join(" ");
                        } else {
                            this.text = "id=" + this.props.value;
                        }
                        this.textLoading = false;
                    }
                });
            } catch (error) {
                runInAction(() => { this.textLoading = false; });
                showError(error);
            }

        }
    }

    protected get defaultViewSourceConfig(): Partial<IViewSourceConfiguration> | undefined {
        // Override the limit for the popover.
        let defaultViewSourceConfig: Partial<IViewSourceConfiguration> | undefined;
        if (this.props.defaultViewSourceConfig === undefined) {
            defaultViewSourceConfig = {};
        } else {
            defaultViewSourceConfig = this.props.defaultViewSourceConfig;
        }
        if (defaultViewSourceConfig.limit === undefined) {
            defaultViewSourceConfig.limit = ASYNCVIEWSELECT_VIEWSOURCE_LIMIT;
        }
        return defaultViewSourceConfig;
    }

    @action.bound private setOpened(value: boolean) {
        this.isOpen = value;
    }

    private onItemSelected = () => {
        if (this.viewSource.rowIndex !== null) {
            runInAction(() => {
                this.isOpen = false;
                if (this.props.onItemSelect) {
                    this.props.onItemSelect(this.viewSource.currentRow);
                }
                if (this.props.onChange) {
                    this.props.onChange(this.viewSource.currentRowId);
                }
            })

        }
    }

    @action.bound private onEscPressed() {
        this.isOpen = false;
    }

    private renderPopover = () => {
        return <Container fluid>
            <Row>
                {this.props.showFilterFrame ?
                    <Col sm={12}>
                        <ViewFilterFrame
                            oid={this.oid}
                            storagePath={this.props.storagePath}
                            onItemSelected={this.onItemSelected}
                            onEscPressed={this.onEscPressed}
                        />
                    </Col> : null}
                <Col sm={12}>
                    <ViewGridFrame
                        oid={this.oid}
                        storagePath={this.props.storagePath}
                        onItemSelected={this.onItemSelected}
                        onEscPressed={this.onEscPressed}
                    />
                </Col>
            </Row>
        </Container>
    }


    render() {
        const text = this.props.value === null ? "<Select>" : (
            this.textLoading ? "<loading...>" : this.text
        );
        return <Popover
            isOpen={this.isOpen}
            interactionKind="click"
            popoverClassName={Classes.POPOVER_CONTENT_SIZING}
            disabled={this.props.disabled}
            content={this.renderPopover()}
            placement="right"
            onInteraction={(nextOpenState: boolean) => {
                if (!nextOpenState) {
                    runInAction(() => { this.isOpen = false });
                }
            }}
        >
            <Button
                rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
                intent={this.props.intent}
                text={text}
                disabled={this.props.disabled}
                onClick={() => this.setOpened(true)}
            />
        </Popover>
    }
}