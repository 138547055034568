import { Button, H3, H5, Intent, Spinner, TextArea } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { UUID } from "crypto";
import _ from "lodash";
import { IObservableArray, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Col, Container, Row } from "react-grid-system";
import { dispatcher } from "../../App";
import { showError, showSuccess } from "../../dialog/Notification";

const LANG_CODE = 'en' // TODO!

const CHATGPT_GEN_INSTRUCTIONS = 'I will give you a set of prompts. Each prompt is closed with a # character. ' +
    'When you respond, do not use numbers or headings. Give only what is requested. ' +
    '1) Tell me the copyrighted, trademarked or potential violated rights related to license, image or name in the prompts. ' +
    ' List only the names that may be in violation. Please include proper nouns including people\'s names. ' +
    'Do not use any explanation about the names if they are in potential violation. ' +
    'Do not explain about copyright issues - just report the names you find. ' +
    'Make one comma separated list that includes all the prompt names without repeating any names. '+
    'If you find no names in all the prompts say "none". 2) give me a title for each prompt in the order received without quotations '+
    'and numbering the lines. Do not repeat the titles, always create unique ones with synonyms. ' +
    'If the prompt contains something from the names generated in step 1, then write COPYRIGHTED instead of a title. '+
    'The number of lines generated in step 2 should be equal to the number of prompts given.'

/*
const TEST_TRADEMARKED = `pee wee Herman, Messi, Thor, Jack Kirby, NIGHT AND DAY, Canon EOS R5, Steven Holl, Alvaro Siza, Statue of Liberty, Canon, Nikon D850, DSLR, NIKKOR, National Geographic, Annie Lebovitz, Provia, Paula Scher, UC Davis, UC Berkeley, Carnegie Mellon, Honey Collins, Angelina Jolie, Alex Ross, Genos, One Punch Man, Che Guevara`
const TEST_TITLES = `Artistic Floral Display
COPYRIGHTED
Poker Advertisement
Luxury NYC Condo Interior
Cosmic Feline Adventure
COPYRIGHTED
Shaolin Levitation Scene
Yin and Yang Visualization
Dwarf Portrait
Decorative Wall Panel
Vintage Image Links
Game Icon Design
Bitcoin Chess Scene
Futuristic Portal
Architectural Pavilion
Princess and Liberty
Lakeside Terrace View
Attic Mystery
Mafia Boss Portrait
Simple Rainbow Art
Sci-fi Military Base
Misty Aikido Scene
Lion's Chiaroscuro Art
Futuristic Green Cityscape
Mindfulness Logo
Joyful Portrait
Floral Awareness Tee
Open Air Cinema
Mountain Top View
Oasis Asylum Concept
Gangster Spiderman
Academic Global Ties
Modern Lounge Setting
Studious Astronaut
Fishing App Mockup
Children's Charity Logo
Doll's Chase
Elegant High Ceiling Room
French Bulldogs Display
Festive Penguin Art
AI Fraud Cartoon
Serenity Lake Image
Surfing Snake Photo
COPYRIGHTED
Iconic Celebrity Art
Romantic Subway Scene
Tactical Shirt Advertisement
Trippy 3D Artwork
Alexander's Epic March
Festival Che Portrait`
*/


const loading = observable.box<boolean>(true)
const setLoading = (value: boolean) => { runInAction(() => loading.set(value)) }
const saving = observable.box<boolean>(false)
const setSaving = (value: boolean) => { runInAction(() => saving.set(value)) }

const gptTrademarked = observable.box(''/*TEST_TRADEMARKED*/)
const gptTitles = observable.box(''/*TEST_TITLES*/)

type ToReview = {
    id: UUID
    prompt: string
}

const toReview: IObservableArray<ToReview> = observable([]);

const reload = async () => {
    try {
        setLoading(true)
        const data = await dispatcher.call<ToReview[]>("product.get_community_to_review")
        runInAction(() => {
            toReview.replace(data)
            gptTrademarked.set(''/*TEST_TRADEMARKED*/)
            gptTitles.set(''/*TEST_TITLES*/)
            loading.set(false)
        })
    } catch (error) {
        showError(error)
    }
}

const save = async () => {
    try {
        setSaving(true)
        const params = {
            product_ids: toReview.map(r => r.id),
            lang_code: LANG_CODE, // TODO!
            forbidden_phrases: gptTrademarked.get(),
            titles: gptTitles.get(),
        }
        await dispatcher.call("product.save_community_review", params)
        runInAction(() => {
            saving.set(false)
            showSuccess("Titles saved")
            reload()
        })
    } catch (error) {
        setSaving(false)
        showError(error)
    }
}

const debouncedReload = _.debounce(reload, 1000, { maxWait: 100000 })


type ProductCommunityReviewPageProps = {}


@observer
export class ProductCommunityReviewPage extends React.PureComponent<ProductCommunityReviewPageProps> {

    componentDidMount(): void {
        debouncedReload()
    }

    private onGptTrademarkedChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value: string = event.target.value;
        runInAction(() => {
            gptTrademarked.set(value)
        })
    };

    private onGptTitlesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value: string = event.target.value;
        runInAction(() => {
            gptTitles.set(value)
        })
    };


    render(): React.ReactNode {
        if (loading.get()) return <Spinner />

        let s = toReview.map(r => r.prompt).join("#\n") + "#"
        return <Container fluid>
            <Row>
                <Col lg={12}>
                    <H3>Instructions</H3>
                    <TextArea style={{ width: "100%", height: "10em", fontSize: "70%" }}>
                        {CHATGPT_GEN_INSTRUCTIONS}
                    </TextArea>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <H3>Prompts</H3>
                    <Button
                        icon={IconNames.CLIPBOARD}
                        text="Copy instructions and prompts"
                        intent={Intent.PRIMARY}
                        disabled={loading.get() || saving.get()}
                        onClick={() => {
                            navigator.clipboard.writeText(CHATGPT_GEN_INSTRUCTIONS + " Here are the prompts:\n\n" + s)
                            showSuccess(null, "" + toReview.length + " prompts copied")
                        }}
                    />
                    <TextArea style={{ width: "100%", height: "50em", fontSize: "70%" }} autoResize={true}>
                        {s}
                    </TextArea>
                </Col>
                <Col lg={6}>
                    <H3>Results</H3>
                    <Button
                        icon={saving.get() ? IconNames.STOPWATCH : IconNames.FLOPPY_DISK}
                        text={saving.get() ? 'Saving...' : 'Save'}
                        intent={Intent.SUCCESS}
                        disabled={
                            loading.get() || saving.get()
                            || !gptTrademarked.get().trim() || !gptTitles.get().trim()
                        }
                        onClick={save}
                    />
                    <H5>Paste trademarked names</H5>
                    <TextArea style={{ width: "100%", height: "50em", fontSize: "70%" }}
                        placeholder="Paste trademarked names here"
                        autoResize={true}
                        value={gptTrademarked.get()}
                        onChange={this.onGptTrademarkedChange}
                    />
                    <H5>Paste generated titles</H5>
                    <TextArea style={{ width: "100%", height: "50em", fontSize: "70%" }}
                        placeholder="Paste titles here"
                        autoResize={true}
                        value={gptTitles.get()}
                        onChange={this.onGptTitlesChange}
                    />
                </Col>
            </Row>
        </Container>
    }
}

