import { Button, Icon, Intent, Spinner, Tab, TabId, Tabs, Tag } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';
import JsonView from '@uiw/react-json-view';
import { UUID } from "crypto";
import _ from "lodash";
import { IObservableValue, observable, reaction, runInAction, toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { dispatcher } from "../../App";
import { HStore } from "../../data/abc/record";
import { ITimestamp, timestampToShortString } from "../../data/abc/timestamptz";
import { showError, showSuccess } from "../../dialog/Notification";
import { ROUTE_PRODUCT_DETAILS } from "../../navigation/Routes";
import { WithRouterProps, withRouter } from "../../navigation/WithRouter";

import { darkTheme } from '@uiw/react-json-view/dark';
import { Col, Container, Row } from "react-grid-system";
import { Api } from "../../api/Api";
import { IViewFilter } from "../../api/View";
import { ViewGridFrame } from "../../components/frames/view/ViewGridFrame";
import { ProductRecord } from "../../data/schema/product/product";
import { ProductFile } from "../../data/schema/product/product_file";
import { ProductFileQueue } from "../../data/schema/product/product_file_queue";
import { ProductPrice } from "../../data/schema/product/product_price";
import { ConfirmDialog } from "../../dialog/ConfirmDialog";
import { appStore } from "../../store/AppStore";
import { formatSize } from "../../util";
import "./ProductDetailPage.scss";
import { openShareLinkDialog } from "../../dialog/ShareLinkDialog";

interface Params {
  productId: string;
}

type ProductDetailPageProps = WithRouterProps<Params>;

const loading = observable.box<boolean>(true)
//const setLoading = (value: boolean) => { runInAction(() => loading.set(value)) }
//const saving = observable.box<boolean>(false)
//const setSaving = (value: boolean) => { runInAction(() => saving.set(value)) }

const selProdTab = observable.box("basic");
const selImgTab = observable.box("image");




type ProductEx = ProductRecord & {
  keyphrase: HStore;
  product_status_code: string;
  product_status_title: HStore;
  product_status_description: HStore;
}

type ProductPriceEx = ProductPrice & {
  currency_code: string;
  enable_status_code: string;
  enable_status_title: HStore;
  enable_status_description: HStore | null;
  upload_status_code: string;
  upload_status_title: HStore;
  upload_status_description: HStore | null;
}

type ProductFileEx = ProductFile & {
  file_type_code: string;
  file_type_title: HStore;
  file_type_description: HStore;
  file_status_code: string;
  file_status_title: HStore;
  file_status_description: HStore;
  is_ready: boolean;
  file_size: number;
  content_type_id: UUID;
  content_type: string;

  last_requested: ITimestamp | null;
  last_processed: ITimestamp | null;
  last_failed: ITimestamp | null;
  last_error_message: string | null;

};

type ProductFileQueueEx = ProductFileQueue & {
  src_c_tim: ITimestamp
  src_m_tim: ITimestamp
  src_file_id: UUID
  src_type_code: string
  src_type_title: HStore
  dst_c_tim: ITimestamp | null
  dst_m_tim: ITimestamp | null
  dst_file_id: UUID | null
  dst_type_code: string | null
  dst_type_title: HStore | null
}

type GetProductResult = {
  product: ProductEx
  prices: ProductPriceEx[]
  files: Partial<ProductFileEx>[]
  file_queue: ProductFileQueueEx[]
}

const productId: IObservableValue<UUID | null> = observable.box(null);
const product: IObservableValue<GetProductResult | null> = observable.box(null);
const isDisplayed: IObservableValue<boolean> = observable.box(false);
const masterFilter = observable.box<IViewFilter>({ equals: { product_id: productId.get() } })

reaction(
  () => productId.get(),
  () => {
    masterFilter.set({ equals: { product_id: productId.get() } })
  }
)

const reload = async () => {
  if (!isDisplayed.get()) {
    return;
  }
  try {
    const product_id = productId.get();
    const data = product_id === null ? null : await dispatcher.call<GetProductResult>("product.get_product", { product_id })
    runInAction(() => {
      product.set(data)
      loading.set(false)
    })
    debouncedAutoReload() // auto-reload
  } catch (error) {
    showError(error)
  }
  debouncedAutoReload() // auto-reload
}

const debouncedReload = _.debounce(reload, 100, { maxWait: 100000 })
const debouncedAutoReload = _.debounce(reload, 5000, { maxWait: 100000 })

reaction(() => [productId.get(), isDisplayed.get()], debouncedReload)



const generateFiles = async () => {
  try {
    const product_id = productId.get();
    const n = await dispatcher.call<number>("product.enqueue_files_for_order", { product_id })
    showSuccess(`${n} file(s) enqueued`)
    debouncedReload()
  } catch (error) {
    showError(error)
  }
}

const checkZipDownloadFiles = async () => {
  const needed_type_codes = ["outline", "hires", "dxf", "eps", "svg", "pdf"];
  const files = product.get()!.files;
  const dl_files = files.filter(f => needed_type_codes.includes(f.file_type_code!) && !!f.file_id);

  if (dl_files.length === needed_type_codes.length) {
    return true;
  }
    if (await ConfirmDialog.open("Warning",
      "Not all required file types are available. ZIP file will be missing some file types. Continue?"
    )) {
      return true;
    }
  return false;
}

const downloadZip = async () => {
  if (!await checkZipDownloadFiles()) {
    return
  }
  const product_id = productId.get();
  const url = `/product/download_zip/${product_id}`;
  window.location.replace(url);
  // window.open(url, '_blank', 'noopener,noreferrer,resizable')
}

const createShareLink = async () => {
  if (!await checkZipDownloadFiles()) {
    return
  }
  const product_id = productId.get()!;
  try {
      const url = await Api.shelf.create_zip_download_link(product_id);
      console.log(url)
      openShareLinkDialog(url);
  } catch (error) {
      showError(error)
  }
}

@observer
class ProductDetailPageInner extends React.PureComponent<ProductDetailPageProps> {
  componentDidMount(): void {
    runInAction(() => {
      const { match } = this.props;
      console.log(match.params.productId)
      productId.set(match.params.productId as UUID)
      isDisplayed.set(true)
    })
  }

  componentWillUnmount(): void {
    runInAction(() => {
      isDisplayed.set(false)
    })
  }

  get product() {
    return product.get()!.product
  }

  get prices() {
    return product.get()!.prices
  }

  get files() {
    return product.get()!.files
  }

  get file_queue() {
    return product.get()!.file_queue
  }

  _renderText(value: string, html?: boolean) {
    if (html) {
      return <span dangerouslySetInnerHTML={{ __html: value }} />
    } else {
      return <span>{value}</span>
    }
  }

  renderHstoreText(lbl: string, value: HStore | null, html?: boolean) {
    if (!value) return null;
    let keys = Object.keys(value);
    if (keys.length === 0) return;
    if (keys.length === 1) {
      const item = value[keys[0]];
      return <tr>
        <td><b>{lbl}</b></td>
        <td>{keys[0]}</td>
        <td>{this._renderText(item, html)}</td>
      </tr>
    }
    return <>
      <tr><td colSpan={2}>{lbl}</td></tr>
      {Object.keys(value).map(isoCode =>
        <tr key={isoCode}>
          <td></td>
          <td><b>{isoCode}</b></td>
          <td>{this._renderText(value[isoCode], html)}</td>
        </tr>
      )}
    </>
  }

  copyIdData = (event: React.MouseEvent<HTMLElement>) => {
    const dataId = event.currentTarget.getAttribute("data-id")
    if (dataId) {
      navigator.clipboard.writeText(dataId);
      showSuccess(dataId, "Copied");
    }
  }

  openLinkData = (event: React.MouseEvent<HTMLElement>) => {
    const dataLink = event.currentTarget.getAttribute("data-link")
    if (dataLink) {
      window.open(dataLink, "_blank", "noopener,noreferrer,resizable")
    }
  }

  renderIdButton(value: UUID | null | undefined) {
    if (!value) return null;
    const i = value.lastIndexOf("-")
    const code = value.substring(i + 1, value.length);
    return <Button text={code} icon={IconNames.CLIPBOARD} title={value} data-id={value} onClick={this.copyIdData} />
  }

  renderDownloadButton(link: string, text?: string, title?: string, icon?: any) {
    if (!icon) { icon = IconNames.DOWNLOAD }
    if (!title) { title = "Download " }
    return <Button text={text} icon={icon} title={title} data-link={link} onClick={this.openLinkData} />
  }

  renderId(lbl: string, value: UUID | null, link?: string) {
    if (!value) return null;
    return <tr key={lbl}>
      <td colSpan={2}><b>{lbl}</b></td>
      <td>
        {this.renderIdButton(value)}
        {link &&
          <Button icon={IconNames.GLOBE}
            data-link={link + value}
            onClick={this.openLinkData}
            title="Open link"
          />
        }
      </td>
    </tr>
  }

  renderTimestamp(lbl: string, value: ITimestamp | null) {
    if (!value) return null;
    return <tr key={lbl}>
      <td colSpan={2}><b>{lbl}</b></td>
      <td>{timestampToShortString(value)}</td>
    </tr>
  }

  renderText(lbl: string, value: string | null) {
    if (!value) return null;
    return <tr key={lbl}>
      <td colSpan={2}><b>{lbl}</b></td>
      <td>{value}</td>
    </tr>
  }

  renderURL(lbl: string, url: string | null) {
    if (!url) return null;
    return <tr key={lbl}>
      <td colSpan={2}><b>{lbl}</b></td>
      <td><a href={url} rel="noreferrer" target="_blank">{url}</a></td>
    </tr>
  }

  renderBoolean(lbl: string, value: boolean | null, falseText: string, trueText: string, intent?: Intent, icon?: any) {
    let text: string
    if (value === true) {
      text = trueText
    } else if (value === false) {
      text = falseText
    } else {
      text = ""
    }
    let chk = icon === undefined ? null : <Icon icon={icon} />
    return <tr key={lbl}>
      <td colSpan={2}><b>{lbl}</b></td>
      <td><Tag intent={intent}>{chk}{text}</Tag></td>
    </tr>
  }

  renderBasicProductData() {
    const p = this.product;
    let price: ProductPriceEx | null = null;
    if (this.prices && this.prices.length) {
      price = this.prices[0];
    }
    return <table>
      <thead>
        <th align="center"><Icon icon={IconNames.TAG} />Prop</th>
        <th align="center"><Icon icon={IconNames.GLOBE} /></th>
        <th align="center"><Icon icon={IconNames.VARIABLE} /> Value</th>
      </thead>
      <tbody>
        {this.renderId("Id", p.id)}
        {this.renderTimestamp("Created", p.c_tim)}
        {this.renderTimestamp("Modified", p.m_tim)}
        {this.renderText("Keyphrase", p.keyphrase?p.keyphrase["en"]:null)}
        {this.renderText("Status code", p.product_status_code)}
        {this.renderHstoreText("Status title", p.product_status_title)}
        {this.renderHstoreText("Title", p.title)}
        {this.renderHstoreText("SEO Description", p.seo_description)}
        {price && <>
          {this.renderHstoreText("Enable status", price.enable_status_title)}
          {this.renderHstoreText("Upload status", price.upload_status_title)}
          {this.renderBoolean("Synchronization (selling site)", price.has_sync_error,
            "Synchronized OK", "Sync ERROR!",
            price.has_sync_error === true ? Intent.DANGER : Intent.SUCCESS,
            price.has_sync_error === true ? IconNames.CROSS : IconNames.TICK,
          )}
          {this.renderText("ASIN (selling site)", price.asin)}
          {this.renderText("Price (selling site)", "" + price.price + " " + price.currency_code)}
          {this.renderURL("Admin URL (selling site)", "https://admin.shopify.com/store/238cc4/products/" + price.asin)}
          {this.renderURL("Listing URL (selling site)", price.url)}
          {this.renderURL("Cover Image URL (selling site)", price.cover_image_url)}
        </>}
      </tbody>

    </table>
  }

  renderFullProductData() {
    const p = this.product;
    return <table>
      <thead>
        <th align="center"><Icon icon={IconNames.TAG} />Prop</th>
        <th align="center"><Icon icon={IconNames.GLOBE} /></th>
        <th align="center"><Icon icon={IconNames.VARIABLE} /> Value</th>
      </thead>
      <tbody>
        {this.renderId("Id", p.id)}
        {this.renderTimestamp("Created", p.c_tim)}
        {this.renderTimestamp("Modified", p.m_tim)}
        {this.renderId("keyphrase_id", p.keyphrase_id)}
        {this.renderText("Keyphrase", p.keyphrase?p.keyphrase["en"]:null)}
        {this.renderId("gen_batch_id", p.gen_batch_id)}
        {this.renderText("Prompt", p.prompt)}
        {this.renderId("product_status_id", p.product_status_id)}
        {this.renderText("Status code", p.product_status_code)}
        {this.renderHstoreText("Status title", p.product_status_title)}
        {this.renderHstoreText("Status description", p.product_status_description)}
        {this.renderId("Copied from", p.copied_from_id, ROUTE_PRODUCT_DETAILS + "/")}
        {this.renderId("mj_bot_id", p.mj_bot_id)}
        {this.renderHstoreText("Title", p.title)}
        {this.renderHstoreText("SEO Title", p.seo_title)}
        {this.renderHstoreText("Description", p.description, true)}
        {this.renderHstoreText("SEO Description", p.seo_description)}
        {this.renderId("title_text_template_id", p.title_text_template_id)}
        {this.renderId("description_text_template_id", p.description_text_template_id)}
        {this.renderId("title_category_tail_template_id", p.title_category_tail_template_id)}
        {this.renderId("description_category_tail_template_id", p.description_category_tail_template_id)}
        {this.renderId("title_gen_template_id", p.title_gen_template_id)}

      </tbody>

    </table>
  }

  renderPriceTab = (price: ProductPriceEx, priceIndex: number) => {
    return <Tab id={price.id} title={`Price ${priceIndex + 1}.`} panel={this.renderPriceData(price, priceIndex)} />
  }

  renderPriceData = (p: ProductPriceEx, priceIndex: number) => {
    return <table>
      <thead>
        <th align="center"><Icon icon={IconNames.TAG} />Prop</th>
        <th align="center"><Icon icon={IconNames.GLOBE} /></th>
        <th align="center"><Icon icon={IconNames.VARIABLE} /> Value</th>
      </thead>
      <tbody>
        {this.renderId("Id", p.id)}
        {this.renderTimestamp("Created", p.c_tim)}
        {this.renderTimestamp("Modified", p.m_tim)}
        {this.renderTimestamp("Update at (selling site)", p.updated_at)}
        {this.renderText("Enable status", p.enable_status_code)}
        {this.renderHstoreText("Enable status title", p.enable_status_title)}
        {this.renderHstoreText("Enable status description", p.enable_status_description)}
        {this.renderText("Upload status", p.upload_status_code)}
        {this.renderHstoreText("Upload status title", p.upload_status_title)}
        {this.renderHstoreText("Upload status description", p.upload_status_description)}
        {this.renderBoolean("Synchronization", p.has_sync_error,
          "Synchronized OK", "Sync ERROR!",
          p.has_sync_error === true ? Intent.DANGER : Intent.SUCCESS,
          p.has_sync_error === true ? IconNames.CROSS : IconNames.TICK,
        )}
        {this.renderText("ASIN", p.asin)}
        {this.renderText("Price", "" + p.price + " " + p.currency_code)}
        {this.renderURL("Admin URL (selling site)", "https://admin.shopify.com/store/238cc4/products/" + p.asin)}
        {this.renderURL("Listing URL (selling site)", p.url)}
        {this.renderURL("Cover Image URL (selling site)", p.cover_image_url)}
      </tbody>

    </table>
  }

  renderFile = (fl: Partial<ProductFileEx>, idx: number) => {
    let progress: any = null;
    if (fl.last_processed) {
      progress = <>
        <Tag intent={Intent.SUCCESS}>Processed </Tag> <br />
        <small>{timestampToShortString(fl.last_processed)}</small>
      </>
    } else if (fl.last_failed) {
      progress = <>
        <Tag intent={Intent.DANGER}>ERROR {timestampToShortString(fl.last_failed)}</Tag> <br />
        <small>{timestampToShortString(fl.last_failed)}</small>
        <br />
        <small>{fl.last_error_message}</small>
      </>;
    } else if (fl.last_requested) {
      progress = <>
        <Tag intent={Intent.PRIMARY}>Requested </Tag><br />
        <small>{timestampToShortString(fl.last_requested)}</small>
      </>
    }

    return <tr>
      <td>{this.renderIdButton(fl.id)}</td>
      <td>{timestampToShortString(fl.c_tim || null)}</td>
      <td align="center">
        {fl.is_ready
          ? <Icon icon={IconNames.TICK} intent={Intent.SUCCESS} />
          : <Icon icon={IconNames.CROSS} intent={Intent.WARNING} />
        }
      </td>
      <td>{fl.file_type_code}<br /><small>{fl.content_type}</small></td>
      <td>{fl.file_status_code}</td>
      <td>{progress}</td>
      <td align="left">
        {fl.file_id && this.renderDownloadButton("/media/file/" + fl.file_id)}
        {fl.dl_url && this.renderDownloadButton(fl.dl_url, "", "On shopify", IconNames.GLOBE)}
      </td>
      <td align="right">{formatSize(fl.file_size)}</td>
    </tr>
  }

  renderFiles = () => {
    return <Container>
      <Row>
        <Col>
          <Button
            intent={Intent.PRIMARY}
            icon={IconNames.NEW_OBJECT}
            text="Generate files for order fulfillment"
            onClick={generateFiles}
          />
          <Button
            intent={Intent.SUCCESS}
            icon={IconNames.DOWNLOAD}
            text="Download as ZIP"
            onClick={downloadZip}
          />
          <Button
            intent={Intent.SUCCESS}
            icon={IconNames.SHARE}
            text="Share download link"
            onClick={createShareLink}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <table className="imagetable">
            <thead>
              <th>Id</th>
              <th>Created</th>
              <th align="center">Is ready</th>
              <th>Type<br /><small>Content-Type</small></th>
              <th>Status</th>
              <th>Progress</th>
              <th align="left">Download</th>
              <th align="right">Size</th>
            </thead>
            <tbody>
              {this.files.map((img, idx) => this.renderFile(img, idx))}
            </tbody>
          </table>

        </Col>
      </Row>
    </Container>
  }

  renderFileQueueItem = (qi: ProductFileQueueEx, idx: number) => {
    let progress: any = null;
    if (qi.processed) {
      progress = <Tag intent={Intent.SUCCESS}>Processed </Tag>
    } else if (qi.failed) {
      progress = <>
        <Tag intent={Intent.DANGER}>ERROR {timestampToShortString(qi.failed)}</Tag><br />
        <small>{qi.error_message}</small>
      </>;
    } else {
      progress = <Tag intent={Intent.PRIMARY}>Waiting/In progress</Tag>
    }

    return <tr>
      <td>{this.renderIdButton(qi.id)}<br /><small>{qi.ord}</small></td>
      <td>{timestampToShortString(qi.requested)}</td>
      <td>{timestampToShortString(qi.requested)}</td>
      <td>{timestampToShortString(qi.failed)}</td>
      <td>{progress}</td>
      <td>{qi.src_type_code}</td>
      <td>{this.renderIdButton(qi.src_file_id)}</td>
      <td>{qi.dst_type_code}</td>
      <td>{this.renderIdButton(qi.dst_file_id)}</td>
    </tr>
  }

  renderFileQueue = () => {
    return <table className="imagetable">
      <thead>
        <th>Id</th>
        <th>Requested<br /><small>Ord</small></th>
        <th>Processed</th>
        <th>Failed</th>
        <th>Status</th>
        <th>Source type</th>
        <th>Source file</th>
        <th>Dst type</th>
        <th>Dst file</th>
      </thead>
      <tbody>
        {this.file_queue.map((img, idx) => this.renderFileQueueItem(img, idx))}
      </tbody>
    </table>
  }

  onChangeProductTab(newTabId: TabId, prevTabId: TabId | undefined, event: React.MouseEvent<HTMLElement, MouseEvent>) {
    runInAction(() => {
      selProdTab.set(newTabId as string)
    })
  }

  onChangeImageTab(newTabId: TabId, prevTabId: TabId | undefined, event: React.MouseEvent<HTMLElement, MouseEvent>) {
    runInAction(() => {
      selImgTab.set(newTabId as string)
    })
  }

  renderFileSyncQueue = () => {
    let data: any = {}
    const oid = Api.meta.get_relation_oid_by_name("product.av_product_sync_queue");
    const sp = "product_detail.product_sync_queue";
    const vs = appStore.getViewSource(sp, oid, masterFilter.get());
    if (vs && vs.currentRow) {
      console.log(vs.currentRow)
      const cw = toJS(vs.currentRow)
      if (cw && typeof cw === "object") {
        data = JSON.parse(cw["args"])
      }
    }
    console.log("data", data)
    return <Container fluid>
      <Row>
        <Col sm={12} lg={12}>
          <ViewGridFrame
            oid={oid}
            storagePath="product_detail.product_sync_queue"
            masterFilter={masterFilter.get()} />

        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={12}>
          {(!!data && (typeof data === "object")) ? <JsonView value={data} style={darkTheme} /> : null}
        </Col>
      </Row>
    </Container>
  }

  render(): React.ReactNode {
    if (loading.get()) return <Spinner />
    const thumbs = this.files.filter(i => i.file_type_code === "thumb_256");
    const thumb = (thumbs.length === 1) ? thumbs[0] : null;

    return <Container fluid>
      <Row>
        <Col sm={12} md={4} lg={2}>
          {thumb && <img src={"/media/file/" + thumb.file_id} alt={thumb.file_status_code} />}
        </Col>
        <Col sm={12} md={6} lg={10}>
          <Tabs id="productData" large onChange={this.onChangeProductTab} selectedTabId={selProdTab.get()}>
            <Tab id="basic" title="Basic product info" panel={this.renderBasicProductData()} />
            <Tab id="full" title="Full product info" panel={this.renderFullProductData()} />
            {this.prices.map((p, idx) => this.renderPriceTab(p, idx))}
          </Tabs>
        </Col>
      </Row>
      <Row>
        <Tabs id="imageData" large onChange={this.onChangeImageTab} selectedTabId={selImgTab.get()}>
          <Tab id="image" title="Files (images)" panel={this.renderFiles()} />
          <Tab id="file_queue" title="File (image) queue" panel={this.renderFileQueue()} />
          <Tab id="sync_queue" title="Sync queue" panel={this.renderFileSyncQueue()} />
        </Tabs>
      </Row>
    </Container>

  }
}

export const ProductDetailPage = withRouter(ProductDetailPageInner)
