import * as React from "react";

import { Button, Callout, ControlGroup, FormGroup, InputGroup, Intent, Radio, RadioGroup, Spinner, Tag } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';
import { UUID } from "crypto";
import { IObservableArray, action, makeObservable, observable, reaction, runInAction, toJS } from "mobx";
import { observer } from 'mobx-react';

import { dispatcher } from "../../App";
import { showError, showSuccess } from "../../dialog/Notification";

import { Cell, Column, Table2 } from "@blueprintjs/table";
import _ from "lodash";
import { Col, Container, Row } from "react-grid-system";
import { RecordId } from "../../api/Record";
import { AbcInput } from "../../components/inputs/AbcInput";
import { HStore } from "../../data/abc/record";
import { ITimestamp, abcToLocalTimestampString } from "../../data/abc/timestamptz";
import { ROUTE_PRODUCT_DETAILS } from "../../navigation/Routes";
import { Link } from "react-router-dom";


// TODO!!!
const LANGUAGE_CODE = "en"

const loading = observable.box<boolean>(false)
const setLoading = (value: boolean) => { runInAction(() => loading.set(value)) }

type HasImage = "" | "1" | "0"


class Filters {
    // Tells if the product list is currently loading.
    @observable public asin = ""
    @observable public searchPhrase = ""
    @observable public categoryId: RecordId | null = null
    @observable public hasImage: HasImage = ""
    @observable public productUrl = ""



    @observable public minCreated: ITimestamp | null = null
    @observable public maxCreated: ITimestamp | null = null
    @observable public minModified: ITimestamp | null = null
    @observable public maxModified: ITimestamp | null = null

    
    @action.bound public setASIN(value: string) { this.asin = value }
    @action.bound public setSearchPhrase(value: string) { this.searchPhrase = value }
    @action.bound public setCategoryId(value: RecordId | null) { this.categoryId = value }
    @action.bound public setHasImage(value: HasImage) { this.hasImage = value }
    @action.bound public setProductUrl(value: string) { this.productUrl = value }

    public getHasImage() {
        switch (this.hasImage) {
            case "1":
                return true
            case "0":
                return false
            default:
                return null
        }
    }

    @action.bound public setMinCreated(value: ITimestamp | null) { this.minCreated = value }
    @action.bound public setMaxCreated(value: ITimestamp | null) { this.maxCreated = value }
    @action.bound public setMinModified(value: ITimestamp | null) { this.minModified = value }
    @action.bound public setMaxModified(value: ITimestamp | null) { this.maxModified = value }


    constructor() { makeObservable(this) }
}
const filters = new Filters()




type ProductPriceRow = {
    id: UUID // product_price.id
    c_tim: ITimestamp // price created
    m_tim: ITimestamp | null // price last modified
    enable_status_id: UUID
    enable_status: string
    upload_status_id: UUID
    upload_status: string
    currency_id: UUID
    currency_code: string
    price: number | null
    asin: number | null
    has_sync_error: boolean
    product_id: UUID
    keyphrase_id: UUID
    keyphrase: HStore
    keyphrase_stem: HStore
    main_category_id: UUID
    category_title: string
    product_status_id: UUID
    product_status: string
    p_c_tim: ITimestamp // product created
    p_m_tim: ITimestamp | null // product last modified
    title: string | null
    seo_title: string | null
    description: string | null
    seo_description: string | null
    thumb_256_id: UUID | null
    mj_orig_id: UUID | null
    headline: string | null
    ts_rank: number
    url: string|null
}

export type CodeTitleRecord = {
    id: UUID;
    code: string;
    title: HStore;
    description: HStore;
}

export type CodeTitleDescRecord = CodeTitleRecord & {
    description: HStore;
}

type ProductPriceSearchResult = {
    product_prices: ProductPriceRow[]
    ts_query: string
    warning: string

    product_statuses: { [product_status_id: string]: CodeTitleDescRecord }
    upload_statuses: { [upload_status_id: string]: CodeTitleDescRecord }
    enable_statuses: { [enable_status_id: string]: CodeTitleDescRecord }
    categories: { [category_id: string]: CodeTitleRecord }
}

const EMPTY_SEARCH_RESULTS: ProductPriceSearchResult = {
    product_prices: [], ts_query: "", warning: "",
    product_statuses: {}, enable_statuses: {}, upload_statuses: {}, categories: {}
}

const search = observable.box<ProductPriceSearchResult>(EMPTY_SEARCH_RESULTS);
const rowHeights: IObservableArray<number> = observable([]);



const reloadSearch = async () => {
    try {
        setLoading(true)
        const sp = filters.searchPhrase;
        if (sp || filters.asin || filters.productUrl.trim()) {
            const params: any = {
                lang_id: '9cfefed8-fb94-97ba-a5cd-519d7d2bb5d7',
                asin: filters.asin,
                search_phrase: sp,
                product_url: filters.productUrl,
                main_category_id: filters.categoryId,
                min_created: filters.minCreated,
                max_created: filters.maxCreated,
                min_modified: filters.minModified,
                max_modified: filters.maxModified,
                has_image: filters.getHasImage()
            }
            const data = await dispatcher.call<ProductPriceSearchResult>("product_price.search", params)
            runInAction(() => {
                search.set(data);
                let rh = Array.from({ length: data.product_prices.length }, (x, i) => 256);
                rowHeights.replace(rh)
                loading.set(false);
            })
        } else {
            runInAction(() => {
                search.set(EMPTY_SEARCH_RESULTS);
                rowHeights.replace([])
                loading.set(false);
            })
        }
    } catch (error) {
        showError(error)
    }
}

const debouncedReloadSearch = _.debounce(reloadSearch, 1000, { maxWait: 100000 })

reaction(
    () => toJS(filters),
    () => {
        setLoading(true)
        // Note: mobx reaction throttle is not the same as a debounce
        debouncedReloadSearch()
    }
)

const imageCellRenderer = (rowIndex: number) => {
    const pp = search.get().product_prices[rowIndex];
    return <Cell>{
        pp.thumb_256_id ?
            <a href={`/media/file/${pp.mj_orig_id}`} target="_blank" rel="noreferrer"><img
                src={`/media/file/${pp.thumb_256_id}`}
                alt=""
            /></a> : null}
    </Cell>
}

const getUploadStatusIntent = (id: UUID) => {
    switch (id) {
        case '0189c212-b3ad-1e10-4c0a-fc8992d383c7':
            return Intent.SUCCESS // active
        case '0189b5e8-9b72-0484-e29f-c5a28baa497b':
            return Intent.PRIMARY // not uploaded
        case '0189c212-b3ad-a006-12ce-7dcc2d003f59':
            return Intent.WARNING // draft
        default:
            return Intent.NONE
    }
}

const copyHeadline = (event: React.MouseEvent<HTMLElement>) => {
    const rowIndex = parseInt(event.currentTarget.getAttribute("data-row-index")!);
    const pp = search.get().product_prices[rowIndex];
    const headline = pp.headline!.replaceAll("<b>", " ").replaceAll("</b>", " ").replaceAll("  ", " ").trim()
    navigator.clipboard.writeText(headline);
    showSuccess(headline, "Copied");
}

const titleCellRenderer = (rowIndex: number) => {
    const pp = search.get().product_prices[rowIndex];
    return <Cell wrapText={true}>
        <b>Title</b>: {pp.title}<br />
        <b>Rank:</b> {pp.ts_rank}<br />
        <Tag round={true} icon={IconNames.TAG} title="Product status">{pp.product_status}</Tag>
        <Tag round={true} icon={
            pp.enable_status_id === '0189b5e8-9b58-c281-9dc6-a6a772fb5f12'
                ? IconNames.TICK : IconNames.DISABLE}
            intent={
                pp.enable_status_id === '0189b5e8-9b58-c281-9dc6-a6a772fb5f12'
                    ? Intent.SUCCESS : Intent.NONE}
            title="Enable status">{pp.enable_status}</Tag>
        <Tag round={true} icon={
            pp.upload_status_id === '0189c212-b3ad-1e10-4c0a-fc8992d383c7'
                ? IconNames.UPLOAD : IconNames.CROSS} title="Upload status"
            intent={getUploadStatusIntent(pp.upload_status_id)}
        >{pp.upload_status}</Tag>
        <Tag round={true} title="Has sync error?"
            intent={pp.has_sync_error ? Intent.DANGER : Intent.SUCCESS}
            icon={pp.has_sync_error ? IconNames.CROSS : IconNames.TICK}
        >{pp.has_sync_error ? "Sync error" : "Sync OK"}</Tag>
        <br />
        {pp.headline ?
            <>
                <div className="bp5-text-muted bp5-text-small" dangerouslySetInnerHTML={{ __html: pp.headline }} />
                <Button icon={IconNames.CLIPBOARD}
                    data-row-index={rowIndex}
                    onClick={copyHeadline}
                    title="Copy to clipboard"
                />
            </>
            : null}
    </Cell>
}

const keyphraseCellRenderer = (rowIndex: number) => {
    const pp = search.get().product_prices[rowIndex];
    return <Cell>
        <b>Keyphrase</b>: {pp.keyphrase && pp.keyphrase["en"]}<br />
        <b>Stem:</b>:  {pp.keyphrase_stem && pp.keyphrase_stem["en"]}<br />
        <b>Main category:</b>:  {pp.category_title}<br />
    </Cell>
}


const asinPriceCellRenderer = (rowIndex: number) => {
    const pp = search.get().product_prices[rowIndex];
    return <Cell>
        <b>ProductId:</b> <Link to={ROUTE_PRODUCT_DETAILS + "/" + pp.product_id}>{pp.product_id}</Link><br />
        <b>PriceId:</b> {pp.id}<br />
        <b>ASIN</b>:
        {pp.asin
            ? <a
                href={`https://admin.shopify.com/store/238cc4/products/${pp.asin}`}
                target="_blank"
                rel="noreferrer">{pp.asin}</a>
            : <span>N/A</span>
        }< br/>
        <b>Public URL:</b> {
            pp.url?<a href={pp.url} rel="noreferrer">{pp.url}</a>:<span>N/A</span>
        }
        <br />
        <b>Price:</b>:  {pp.price} {pp.currency_code}<br />
        <b>Price Created:</b>:  {abcToLocalTimestampString(pp.c_tim)}<br />
        <b>Price Modified:</b>:  {abcToLocalTimestampString(pp.m_tim)}<br />
        <b>Product Created:</b>:  {abcToLocalTimestampString(pp.p_c_tim)}<br />
        <b>Product Modified:</b>:  {abcToLocalTimestampString(pp.p_m_tim)}<br />
        < br/>
    </Cell>
}

@observer
export default class ProductPriceSearchPage extends React.PureComponent<{}> {

    constructor(props: {}) {
        super(props)
        makeObservable(this, {})
    }

    componentDidMount(): void { reloadSearch() }

    onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        filters.setSearchPhrase(event.target.value)
    }

    onASINChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        filters.setASIN(event.target.value)
    }

    getResults = () => {
        const pps = search.get()!.product_prices;
        return <Table2 numRows={pps.length} rowHeights={rowHeights}
            columnWidths={[
                256, // image
                500, // title and statuses
                250, // Keyphrase and category
                300, // ASIN, price
            ]}
        >
            <Column name="Image" cellRenderer={imageCellRenderer} />
            <Column name="Title, Rank, Statuses" cellRenderer={titleCellRenderer} />
            <Column name="Keyphrase" cellRenderer={keyphraseCellRenderer} />
            <Column name="ID, ASIN, Price, Dates" cellRenderer={asinPriceCellRenderer} />
        </Table2>
    }

    onFilterCategorySelected = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value || null
        filters.setCategoryId(value as RecordId)

    }

    onHasImageSelected = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value
        filters.setHasImage(value as HasImage)
    }

    onProductUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        filters.setProductUrl(event.target.value)
    }

    renderCategoryRadio = () => {
        const cats = search.get().categories;
        const filterId = filters.categoryId
        const filterValue = filterId || "";
        return <RadioGroup
            label="Category"
            onChange={this.onFilterCategorySelected}
            selectedValue={filterValue}
            inline={true}
        >
            <Radio label="<Any category>" value={""} key={"_any_"} />
            {Object.keys(cats).map((value, index) => {
                const cat = cats[value];
                return <Radio label={cat.title[LANGUAGE_CODE]} value={cat.id} key={cat.code} />
            })}
        </RadioGroup>
    }

    render(): React.ReactNode {
        const res = search.get()
        let helperText = res.ts_query
        if (!helperText) {
            helperText = "Enter your search phrase.";
        }
        return <Container fluid>
            <Row>
                <Col>
                    <FormGroup
                        helperText={helperText}
                        intent={Intent.PRIMARY}
                        labelFor="search-terms"
                        label="Search phrase"
                    >
                        <InputGroup
                            id="search-terms" placeholder="Type in some words to search for prices..." intent={Intent.PRIMARY}
                            value={filters.searchPhrase}
                            onChange={this.onSearchTermChange}
                        />
                    </FormGroup>

                    <FormGroup
                        helperText={"Public shopify product URL"}
                        labelFor="product-shopify-url"
                        label="Product URL"
                    >
                        <InputGroup
                            id="product-shopify-url" placeholder="+black +ink -sheep" intent={Intent.PRIMARY}
                            value={filters.productUrl}
                            onChange={this.onProductUrlChange}
                        />
                    </FormGroup>

                </Col>
                <Col>
                    <FormGroup
                        intent={Intent.PRIMARY}
                        labelFor="asin"
                        label="ASIN"
                    >
                        <InputGroup
                            id="asin" placeholder="Type in ASIN (shopify listing id)" intent={Intent.PRIMARY}
                            value={filters.asin}
                            onChange={this.onASINChange}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup
                        label="Min/max created"
                        labelFor="min-max-created"
                    >
                        <ControlGroup vertical={true} id="min-max-created">
                            <AbcInput
                                id="min-created"
                                typeCode="timestamptz"
                                abcValue={filters.minCreated}
                                onAbcValueChange={filters.setMinCreated}
                            />
                            <AbcInput
                                id="max-created"
                                typeCode="timestamptz"
                                abcValue={filters.maxCreated}
                                onAbcValueChange={filters.setMaxCreated}
                            />
                        </ControlGroup>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup
                        label="Min/max modified"
                        labelFor="min-max-modified"
                    >
                        <ControlGroup vertical={true} id="min-max-modified">
                            <AbcInput
                                id="min-modified"
                                typeCode="timestamptz"
                                abcValue={filters.minModified}
                                onAbcValueChange={filters.setMinModified}
                            />
                            <AbcInput
                                id="max-modified"
                                typeCode="timestamptz"
                                abcValue={filters.maxModified}
                                onAbcValueChange={filters.setMaxModified}
                            />
                        </ControlGroup>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    {this.renderCategoryRadio()}
                </Col>
                <Col>
                    <RadioGroup
                        label="Has image?"
                        onChange={this.onHasImageSelected}
                        selectedValue={filters.hasImage}
                        inline={true}
                    >
                        <Radio label="All" value={""} />
                        <Radio label="Has image" value={"1"} />
                        <Radio label="No image" value={"0"} />
                    </RadioGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    {res.warning ? <Callout intent={Intent.WARNING}>{res.warning}</Callout> : null}
                </Col>
            </Row>
            <Row>
                {loading.get() ? <Spinner /> : this.getResults()}
            </Row>


        </Container>
    }
}
