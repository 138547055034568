import { observer } from "mobx-react";
import * as React from "react";
import { WithRouterProps, withRouter } from "../../navigation/WithRouter";

import { IconNames } from '@blueprintjs/icons';

import { Button, Callout, Dialog, DialogBody, DialogFooter, FormGroup, InputGroup, Intent, NumericInput } from "@blueprintjs/core";
import { IObservableArray, action, makeObservable, observable, reaction, runInAction, toJS } from "mobx";
import { Col, Container, Row } from "react-grid-system";
import { dispatcher } from "../../App";
import { Api } from "../../api/Api";
import { IRecord, RecordId } from "../../api/Record";
import { IViewFilter } from "../../api/View";
import { ViewGridFrame } from "../../components/frames/view/ViewGridFrame";
import { AsyncViewSelect } from "../../components/inputs/AsyncViewSelect";
import { MjBotRecord } from "../../data/schema/sys/mj_bot";
import { ConfirmDialog } from "../../dialog/ConfirmDialog";
import { showError, showSuccess, showWarning } from "../../dialog/Notification";
import { appStore } from "../../store/AppStore";
import "./ProductDetailPage.scss";

const mj_bots: IObservableArray<MjBotRecord> = observable([]);
const searchKeyphrase = observable.box("");

const loadMjBots = async () => {
    const oid = Api.meta.get_relation_oid_by_name("sys.mj_bot")
    const vs = appStore.getViewSource('mj_dl_queue', oid);
    await vs.reload()
    const data = vs.rows as MjBotRecord[];
    console.log(data)
    runInAction(() => {
        mj_bots.replace(data)
    })
}

const mjBotId = observable.box<RecordId | null>('01895a01-96d7-8180-8ef0-408de8264092');
const queueFilter = observable.box<IViewFilter>({ equals: {} })
const showAddDialog = observable.box<boolean>(false)

reaction(
    () => [mjBotId.get(), searchKeyphrase.get()],
    () => {
        queueFilter.set({ equals: { mj_bot_id: mjBotId.get() }, simple: searchKeyphrase.get()||undefined })
    }
)

const openAddDialog = () => { runInAction(() => { showAddDialog.set(true) }) }
const closeAddDialog = () => { runInAction(() => { showAddDialog.set(false) }) }

const saving = observable.box<boolean>(false)
const setSaving = (value: boolean) => { runInAction(() => saving.set(value)) }

const VIEWSOURCE_PATH = "mj_dl_queue_page"

const get_queue_view_oid = () => {
    return Api.meta.get_relation_oid_by_name("product.v_mj_dl_queue")
}

const getViewSource = () => {
    return appStore.getViewSource(VIEWSOURCE_PATH, get_queue_view_oid())
}

const canRemoveTask = () => {
    const vs = getViewSource()
    const row = vs.currentRow
    if (!row) { return false }
    if (row.started) { return false }
    return true;
}

const removeTask = async () => {
    if (! await ConfirmDialog.open(
        "Confirm",
        "Do you wish to remove this task?")) {
        return
    }
    const vs = getViewSource()
    const row = vs.currentRow
    if (!row) { return }

    try {
        setSaving(true)
        await dispatcher.call("product.mj_dl_queue_remove", { mj_dl_queue_id: row.id })
        runInAction(() => {
            setSaving(false)
            showWarning("Task removed from  queue")
            getViewSource().reload()
        })
    } catch (error) {
        setSaving(false)
        showError(error)
    }
}


const canResetTask = () => {
    const vs = getViewSource()
    const row = vs.currentRow
    if (!row) { return false }
    if (!(row.started)) { return false }
    if (!(row.finished)) { return false }
    return true;
}

const resetTask = async () => {
    if (! await ConfirmDialog.open(
        "Confirm",
        "Do you wish to reset this task, e.g. scan the channel and download all new images?")) {
        return
    }
    const vs = getViewSource()
    const row = vs.currentRow
    if (!row) { return }

    try {
        setSaving(true)
        await dispatcher.call("product.mj_dl_queue_reset", { mj_dl_queue_id: row.id })
        runInAction(() => {
            setSaving(false)
            showWarning("Task was reset")
            getViewSource().reload()
        })
    } catch (error) {
        setSaving(false)
        showError(error)
    }
}


const DEFAULT_QUEUE_REC = {
    ord: 50,
    product_status_id: '0188f19c-39cd-1d7e-2c3a-eba39e51ab7c', // gen_ok
    product_file_status_id: '0189087f-875f-7e48-1935-600c0b17af9f', // complete
}

@observer
class AddToQueueDialog extends React.PureComponent<{}> {
    @observable private rec: any = Object.assign({}, DEFAULT_QUEUE_REC)
    __disp: any = null

    constructor(props: {}) {
        super(props)
        makeObservable(this)
    }

    componentDidMount(): void {
        this.__disp = reaction(showAddDialog.get, this.resetRec, { fireImmediately: true })
    }

    componentWillUnmount(): void {
        if (this.__disp) {
            this.__disp()
            this.__disp = null;
        }
    }


    @action.bound
    private resetRec() {
        this.rec = Object.assign({ mj_bot_id: mjBotId.get() }, DEFAULT_QUEUE_REC)
    }

    private changeRec(fname: string, fvalue: any) {
        runInAction(() => {
            this.rec[fname] = fvalue;
        })
    }

    private get canSave() {
        const ret = !!mjBotId.get() && !this.channelUrlHint && !!this.rec.keyphrase_id && !!this.rec.main_category_id && !!this.rec.product_status_id && !!this.rec.product_file_status_id
        console.log("canSave", ret)
        return ret
    }

    private get channelUrlHint() {
        if (!this.rec.channel_url || !this.rec.channel_url.startsWith("https://")) {
            return "Channel URL should starts with https://"
        } else {
            return ""
        }
    }

    private saveRec = async () => {
        try {
            setSaving(true)
            const params = toJS(Object.assign({}, this.rec))
            params.mj_bot_id = toJS(mjBotId.get())
            console.log(params)
            await dispatcher.call("product.mj_dl_queue_insert", params)
            runInAction(() => {
                setSaving(false)
                showSuccess("Task inserted into queue")
                closeAddDialog()
                getViewSource().reload()
            })
        } catch (error) {
            setSaving(false)
            showError(error)
        }
    }

    onCategorySelect = (value: RecordId | null) => {
        this.changeRec("main_category_id", value)
        this.changeRec("keyphrase_id", null)
    }

    onKeyphraseSelect = (item: IRecord, event?: React.SyntheticEvent<HTMLElement, Event> | undefined) => {
        const cat_id = (item as any).main_category_id || null;
        this.changeRec("main_category_id", cat_id)
        this.changeRec("keyphrase_id", item.id)
    }

    render(): React.ReactNode {
        return <Dialog title="Add new channel to queue" icon={IconNames.ADD} isOpen={showAddDialog.get()}>
            <DialogBody>
                <Container fluid>
                    <Row>
                        <Col>
                            <FormGroup
                                helperText="Select MJ bot for the queue"
                                label="MJ Bot"
                                labelFor="bot-selector"
                                labelInfo="(required)"
                            >
                                <AsyncViewSelect
                                    id="bot-selector"
                                    oid={Api.meta.get_relation_oid_by_name("sys.mj_bot")}
                                    value={mjBotId.get()}
                                    onChange={value => mjBotId.set(value)}
                                    intent={mjBotId.get() ? Intent.NONE : Intent.DANGER}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup
                                helperText="Lower number will be processed first"
                                label="Order of execution"
                                labelFor="ord-edit"
                                labelInfo="(required)"
                            >
                                <NumericInput min={0} max={100} value={this.rec.ord || 0} style={{ width: "3em" }}
                                    onValueChange={this.changeRec.bind(this, "ord")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup
                                helperText={this.channelUrlHint}
                                label="Channel url"
                                labelFor="channel-edit"
                                labelInfo="(required)"
                            >
                                <InputGroup
                                    intent={this.channelUrlHint ? Intent.DANGER : Intent.NONE}
                                    value={this.rec.channel_url || ""}
                                    onValueChange={this.changeRec.bind(this, "channel_url")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup
                                label="Keyphrase"
                                labelFor="keyphrase-selector"
                            >
                                <AsyncViewSelect
                                    id="keyphrase-selector"
                                    intent={this.rec.keyphrase_id ? Intent.NONE : Intent.DANGER}
                                    oid={Api.meta.get_relation_oid_by_name("product.av_keyphrase")}
                                    value={this.rec.keyphrase_id || null}
                                    onChange={this.changeRec.bind(this, "keyphrase_id")}
                                    onItemSelect={this.onKeyphraseSelect}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup
                                helperText="When the items cannot be categorized, use 'community/stock'"
                                label="Main category"
                                labelFor="category-selector"
                                labelInfo="(required)"
                            >
                                <AsyncViewSelect
                                    id="category-selector"
                                    oid={Api.meta.get_relation_oid_by_name("product.category")}
                                    intent={this.rec.main_category_id ? Intent.NONE : Intent.DANGER}
                                    value={this.rec.main_category_id || null}
                                    onChange={this.onCategorySelect}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup
                                label="Product status"
                                labelFor="product-status-selector"
                                helperText="Products will be created in this status when an image is downloaded"
                            >
                                <AsyncViewSelect
                                    id="product-status-selector"
                                    oid={Api.meta.get_relation_oid_by_name("product.product_status")}
                                    value={this.rec.product_status_id || null}
                                    onChange={this.changeRec.bind(this, "product_status_id")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup
                                label="Product file status"
                                labelFor="product-file-status-selector"
                                helperText="Images (product files) will be created in this status when downloaded"
                            >
                                <AsyncViewSelect
                                    id="product-file-status-selector"
                                    oid={Api.meta.get_relation_oid_by_name("product.product_file_status")}
                                    value={this.rec.product_file_status_id || null}
                                    onChange={this.changeRec.bind(this, "product_file_status_id")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </DialogBody>
            <DialogFooter actions={
                <>
                    <Button intent={Intent.NONE} icon={IconNames.CROSS} text="Cancel" onClick={closeAddDialog} />
                    <Button intent={Intent.DANGER} icon={IconNames.RESET} text="Reset" onClick={this.resetRec} />
                    <Button intent={Intent.SUCCESS} icon={saving.get() ? IconNames.FLOPPY_DISK : IconNames.SAVED} text="Save" onClick={this.saveRec}
                        disabled={!this.canSave || saving.get()}
                    />
                </>

            }
            />
        </Dialog>
    }
}

interface Params { }
type ProductMjDlQueueProps = WithRouterProps<Params>;


@observer
class ProductMjDlQueueInner extends React.PureComponent<ProductMjDlQueueProps> {
    componentDidMount(): void {
        if (mj_bots.length === 0) {
            loadMjBots()
        }
    }

    render(): React.ReactNode {
        return <>
            <Container fluid>
                <Row>
                    <Col lg={6}>
                        <FormGroup
                            helperText="Select MJ bot to display channel download queue"
                            label="MJ Bot"
                            labelFor="bot-selector"
                            labelInfo="(required)"
                        >
                            <AsyncViewSelect
                                id="bot-selector"
                                oid={Api.meta.get_relation_oid_by_name("sys.mj_bot")}
                                value={mjBotId.get()}
                                onChange={value => mjBotId.set(value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={2}>
                        <FormGroup
                            helperText="Press to add a new channel to the queue"
                            label="Add new"
                            labelFor="add-new"
                        >
                            <Button
                                id="add-new"
                                text="Add new"
                                icon={IconNames.ADD}
                                intent={Intent.PRIMARY}
                                onClick={openAddDialog}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={2}>
                        <FormGroup
                            helperText="Reset/retry"
                            label="Reset"
                            labelFor="reset-task"
                        >
                            <Button
                                id="reset-task"
                                text="Reset task"
                                icon={IconNames.WARNING_SIGN}
                                intent={Intent.WARNING}
                                disabled={!canResetTask()}
                                onClick={resetTask}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={2}>
                        <FormGroup
                            helperText="Remove task from queue"
                            label="Remove"
                            labelFor="remove-task"
                        >
                            <Button
                                id="remove-task"
                                text="Remove task"
                                icon={IconNames.DELETE}
                                intent={Intent.DANGER}
                                disabled={!canRemoveTask()}
                                onClick={removeTask}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={2}>
                        <FormGroup
                            helperText="Keyphrase search"
                            label="Keyphrase"
                            labelFor="keyphrase-search"
                        >
                            <InputGroup
                                id="keyphrase-search"
                                value={searchKeyphrase.get()}
                                onValueChange={(value) => {
                                    runInAction(() => {
                                        searchKeyphrase.set(value)
                                    })
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={2}>
                        <FormGroup
                            helperText="Reload table data"
                            label="Refresh"
                            labelFor="refresh"
                        >
                            <Button
                                id="refresh"
                                text="Refresh"
                                icon={IconNames.RESET}
                                intent={Intent.NONE}
                                onClick={getViewSource().reload}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {mjBotId.get()
                            ? <ViewGridFrame
                                oid={get_queue_view_oid()}
                                storagePath={VIEWSOURCE_PATH}
                                masterFilter={queueFilter.get()}
                            />
                            : <Callout intent={Intent.WARNING}>Please select a bot to diplay its queue</Callout>
                        }
                    </Col>
                </Row>
            </Container>
            <AddToQueueDialog />
        </>
    }
}

export const ProductMjDlQueuePage = withRouter(ProductMjDlQueueInner)
