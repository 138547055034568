

export const KEY_UP = 38;
export const KEY_DOWN = 40;
export const KEY_RIGHT = 39;
export const KEY_LEFT = 37;
export const KEY_PG_UP = 33;
export const KEY_PG_DOWN = 34;
export const KEY_HOME = 36;
export const KEY_END = 35;

type KeyboardEventHandler = (event: KeyboardEvent) => void;

class Keyboard {
    private keyEventListeners : { [eventType:string] : KeyboardEventHandler[] } = {
        'keydown' : []
    };

    constructor() {
        document.addEventListener('keydown', this.notifyListeners.bind(this, "keydown"));
    }

    private notifyListeners (eventType:string, event: KeyboardEvent) {
        this.keyEventListeners[eventType].forEach((listener) => listener(event));
    }

    public addListener(eventType: string, listener: KeyboardEventHandler) {
        this.keyEventListeners[eventType].push(listener);
    }

    public removeListener(eventType: string, listener: KeyboardEventHandler) {
        const index = this.keyEventListeners[eventType].indexOf(listener);
        if (index>=0) {
            this.keyEventListeners[eventType].splice(index, 1);
        }
    }

}

export const keyboard = new Keyboard();