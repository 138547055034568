import * as React from 'react';
import { IComponentCollection } from './ComponentCollection';
import { observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';
import { DynamicLayoutConfiguration } from '../../../store/layout/DynamicLayoutConfiguration';
import { uiStore } from '../../../store/UIStore';
import { showError, showSuccess } from '../../../dialog/Notification';
import { Callout, Intent, Button, Card, H5 } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { DynamicLayoutEditorDialog } from '../../../dialog/DynamicLayoutEditorDialog';
import { Container, Row, Col } from 'react-grid-system';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

interface IDynamicLayoutProps {
    storagePath: string;
    collection: IComponentCollection;
}

/** Dynamic layout */
@observer
export class DynamicLayout extends React.PureComponent<IDynamicLayoutProps> {
    @observable configuration: DynamicLayoutConfiguration | null = null;

    constructor(props: IDynamicLayoutProps, configKey:string) {
        super(props);
        this.loadConfiguration();
        makeObservable(this)
    }

    private loadConfiguration = async () => {
        let configuration: DynamicLayoutConfiguration;
        try {
            configuration = await uiStore.getlayoutConfiguration(this.props.storagePath);
        } catch (error) {
            showError(error);
            configuration = new DynamicLayoutConfiguration();
        }
        runInAction(() => { this.configuration = configuration; })
    }

    private openEditor = async () => {
        if (await DynamicLayoutEditorDialog.open(this.configuration!, this.props.collection)) {
            this.configuration!.save(this.props.storagePath).then(() => {
                showSuccess(null, "Elrendezés mentve.")
            })
        }
    }

    private renderConfig = (cfg: DynamicLayoutConfiguration, key:string) => {
        const componentKey = cfg.options.componentKey;
        const items = cfg.items;
        const provider = componentKey?this.props.collection.get(componentKey):undefined;

        switch (cfg.type) {
            case "empty":
                return <Callout key={key} intent={Intent.WARNING} icon={IconNames.INFO_SIGN}>
                    Ehhez a nézethez még nincs beállítva elrendezés.
                    <br />
                    <Button icon={IconNames.CONTROL} onClick={this.openEditor}>
                        Új elrendezés beállítása
                    </Button>
                </Callout>;
            case "card":
                return <Card key={key}>
                    {cfg.options["title"]?<H5>{cfg.options["title"]}</H5>:null}
                    {cfg.options["description"]?<p>{cfg.options["description"]}</p>:null}
                    {items.map((cfg,index)=> this.renderConfig(cfg, key+"-"+index))}
                </Card>;
            case "component":
                if (provider) {
                    return provider.createElement();
                } else {
                    return <Callout key={key} intent={Intent.DANGER} icon={IconNames.ERROR}>
                        Nincs megadva komponens ({cfg.type}).
                    <br />
                        <Button icon={IconNames.CONTROL} onClick={this.openEditor}>
                            Komponens beállítása
                    </Button>
                    </Callout>
                }
            case "container":
                return <Container fluid key={key}>{items.map((cfg, index) => this.renderConfig(cfg, key+"-"+index) )}</Container>
            case "row":
                    return <Row key={key}>{items.map((cfg, index) => this.renderConfig(cfg, key+"-"+index) )}</Row>
            case "column":
                    return <Col 
                            key={key}
                            sm={cfg.getOption("sm")}
                            md={cfg.getOption("md")}
                            lg={cfg.getOption("lg")}
                        >{items.map((cfg, index) => this.renderConfig(cfg, key+"-"+index) )}</Col>
            case "tabs":
                return <Tabs key={key}>
                    <TabList>
                        {items.map((cfg, index)=> <Tab key={index}>{cfg.options["title"] || `Tab ${index}`}</Tab>)}
                    </TabList>
                    {items.map((cfg,index)=> this.renderConfig(cfg, key+"-"+index))}
                </Tabs>
            case "tab":
                return <TabPanel key={key}>
                    {items.map((cfg,index)=> this.renderConfig(cfg, key+"-"+index))}
                </TabPanel>
            default:
                return <Callout key={key} intent={Intent.DANGER} icon={IconNames.ERROR}>
                    Belső hiba: ismeretlen layout típus ({cfg.type}).
                    <br />
                    <Button icon={IconNames.CONTROL} onClick={this.openEditor}>
                        Új elrendezés beállítása
                    </Button>
                </Callout>
        }    
    }

    public render() {
        if (this.configuration === null) {
            return <Callout key={"open-editor"} intent={Intent.WARNING} icon={IconNames.TIME}>
                Betöltés folyamatban...
            </Callout>
        } else {
            return <>
                {uiStore.designMode?<Button  key={"open-editor"} icon={IconNames.CONTROL} onClick={this.openEditor} />:null}                
                {this.renderConfig(this.configuration, "root")}
                </>;
       }
    }
}