import { observer } from "mobx-react";
import * as React from "react";
import { WithRouterProps, withRouter } from "../../navigation/WithRouter";

import { IconNames } from '@blueprintjs/icons';

import { Button, Callout, EditableText, FormGroup, Intent } from "@blueprintjs/core";
import { observable, reaction, runInAction } from "mobx";
import { Col, Container, Row } from "react-grid-system";
import { dispatcher } from "../../App";
import { Api } from "../../api/Api";
import { RecordId } from "../../api/Record";
import { IViewFilter } from "../../api/View";
import { ViewGridFrame } from "../../components/frames/view/ViewGridFrame";
import { AsyncViewSelect } from "../../components/inputs/AsyncViewSelect";
import { ConfirmDialog } from "../../dialog/ConfirmDialog";
import { showError, showSuccess, showWarning } from "../../dialog/Notification";
import { appStore } from "../../store/AppStore";
import "./ProductDetailPage.scss";

const categoryId = observable.box<RecordId | null>(null);
const keyphrasesFilter = observable.box<IViewFilter>({ equals: {} })
const keyphraseLines = observable.box<string>("")
const saving = observable.box<boolean>(false)
const setSaving = (value: boolean) => { runInAction(() => saving.set(value)) }

const masterFilter = observable.box<IViewFilter>({equals: {}});


const VIEWSOURCE_PATH = "product_keyphrase"

const get_keyphrases_view_oid = () => {
    return Api.meta.get_relation_oid_by_name("product.av_keyphrase")
}

const getViewSource = () => {
    return appStore.getViewSource(VIEWSOURCE_PATH, get_keyphrases_view_oid(), masterFilter.get(), {autoReload: false})
}

reaction(
    ()=> categoryId.get(),
    () => {
        console.log(categoryId.get())
        runInAction(() => {
            console.log("categoryId changed")
            const mf = masterFilter.get();
            mf.equals = { main_category_id : categoryId.get()};
            masterFilter.set(mf);
            getViewSource().reload()
        })
    }
)


const canRemoveKeyphrase = () => {
    const vs = getViewSource()
    const row = vs.currentRow
    if (!row) { return false }
    if (row.started) { return false }
    return true;
}


const removeKeyphrase = async () => {
    if (! await ConfirmDialog.open(
        "Confirm",
        "Do you wish to remove this keyphrase?")) {
        return
    }
    const vs = getViewSource()
    const row = vs.currentRow
    if (!row) { return }

    try {
        setSaving(true)
        await dispatcher.call("product.keyphrase_remove", { keyphrase_id: row.id })
        runInAction(() => {
            setSaving(false)
            showWarning("Keyphrase deleted")
            getViewSource().reload()
        })
    } catch (error) {
        setSaving(false)
        showError(error)
    }
}



interface Params { }
type MassKeyphraseProps = WithRouterProps<Params>;


@observer
class MassKeyphrasePageInner extends React.PureComponent<MassKeyphraseProps> {

    KeyphraseLinesChanged = (value: string) => {
        runInAction(() => {
            keyphraseLines.set(value);
        })
    }

    addAllKeyphrases = async () => {
        let keyphrases = keyphraseLines.get().split("\n").map(s => s.trim()).filter(s => !!s)
        if (!keyphrases.length) {
            showError("Please type in some keyphrases")
            return
        }
        try {
            await dispatcher.call<string[]>("product.add_keyphrases", {
                main_category_id: categoryId.get(),
                keyphrases
            })
            runInAction(() => {
                keyphraseLines.set("")
            })
            getViewSource().reload()
            showSuccess("Keyphrase(s) added")
        } catch (e) {
            showError(e)
        }
    }

    render(): React.ReactNode {
        return <>
            <Container fluid>
                <Row>
                    <Col lg={6}>
                        <FormGroup
                            helperText="Select category"
                            label="Category"
                            labelFor="category-selector"
                            labelInfo="(required)"
                        >
                            <AsyncViewSelect
                                id="category-selector"
                                oid={Api.meta.get_relation_oid_by_name("product.category")}
                                value={categoryId.get()}
                                onChange={value => categoryId.set(value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={2}>
                        <FormGroup
                            helperText="Remove"
                            label="Remove"
                            labelFor="remove-keyphrase"
                        >
                            <Button
                                id="remove-keyphrase"
                                text="Remove keyphrase"
                                icon={IconNames.DELETE}
                                intent={Intent.DANGER}
                                disabled={!canRemoveKeyphrase()}
                                onClick={removeKeyphrase}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={2}>
                        <FormGroup
                            helperText="Reload table data"
                            label="Refresh"
                            labelFor="refresh"
                        >
                            <Button
                                id="refresh"
                                text="Refresh"
                                icon={IconNames.RESET}
                                intent={Intent.NONE}
                                onClick={getViewSource().reload}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {categoryId.get()
                            ? <ViewGridFrame
                                oid={get_keyphrases_view_oid()}
                                storagePath={VIEWSOURCE_PATH}
                                masterFilter={keyphrasesFilter.get()}
                            />
                            : <Callout intent={Intent.WARNING}>Please select a category to diplay its keyphrases</Callout>
                        }
                    </Col>
                </Row>
                {categoryId.get() && <Row>
                    <Col sm={12} lg={12}>
                        <EditableText multiline={true} minLines={3} maxLines={25}
                            placeholder="Paste multiple keyphrases here, one keyphrase per line"
                            value={keyphraseLines.get()}
                            onChange={this.KeyphraseLinesChanged}
                        />
                    </Col>
                    <Col sm={12} lg={12}>
                        <Button
                            id="add"
                            text="Add all"
                            icon={IconNames.ADD}
                            intent={Intent.PRIMARY}
                            onClick={this.addAllKeyphrases}
                        />
                    </Col>
                </Row>
                }
            </Container>
        </>
    }
}

export const MassKeyphrasePage = withRouter(MassKeyphrasePageInner)
