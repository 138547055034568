import * as React from "react";
import {
  Route,
  Routes
} from "react-router-dom";
import MainPage from "../pages/MainPage";
import ProfilePage from "../pages/ProfilePage";
import { ProductCommunityReviewPage } from "../pages/product/ProductCommunityReviewPage";
import { ProductDetailPage } from "../pages/product/ProductDetailPage";
import { ProductMjDlQueuePage } from "../pages/product/ProductMjDlQueuePage";
import ProductMjRev1Page from "../pages/product/ProductMjRev1Page";
import ProductPriceSearchPage from "../pages/product/ProductPriceSearchPage";
import ProductSearchPage from "../pages/product/ProductSearchPage";
import CustomRouter from "./CustomRouter";
import { ListEditorFrame } from "../components/frames/composite/ListEditorFrame";
import { Api } from "../api/Api";
import SysUserListPage from "../pages/sys/SysUserListPage";
import { ProductPromptPage } from "../pages/product/ProductPromptPage";
import { MassKeyphrasePage } from "../pages/product/MassKeyphrasePage";

export const ROUTE_PROFILE = "/profile";
export const ROUTE_MJ_REV1 = "/product/rev_1";
export const ROUTE_SEARCH_PRODUCT = "/product/search_product";
export const ROUTE_SEARCH_PRODUCT_PRICE = "/product/search_product_price";
export const ROUTE_PRODUCT_DETAILS = "/product/details";
export const ROUTE_PRODUCT_COMMUNITY_REVIEW = "/product/community_review";
export const ROUTE_MJ_DL_QUEUE = "/product/mj_dl_queue"
export const ROUTE_PRODUCT_KEYPHRASE = "/product/keyphrase";
export const ROUTE_PRODUCT_MASS_KEYPHRASE = "/product/mass_keyphrase";
export const ROUTE_PRODUCT_PROMPT = "/product/prompt";

export const ROUTE_SYS_USER = "/sys/sys_user";

interface IRoutesProps { }


export default class MainRoutes extends React.Component<IRoutesProps, {}> {
  public render() {
    return <CustomRouter>
      <Routes >
        <Route path="/" element={<MainPage />}></Route>
        <Route path={ROUTE_PROFILE} element={<ProfilePage />}></Route>
        <Route path={ROUTE_MJ_REV1} element={<ProductMjRev1Page />}></Route>
        <Route path={ROUTE_SEARCH_PRODUCT} element={<ProductSearchPage />}></Route>
        <Route path={ROUTE_SEARCH_PRODUCT_PRICE} element={<ProductPriceSearchPage />}></Route>
        <Route path={ROUTE_PRODUCT_DETAILS + "/:productId"} element={<ProductDetailPage />}></Route>
        <Route path={ROUTE_PRODUCT_COMMUNITY_REVIEW} element={<ProductCommunityReviewPage />}></Route>
        <Route path={ROUTE_MJ_DL_QUEUE} element={<ProductMjDlQueuePage />}></Route>
        <Route path={ROUTE_PRODUCT_KEYPHRASE} element={
            <ListEditorFrame 
              tableOid={Api.meta.get_relation_oid_by_name("product.keyphrase")}
              viewOid={Api.meta.get_relation_oid_by_name("product.av_keyphrase")}
            />
          }></Route>          
          <Route path={ROUTE_PRODUCT_MASS_KEYPHRASE} element={<MassKeyphrasePage />}></Route>
          <Route path={ROUTE_PRODUCT_PROMPT} element={<ProductPromptPage />}></Route>
          

        <Route path={ROUTE_SYS_USER} element={<SysUserListPage />}></Route>
      </Routes>
    </CustomRouter>
  }
}
