import * as React from "react";

import { makeObservable } from "mobx";
import { observer } from 'mobx-react';



@observer
export default class SysUserListPage extends React.PureComponent<{}> {

    constructor(props: {}) {
        super(props)
        makeObservable(this, {})
    }


    render(): React.ReactNode {
        return <div>TODO</div>
    }
}
