import { IReactionDisposer, makeObservable, reaction, toJS } from "mobx";
import * as React from "react";
import { RecordEditorFrame } from "../record/RecordEditorFrame";
import { ViewFilterFrame } from "../view/ViewFilterFrame";
import { ViewGridFrame } from "../view/ViewGridFrame";
import { appStore } from "../../../store/AppStore";
import RecordSource, { ILastSavedRecord } from "../../../store/record/RecordSource";
import { ViewSource } from "../../../store/view/ViewSource";
import { IComponentProvider, COMPONENT_CATEGORY_DETAIL_DATA } from "../layout/ComponentProvider";
import { IViewFilter } from "../../../api/View";
import { Callout, Intent, Tag, Button } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { IComponentCollection } from "../layout/ComponentCollection";
import { uiStore } from "../../../store/UIStore";
import { ConfirmDialog } from "../../../dialog/ConfirmDialog";
import { showError } from "../../../dialog/Notification";
import { NotificationDialog } from "../../../dialog/NotificationDialog";
import { RelationMeta } from "../../../api/Meta";
import { Api } from "../../../api/Api";

export interface IListEditorFrameProps {
    tableOid: number; // oid of the table
    viewOid: number; // oid of the wiew
    /**
     * Use storagePath to define an alternative storage path.
     * It determines the location of the underlying ViewSource object as well
     * as the configuration key prefix for local ViewFrame configuration.
     * Configuration includes: visibility, order and size of columns.
     */
    storagePath?: string;
}

/**
 * ListEditorPage is a general page that is used as a default/fallback user interface for managing
 * data in a given table. It contains:
 *
 * - a ViewFrame for displaying and filtering tabular data
 * - a RecordEditorFrame for editing data
 *
 * You should be able to extend this class. (Add detail view frames etc.)
 *
 */
@observer
export class ListEditorFrame extends React.Component<IListEditorFrameProps, {}> implements IComponentProvider {
    /* These three methods make it an IComponentProvider */
    public get key(): string { return this.viewStoragePath; }
    public readonly category: string = COMPONENT_CATEGORY_DETAIL_DATA;
    public get displaylabel(): string { return this.recordSource.meta.displaylabel; }
    public get description(): string | undefined { return undefined; }

    /*
    TODO: do we need this?
    public get viewName() {
        return this.props.viewName || "autoview." + this.props.tableOid;
    }
    */

    /** Get storage path for the connected viewSource. It also determines the configuration key prefix. */
    public get viewStoragePath(): string {
        return this.props.storagePath || ""+this.props.viewOid;
    }

    public get tableOid() {
        return this.props.tableOid;
    }

    public get recordStoragePath(): string {
        return this.props.storagePath || ""+this.tableOid;
    }

    public get viewSource(): ViewSource {
        return appStore.getViewSource(this.viewStoragePath, this.props.viewOid);
    }

    public get viewMeta(): RelationMeta {
        return this.viewSource.meta;
    }

    public get masterFilter(): IViewFilter {
        return this.viewSource.masterFilter;
    }

    public get recordSource(): RecordSource {
        return appStore.getRecordSource(this.recordStoragePath, this.props.tableOid);
    }

    public get tableMeta(): RelationMeta {
        return this.recordSource.meta;
    }

    private viewFrameDisposer: IReactionDisposer | null;
    private recordEditorFrameDisposer: IReactionDisposer | null;
    private masterValuesDisposer: IReactionDisposer | null;
    //private detailComponentsAdded: boolean;

    constructor(props: IListEditorFrameProps) {
        super(props);
        // This should be a PureComponent, but it seems that MobX generates shouldComponentUpdate methods
        // for observables. For this reason, we HAVE TO inherit from React.Component and create
        // a dummy state.
        this.state = {};
        this.viewFrameDisposer = null;
        this.recordEditorFrameDisposer = null;
        this.masterValuesDisposer = null;
        this.viewSource.firstReload();
        makeObservable(this, {})
    }

    public componentDidMount() {
        /*         
           Default values of newly created records should have master filter values.
           When the master filter is changed, then copy it into the default values
           of the RecordSource. Please note that setDefaultValues will also set
           the values of the current record, if it does not exist in the database yet.
        */
        this.masterValuesDisposer = reaction(
            () => toJS(this.viewSource.masterFilter.equals),
            () => { this.recordSource.setDefaultValues(this.viewSource.masterFilter.equals); },
            { fireImmediately: true, name: 'copyMasterFilterToDefaultValues' }
        );

        /*
           Whenever the ViewSource moves to a different row OR reloads the current row, we
           also reload that row in the RecordSource.
        */
        this.viewFrameDisposer = reaction(
            () => [this.viewSource.currentRowId, this.viewSource.dataKey],
            () => {
                const rowId = this.viewSource.currentRowId;
                if (rowId === null) {
                    this.recordSource.createNewRecord();
                } else {
                    this.recordSource.loadRecord(rowId);
                }
            },
            { name: "ListEditorPage.loadSelectedRecord", delay: 100 },
        );

        /*
          When the RecordSource has saved/deleted the record, we load it in the ViewSource.
          Please note that this will only load the current row! 
          
          TODO: A known problem is that it causes the RecordSource to reload it again. It
          should not be neccessary because RecordSource.save already updates the current
          record to what is returned by the server.
        */
        this.recordEditorFrameDisposer = reaction(
            () => this.recordSource.lastSaved,
            (lastSaved: ILastSavedRecord | null) => {
                if (lastSaved) {
                    if (lastSaved!.wasNew) {
                        this.viewSource.appendRowById(lastSaved.record.id!);
                    } else {
                        this.viewSource.reloadCurrentRow();
                    }
                }
            },
            { name: "ListEditorPage.reloadSavedRecordInViewSource" },
        );
    }

    public componentWillUnmount() {
        if (this.viewFrameDisposer) {
            this.viewFrameDisposer();
            this.viewFrameDisposer = null;
        }
        if (this.recordEditorFrameDisposer) {
            this.recordEditorFrameDisposer();
            this.recordEditorFrameDisposer = null;
        }
        if (this.masterValuesDisposer) {
            this.masterValuesDisposer();
            this.masterValuesDisposer = null;
        }
    }

    /**
     * Extra components that are passed to RecordEditor for component chaining. 
     * They can then be used by its DynamicLayout.
     */
    protected getExtraComponents() : IComponentCollection|undefined { return undefined; }

    private setDefaultConfig = async () => {
        if (await ConfirmDialog.open(
            "Megerősítés",
            "Ez a művelet a teljes listázó/szerkesztő keret összes beállítását visszaállítja az alapértelmezettre. " +
            "Ez a művelet nem vonható vissza. Biztos benne, hogy ezt szeretné?"
        )) {
            try {
                if (this.props.storagePath) {
                    await Api.userConfig.reset_prefix(this.props.storagePath);
                } else {
                    await Api.userConfig.reset_prefix(this.viewStoragePath);
                    await Api.userConfig.reset_prefix(this.recordStoragePath);
                }
                await NotificationDialog.open("Figyelmeztetés",
                    "A teljes listázó/szerkesztő keret összes beállítása alapértelmezett lett. " +
                    "A változtatások teljes érvényesítéséhez frissítse be a lapot (F5)."
                );
            } catch (error) {
                showError(error);
            }
        }
    }

    public render() {
        if (this.viewSource.needMasterFilterColumn && !this.viewSource.masterIsValid) {
            const vm = this.viewMeta
            const cm = vm.attrs[vm.attr_nti[this.viewSource.needMasterFilterColumn]]
            const detailLabel = cm.displaylabel || 'main record';
            return <Callout intent={Intent.WARNING}>Ez a felület csak akkor érhető el, ha van egy kapcsolódó 
            &nbsp; <Tag intent={Intent.PRIMARY}>{detailLabel}</Tag>
            </Callout>;
        }
        /*
         TODO: add a status bar with total/current rows and the "has more" warning message.
        */
        return (
            <>
                {uiStore.designMode?
                    <Button
                        key="resetConfig"
                        intent={Intent.DANGER}
                        text="Konfigurációt alapértelmezettre állít"
                        onClick={this.setDefaultConfig}
                    />
                :null}
                <ViewFilterFrame oid={this.props.viewOid} storagePath={this.viewStoragePath} />
                <ViewGridFrame oid={this.props.viewOid} storagePath={this.viewStoragePath} />
                <RecordEditorFrame oid={this.tableOid} storagePath={this.recordStoragePath} components={this.getExtraComponents()} />
            </>
        );
    }

    public createElement() { return React.createElement(ListEditorFrame, this.props, null); }

}
