import { dispatcher } from "../App";
import { ITimestamp } from "../data/abc/timestamptz";
import { RecordId } from "./Record";

export interface IProfileConfirmInfo {
    created: ITimestamp | null;
    valid_until: ITimestamp | null;
    confirmed: ITimestamp | null;
}

export interface IProfileInfo {
    id: string; // uuid
    email: string;
    fullname: string;

    // TODO - these are not stored on the server yet!
    valid_from  : ITimestamp | null;
    valid_until  : ITimestamp | null;
        
    email_confirm: IProfileConfirmInfo;
}

export class Security {
    /**
     * 
     * Return information about the currently logged in user.
     * 
     */
    public async me(): Promise<IProfileInfo> {
        return dispatcher.call<IProfileInfo>("security.me");
    }


    /**
     * Change password.
     * 
     * @param old_password
     * @param new_password
     */
    public async change_own_password(old_password: string, new_password: string): Promise<boolean> {
        try {
            const result = await dispatcher.call<boolean>("security.change_own_password", { old_password, new_password });
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }
    }


    /**
     * Change password of another user.
     * 
     * @param user_id
     * @param old_password
     * @param new_password
     */
    public async change_password(user_id:RecordId, my_admin_password: string, new_password: string): Promise<boolean> {
        try {
            const result = await dispatcher.call<boolean>("security.change_password", { user_id, my_admin_password, new_password });
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }
    }


    /**
     * Request new password reset link for a user.
     * 
     * @param email 
     */
    public request_password_reset(email: string, captcha_token: string): Promise<boolean> {
        return dispatcher.call<boolean>("security.request_password_reset", { email, captcha_token  });
    }

}
