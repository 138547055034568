//import { Button, ButtonGroup, Classes, Intent, TreeNode, Menu, MenuItem, Popover, Tree, Icon, RadioGroup, Radio } from "@blueprintjs/core";
//import { IconNames } from "@blueprintjs/icons";
import { observer } from "mobx-react";
import * as React from "react";
//import { ComponentSelectorDialog } from "../../../dialog/ComponentSelectorDialog";
import {
    DynamicLayoutConfiguration,
    //    LAYOUT_TYPE_ICONS,
    //    LAYOUT_TYPE_LABELS,
    //    LAYOUT_ALLOWED_CHILDREN,
    //    LAYOUT_ROOT_TYPES,
} from "../../../store/layout/DynamicLayoutConfiguration";
import { IComponentCollection } from "./ComponentCollection";
import { makeObservable /*, observable, runInAction */ } from "mobx";
// import { Container, Row, Col } from "react-grid-system";

interface IDynamicLayoutEditorProps {
    layoutConfiguration: DynamicLayoutConfiguration;
    components: IComponentCollection;
}

/*
const MODE_STRUCTURE = 1;
const MODE_COPY_PASTE = 2
const MODE_OPTION_EDIT = 3;
*/

@observer
export class DynamicLayoutEditor extends React.PureComponent<IDynamicLayoutEditorProps> {

    constructor(props: IDynamicLayoutEditorProps) {
        super(props);
        makeObservable(this, {})
    }

    public render() {
        return <div>TODO! DynamicLayoutEditor.render</div>
    }

    /*
    @observable private clipboard: DynamicLayoutConfiguration | null = null;
    @observable private mode: number = MODE_STRUCTURE;

    public render() {
        const nodes: TreeNode<DynamicLayoutConfiguration>[] = [this.createTree(this.props.layoutConfiguration, 0, 'root')];
        return <Container fluid>
            <Row>
                <Col sm={12}>
                    <RadioGroup label="Mód" selectedValue={this.mode} inline={true}
                        onChange={event => runInAction(() => {
                            this.mode = parseInt((event as any).target.value);
                            this.clipboard = null;
                        })}
                    >
                        <Radio label="Struktúra módosítás" value={MODE_STRUCTURE} />
                        <Radio label="Kivágás/beillesztés" value={MODE_COPY_PASTE} />
                        <Radio label="Layout opciók" value={MODE_OPTION_EDIT} />
                    </RadioGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Tree<DynamicLayoutConfiguration> contents={nodes} className={Classes.ELEVATION_0} />                    
                </Col>
            </Row>
        </Container>


    }

    private createTypeMenu = (
        current: DynamicLayoutConfiguration | null,
        parent: DynamicLayoutConfiguration | null,
        onTypeClick: (typ: string) => void): React.ReactElement => {
        const menuItems: any[] = [];
        const allowedTypes = parent ? LAYOUT_ALLOWED_CHILDREN[parent.type] : LAYOUT_ROOT_TYPES;
        allowedTypes.forEach(typ => {
            const icon: any = LAYOUT_TYPE_ICONS[typ] || IconNames.DISABLE;
            menuItems.push(
                <MenuItem
                    key={typ}
                    icon={icon}
                    text={LAYOUT_TYPE_LABELS[typ] || "Ismeretlen (???)"}
                    className={current && current.type === typ ? Classes.INTENT_SUCCESS : ""}
                    onClick={() => onTypeClick(typ)}
                />,
            );
        });
        return <Menu>{menuItems}</Menu>;
    };

    /** This is NASTY!!! Need to make it better. 
    private createTree = (
        config: DynamicLayoutConfiguration,
        level: number,
        keyPrefix: string
    ): TreeNode<DynamicLayoutConfiguration> => {
        const icon: any = LAYOUT_TYPE_ICONS[config.type] || IconNames.DISABLE;
        const buttons: any[] = [];
        const clipIntent = this.clipboard ? Intent.NONE : null;

        // Most operations are hidden when there is something on the clipboard.
        if (this.mode === MODE_STRUCTURE) {
            if (LAYOUT_ALLOWED_CHILDREN[config.type].length > 0) {
                buttons.push(
                    <Popover key={`add-child-${keyPrefix}`}
                        content={this.createTypeMenu(null, config, typ => config.addItem(typ))}
                    >
                        <Button
                            icon={IconNames.ADD}
                            intent={clipIntent || Intent.PRIMARY}
                            minimal={true}
                            title="Új levél hozzáadása"
                            disabled={!!this.clipboard}
                        />
                    </Popover>
                );
            } else {
                buttons.push(
                    <Button
                        key={`add-child-${keyPrefix}`}
                        intent={Intent.NONE}
                        minimal={true}
                        disabled={true}
                    />
                );
            }
            if (level > 0) {
                buttons.push(
                    <Button
                        key={`remove-${keyPrefix}`}
                        icon={IconNames.REMOVE}
                        intent={clipIntent || Intent.DANGER}
                        minimal={true}
                        title="Teljes részfa eltávolítása"
                        onClick={config.remove}
                        disabled={!!this.clipboard}
                    />,
                );
            }

            // Change position inside parent. 
            if (config.prevSibling) {
                buttons.push(
                    <Button
                        key={`moveup-${keyPrefix}`}
                        icon={IconNames.ARROW_UP}
                        intent={Intent.PRIMARY}
                        minimal={true}
                        title="Fölfelé mozgat"
                        onClick={() => {
                            runInAction(() => {
                                config.prevSibling!.swapWith(config);
                            })
                        }}
                        disabled={!!this.clipboard}
                    />
                );
            } else {
                buttons.push(
                    <Button
                        key={`moveup-${keyPrefix}`}
                        intent={Intent.NONE}
                        minimal={true}
                        disabled={true}
                    />
                );
            }
            if (config.nextSibling) {
                buttons.push(
                    <Button
                        key={`movedown-${keyPrefix}`}
                        icon={IconNames.ARROW_DOWN}
                        intent={Intent.PRIMARY}
                        minimal={true}
                        title="Lefelé mozgat"
                        onClick={() => {
                            runInAction(() => {
                                config.nextSibling!.swapWith(config);
                            })
                        }}
                        disabled={!!this.clipboard}
                    />
                );
            } else {
                buttons.push(
                    <Button
                        key={`movedown-${keyPrefix}`}
                        intent={Intent.NONE}
                        minimal={true}
                        disabled={true}
                    />
                );
            }
        }

        if (this.mode === MODE_COPY_PASTE) {
            if (this.clipboard === null && level > 0) {
                // Move with a "clipboard" 
                buttons.push(
                    <Button
                        key={`copy-${keyPrefix}`}
                        icon={IconNames.CLIPBOARD}
                        intent={Intent.NONE}
                        minimal={true}
                        title="Másol"
                        onClick={() => { runInAction(() => { this.clipboard = config; }) }}
                    />
                );
            } else if (this.clipboard !== null && config === this.clipboard) {
                buttons.push(
                    <Button
                        key={`uncopy-${keyPrefix}`}
                        icon={IconNames.DISABLE}
                        intent={Intent.DANGER}
                        minimal={true}
                        title="Mégse másol"
                        onClick={() => { runInAction(() => { this.clipboard = null; }) }}
                    />
                );
            } else if (this.clipboard !== null && !config.isContained(this.clipboard) && config !== this.clipboard.parent) {
                if (LAYOUT_ALLOWED_CHILDREN[config.type].includes(this.clipboard.type)) {
                    buttons.push(
                        <Button
                            key={`moveunder-${keyPrefix}`}
                            icon={IconNames.MOVE}
                            intent={Intent.PRIMARY}
                            minimal={true}
                            title="Beilleszt"
                            onClick={() => {
                                runInAction(() => {
                                    this.clipboard!.moveUnder(config);
                                    this.clipboard = null;
                                })
                            }}
                        />
                    );
                }
            }
        }


        buttons.push(
            <Popover key={`select-type-${keyPrefix}`}
                content={this.createTypeMenu(config, config.parent, typ => config.setType(typ))}>
                <Button icon={icon} intent={Intent.SUCCESS} minimal={true} text={config.typeLabel}
                    disabled={!!this.clipboard}
                />
            </Popover>
        );

        if (config.type === "component") {
            const key = config.options.componentKey;
            const components = this.props.components;
            let component = null;
            let label: string = '<Komponens?>';
            let intent: string = Intent.WARNING;
            if (key) {
                if (components.has(key)) {
                    component = components.get(key)!;
                    label = component.displaylabel;
                    intent = Intent.SUCCESS;
                } else {
                    label = `<Érvénytelen (${key})>`;
                    intent = Intent.DANGER;
                }
            }

            buttons.push(
                <Button
                    key={`select-component-${keyPrefix}`}
                    icon={IconNames.SELECT}
                    intent={(clipIntent || intent) as any}
                    minimal={true}
                    text={label}
                    onClick={() => this.selectComponent(config)}
                    disabled={!!this.clipboard}
                />,
            );
        }



        return {
            childNodes: config.items.map((item, index) => this.createTree(item, level + 1, keyPrefix + "-" + index)),
            id: keyPrefix,
            isExpanded: config.isExpanded,
            label: <ButtonGroup>
                <Icon icon={IconNames.DRAG_HANDLE_VERTICAL} />
                {buttons}
                {this.mode === MODE_OPTION_EDIT ? config.createOptionsEditor() : null}
            </ButtonGroup>,
            nodeData: config
        };
    };

    private selectComponent = (config: DynamicLayoutConfiguration) => {
        ComponentSelectorDialog.open(config, this.props.components);
    };
    */
}
