import { Button, Callout, Classes, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Api } from '../api/Api';
import { uiStore } from '../store/UIStore';
import { showError, showSuccess } from './Notification';
import { RecordId } from '../api/Record';

export interface IChangePasswordDialogProps {
    own : boolean; /** Are you changing your own password? */
    user_id ?: RecordId;
}

@observer
export class ChangePasswordDialog extends React.PureComponent<IChangePasswordDialogProps> {
    @observable private oldPassword: string;
    @observable private password1: string;
    @observable private password2: string;

    constructor(props: IChangePasswordDialogProps) {
        super(props);
        this.oldPassword = "";
        this.password1 = "";
        this.password2 = "";
        if (props.own) {
            if (props.user_id) {
                throw new Error("Belső hiba: saját jelszó változtatásnál tilos megadni a user_id-t!");
            }
        } else {
            if (!props.user_id) {
                throw new Error("Belső hiba: másik felhasználó jelszó változtatásnál kötelező megadni a user_id-t!");
            }
        }
        makeObservable(this)
    }

    private onCloseDialog = (event?: React.SyntheticEvent<HTMLElement>) => {
        uiStore.dialogStack.popLastDialog();
    }

    private onOkClicked = async (event?: React.SyntheticEvent<HTMLElement>) => {
        try {
            if (this.props.own) {
                await Api.security.change_own_password(this.oldPassword, this.password1);
                showSuccess(null, "A saját jelszava megváltozott");
            } else {
                await Api.security.change_password(this.props.user_id!, this.oldPassword, this.password1);
                showSuccess(null, "A cél felhasználó jelszava megváltozott");
            }            
            uiStore.dialogStack.popLastDialog();
        } catch (error) {
            showError(error);
        }
    }

    public static open = (own: boolean, user_id ?: RecordId) => {
        uiStore.dialogStack.addDialog(()=><ChangePasswordDialog own={own} user_id={user_id}/>);
    }

    private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key.toLowerCase() === "enter") {
            if (this.changeEnabled) {
                alert('TODO!')
            }
        }
    };

    @action.bound private setOldPassword(e: React.ChangeEvent<HTMLInputElement>) {
        this.oldPassword = e.target.value;
    }

    @action.bound private setPassword1(e: React.ChangeEvent<HTMLInputElement>) {
        this.password1 = e.target.value;
    }

    @action.bound private setPassword2(e: React.ChangeEvent<HTMLInputElement>) {
        this.password2 = e.target.value;
    }

    @computed get errormessage(): string | null {
        if (!this.oldPassword.trim()) {
            return "Kötelező megadni a régi jelszót."
        } else if (!this.password1.trim()) {
            return "Kötelező megadni az új jelszót."
        } else if (!this.password2.trim()) {
            return "Írja be az jelszó megerősítését."
        } else if (this.password1 !== this.password2) {
            return "A beírt jelszavak nem egyeznek."
        } else {
            return null;
        }

    }

    @computed get changeEnabled(): boolean {
        return this.errormessage === null;
    }

    public render() {
        return <Dialog
            isOpen={true}
            icon={IconNames.KEY}
            title={this.props.own?"Saját jelszó megváltoztatása":"Másik felhasználó jelszavának megváltoztatása"}
            onClose={this.onCloseDialog}
        >
            <div className={Classes.DIALOG_BODY}>
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <FormGroup
                                helperText="Írja be a jelenlegi saját jelszavát"
                                label="Jelenlegi saját jelszó"
                                labelFor="old-password-input"
                                labelInfo="(kötelező)"
                            >
                                <InputGroup
                                    id="old-password-input"
                                    placeholder="Írja be a régi jelszavát"
                                    type="password"
                                    intent={this.oldPassword ? Intent.SUCCESS : Intent.DANGER}
                                    value={this.oldPassword}
                                    onChange={this.setOldPassword}
                                    onKeyPress={this.onKeyPress}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12}>
                            <FormGroup
                                helperText={this.props.own?"Írja be az új jelszavát":"Írja be a felhasználó új jelszavát"}
                                label="Új jelszó"
                                labelFor="password-input"
                                labelInfo="(kötelező)"
                            >
                                <InputGroup
                                    id="password-input"
                                    placeholder={this.props.own?"Írja be az új jelszavát":"Írja be a felhasználó új jelszavát"}
                                    type="password"
                                    intent={this.password1 ? Intent.SUCCESS : Intent.DANGER}
                                    value={this.password1}
                                    onChange={this.setPassword1}
                                    onKeyPress={this.onKeyPress}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12}>
                            <FormGroup
                                helperText={this.props.own?"Írja be az új jelszavát újra":"Írja be a felhasználó új jelszavát még egyszer"}
                                label="Új jelszó újra"
                                labelFor="confirm-password-input"
                                labelInfo="(kötelező)"
                            >
                                <InputGroup
                                    id="confirm-password-input"
                                    placeholder={this.props.own?"Írja be az új jelszavát újra":"Írja be a felhasználó új jelszavát még egyszer"}
                                    type="password"
                                    intent={(this.password2 && this.password1 === this.password2) ? Intent.SUCCESS : Intent.DANGER}
                                    value={this.password2}
                                    onChange={this.setPassword2}
                                    onKeyPress={this.onKeyPress}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>

                <div className={Classes.DIALOG_FOOTER}>
                    {this.errormessage ? (
                        <Callout intent={Intent.DANGER}>{this.errormessage}</Callout>
                    ) : null}
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button icon={IconNames.CROSS} onClick={this.onCloseDialog}>Mégse</Button>
                        <Button
                            intent={Intent.PRIMARY}
                            icon={IconNames.CONFIRM}
                            disabled={!this.changeEnabled}
                            onClick={this.onOkClicked}
                        >OK</Button>
                    </div>
                </div>
            </div>
        </Dialog >
    }
}
