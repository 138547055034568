import * as React from "react";
import { appStore } from "../../../store/AppStore";
import { ViewSource } from "../../../store/view/ViewSource";
import { IViewFilter } from "../../../api/View";
import { IViewSourceConfiguration } from "../../../store/view/ViewSourceConfiguration";
import { runInAction } from "mobx";
import { RelationMeta } from "../../../api/Meta";

export interface IViewSourceRelatedProps {
    oid: number;
    /** 
     * Use storagePath to define an alternative storage path.
     * It determines the location of the underlying ViewSource object as well
     * as the configuration key prefix for local ViewFrame configuration.
     * Configuration includes: visibility, order and size of columns.
     */
    storagePath?: string;
    /**
     * When given, this will create the viewSource with the given masterFilter.
     * Beware! If you use the same ViewSource from multiple components, then only
     * one of them should specify masterFilter!
     */
    masterFilter?: IViewFilter;
    /**
     * Default configuration. This will only be used when the ViewSource is first created.
     * It has no effect for already existing ViewSource instances in the store.
     */
    defaultViewSourceConfig?: Partial<IViewSourceConfiguration>;
}

/**
 * ViewSourceRelated is a base class for all components that are connected to a ViewSource.
 */
export class ViewSourceRelated<T extends IViewSourceRelatedProps> extends React.Component<T, {}> {
    /**
     * 
     * Override this for components that are using a ViewSource but not rendering it directly. 
     * The value of this property MUST be immutable!
     * 
     */
    protected get renderRows(): boolean { return true; }

    constructor(props: T) {
        super(props);
        // This should be a PureComponent, but it seems that MobX generates shouldComponentUpdate methods
        // for observables. For this reason, we HAVE TO inherit from React.Component and create
        // a dummy state.
        this.state = {};
    }

    /**
     * Keep the masterFilter of the connected ViewSource up to date.
     * The this.props.maserFilter property access is not tracked by MobX,
     * so whenever the masterFilter prop is changed, we have to manually
     * set the masterFilter of the ViewSource.
     */
    public componentDidUpdate(prevProps: IViewSourceRelatedProps) {
        if (prevProps.masterFilter !== this.props.masterFilter && this.props.masterFilter !== undefined) {
            //console.log("masterFilter changed!")
            runInAction(() => {this.viewSource.masterFilter = this.props.masterFilter!; })
            
        }
    }

    /** Get storage path for the connected ViewSource. It also determines the configuration key prefix. */
    protected get storagePath(): string {
        //return this.props.storagePath || ""+this.props.oid!;
        if (this.props.storagePath) {
            return this.props.storagePath!;
        } else {
            return ""+this.props.oid;
        }
    }

    protected get oid(): number {
        return this.props.oid;
    }

    protected get defaultViewSourceConfig(): Partial<IViewSourceConfiguration> | undefined {
        return this.props.defaultViewSourceConfig;
    }

    protected get masterFilter(): IViewFilter | undefined {
        return this.props.masterFilter;
    }

    /** Get the connected viewSource. */
    protected get viewSource(): ViewSource {
        return appStore.getViewSource(this.storagePath, this.oid, this.masterFilter, this.defaultViewSourceConfig);
    }

    /** Structure of the view. */
    protected get meta(): RelationMeta {
        return this.viewSource.meta;
    }

    // Notify the viewSource that there is somebody who wants to access the data.
    public componentDidMount() {
        if (this.renderRows) {
            // console.log(this.constructor.name, "incRefCount" );
            this.viewSource.incRefCount();
        }
    }

    // Notify the viewSource that we no longer need this data.
    public componentWillUnmount() {
        if (this.renderRows) {
            // console.log(this.constructor.name, "decRefCount" );
            this.viewSource.decRefCount();
        }
    }

}
