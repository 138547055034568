import { Button, Checkbox, Classes, Dialog, InputGroup, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { showSuccess } from '../../../dialog/Notification';
import { uiStore } from '../../../store/UIStore';
import { IViewSourceRelatedProps, ViewSourceRelated } from './ViewSourceRelated';
import { makeObservable, runInAction } from 'mobx';


@observer
export class SelectColumnsDialog extends ViewSourceRelated<IViewSourceRelatedProps> {

    constructor(props: IViewSourceRelatedProps) {
        super(props)
        makeObservable(this, {})
    }

    private get viewFrameConfig() {
        return uiStore.getViewFrameConfiguration(this.storagePath, this.oid);
    }

    private get colConfig() {
        return this.viewFrameConfig.columnConfiguration;
    }

    private onCloseDialog = (event?: React.SyntheticEvent<HTMLElement>) => {
        uiStore.dialogStack.popLastDialog();
        this.colConfig.load();
    }

    private onOkClicked = (event?: React.SyntheticEvent<HTMLElement>) => {
        uiStore.dialogStack.popLastDialog();
        this.colConfig.save().then(() => { showSuccess(null, "Column settings saved.") });
    }

    public static open = (oid: number, storagePath: string) => {
        return new Promise<boolean>(async (resolve, reject) => {
            const confirmDialog = <SelectColumnsDialog oid={oid} storagePath={storagePath} />;
            uiStore.dialogStack.addDialog(()=>confirmDialog);
        });
    }

    private createCheckbox = (columnName: string, index: number, visible: boolean) => {
        /*
        if (true) {
            throw new Error("SelectColumnsDialog Not ready yet")
        }
        */
        const vs = this.meta!;
        const columnStruct = vs.attrs[vs.attr_nti[columnName]];
        const input = <Checkbox
            checked={visible}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (visible) {
                    this.colConfig.hideVisibleColumn(index);
                } else {
                    this.colConfig.showInvisibleColumn(index);
                }
            }}
        />;


        /*
        const path: string[] = [];
        let fieldName = columnStruct.name;
        let tableAlias = columnStruct.table_alias;
        while (true) {
            let tablePath = vs.table_aliases[tableAlias];
            let ts = api.meta.get_table_struct(tablePath);
            if (!ts.fields[fieldName]) {
                console.log(toJS(columnStruct));
                console.log("fields keys", ts.fields, fieldName);
            }
            path.unshift(ts.fields[fieldName].displaylabel);
            path.unshift(ts.displaylabel);
            if (vs.join_tree[tableAlias]) {
                const ref = vs.join_tree[tableAlias];
                tableAlias = ref[0];
                fieldName = ref[1];
            } else {
                break;
            }
        }
        */

        return <Row key={vs.name}>
            <Col sm={1}>{input}</Col>
            <Col sm={3}>{columnStruct.displaylabel}</Col>
            <Col sm={4}><InputGroup
                value={this.colConfig.labels[columnName] || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    runInAction(() => { this.colConfig.labels[columnName] = event.target.value })
                }}
            /></Col>
            <Col sm={4}><small>??? {/*path.join(" > ")*/}</small></Col>
        </Row>;
        /*
        return <div>SelectColumnsDialog not ready yet!</div>
        */
    }

    private checkUncheckAll = () => {
        this.colConfig.checkUncheckAll();
    }

    private renderColumnSelector = () => {
        return <Container fluid>
            <Row key="header">
                <Col sm={1}>
                    <Button
                        icon={IconNames.SMALL_TICK}
                        intent={Intent.NONE}
                        small={true}
                        minimal={true}
                        onClick={this.checkUncheckAll}
                    ></Button></Col>
                <Col sm={3}><b>Column</b></Col>
                <Col sm={4}><b>Custom name</b></Col>
                <Col sm={4}><small><b>Path</b></small></Col>
            </Row>
            {this.colConfig.visible.map((columnName, index) => this.createCheckbox(columnName, index, true))}
            {this.colConfig.invisible.map((columnName, index) => this.createCheckbox(columnName, index, false))}
        </Container>;
    }

    public render() {
        return <Dialog
            style={{ width: '90%' }}
            isOpen={true}
            icon={IconNames.COLUMN_LAYOUT}
            title="Choose columns to show"
            onClose={this.onCloseDialog}
        >
            <div className={Classes.DIALOG_BODY}>
                {this.renderColumnSelector()}
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button icon={IconNames.CROSS} onClick={this.onCloseDialog}>Cancel</Button>
                        <Button
                            intent={Intent.PRIMARY}
                            icon={IconNames.CONFIRM}
                            onClick={this.onOkClicked}
                        >OK</Button>
                    </div>
                </div>
            </div>
        </Dialog>
    }
}
