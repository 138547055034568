import * as React from "react";
import { Intent, ButtonGroup, Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { observer } from "mobx-react";
import { IRecordSourceRelatedProps, RecordSourceRelated } from "./RecordSourceRelated";
import { IComponentProvider, COMPONENT_CATEGORY_ACTION } from "../layout/ComponentProvider";
import { makeObservable } from "mobx";


@observer
export class RecordActionButtons extends RecordSourceRelated implements IComponentProvider {
    public key = 'buttonbar';
    public category: string = COMPONENT_CATEGORY_ACTION;
    public displaylabel = 'Műveletek (gombsor)';
    public description = `A tételen elvégezhető
    műveletek (pl. mentés, törlés, visszaállítás stb.) elindítására
    szolgáló gombokat tartalmazza.`;

    constructor(props:IRecordSourceRelatedProps) {
        super(props)
        makeObservable(this, {})
    }

    public createElement() { return React.createElement(RecordActionButtons, this.props, null); }

    public render() {
        const buttons: any[] = [
            <Button
                intent={Intent.PRIMARY}
                icon={IconNames.FLOPPY_DISK}
                disabled={!this.recordSource.hasChange}
                onClick={this.saveChanges}
                key="button-save">Mentés</Button>,
            <Button intent={Intent.NONE} icon={IconNames.ADD}
                onClick={() => { this.recordSource.createNewRecord() }}
                disabled={!this.recordExists && !this.recordSource.hasChange}
                key="button-new">
                {this.recordExists ? "Új" : "Alaphelyzetbe"}
            </Button>
        ];
        if (this.hasIsActive) {
            // Logical deletions are supported.
            const canDelete = this.recordExists && this.isActive === true;
            const canUndelete = this.recordExists && this.isActive === false && !this.hasChange;
            if (canUndelete) {
                buttons.push(
                    <Button intent={Intent.WARNING}
                        icon={IconNames.UNDO}
                        onClick={this.undeleteRecord}
                        key="button-undelete"
                    >Aktivál</Button>);
            } else {
                buttons.push(
                    <Button intent={Intent.DANGER}
                        icon={IconNames.REMOVE}
                        onClick={this.deleteRecord}
                        disabled={!canDelete}
                        key="button-delete"
                    >Töröl</Button>);
            }
        } else {
            buttons.push(
                <Button intent={Intent.DANGER}
                    icon={IconNames.REMOVE}
                    onClick={this.deleteRecord}
                    disabled={!this.recordExists}
                    key="button-delete"
                >Töröl</Button>);
        }
        return <ButtonGroup>{buttons}</ButtonGroup>;
    }


}
