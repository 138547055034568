import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import * as React from "react";

import { Callout, Hotkey, Hotkeys, Icon, Intent, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Cell, CellProps, Column, ColumnHeaderCell, ColumnHeaderCellProps, Region, SelectionModes, Table2, TableLoadingOption } from "@blueprintjs/table";
import { makeObservable } from "mobx";
import { Api } from "../../../api/Api";
import { RelationMeta } from "../../../api/Meta";
import { IViewOrderBy } from "../../../api/View";
import { ConfirmDialog } from "../../../dialog/ConfirmDialog";
import { showSuccess } from "../../../dialog/Notification";
import { KEY_DOWN, KEY_UP, keyboard } from "../../../keyboard";
import { uiStore } from "../../../store/UIStore";
import { ViewFrameColumnConfiguration } from "../../../store/view/ViewFrameColumnConfiguration";
import { ViewFrameConfiguration } from "../../../store/view/ViewFrameConfiguration";
import { anyToText } from '../../../util';
import { SelectColumnsDialog } from "./SelectColumnsDialog";
import { IViewSourceRelatedProps, ViewSourceRelated } from "./ViewSourceRelated";


export interface IViewGridFrameProps extends IViewSourceRelatedProps {
    onItemSelected?: () => void;
    onEscPressed?: () => void;
}

// See https://github.com/palantir/blueprint/issues/3604
//@HotkeysTarget
@observer
export class ViewGridFrame extends ViewSourceRelated<IViewGridFrameProps> {
    private saveColConfig: any;
    private keyboardListening: boolean = false;

    constructor(props: IViewGridFrameProps) {
        super(props);
        this.saveColConfig = debounce(this.doSaveColConfig, 2000);
        this.configuration.firstLoad();
        makeObservable(this, {})
    }

    private doSaveColConfig = () => {
        this.colConfig.save().then(() => { showSuccess(null, "Columns settings saved.") })
    }

    private resetColConfig = () => {
        this.colConfig.reset().then(() => { showSuccess(null, "Default column settings restored.") })
    }

    /** Configuration of the ViewFrame */
    private get configuration(): ViewFrameConfiguration {
        return uiStore.getViewFrameConfiguration(this.storagePath, this.oid);
    }

    /** Column configuration of the ViewFrame */
    private get colConfig(): ViewFrameColumnConfiguration {
        return this.configuration.columnConfiguration;
    }

    /** Get name of a visible column by its index. */
    private getColumName = (columnIndex: number): string => {
        return this.colConfig.visible[columnIndex];
    }

    private captureKeys = () => {
        if (!this.keyboardListening) {
            keyboard.addListener("keydown", this.onKeyDown);
            this.keyboardListening = true;
        }
    }

    private unCaptureKeys = () => {
        if (this.keyboardListening) {
            keyboard.removeListener("keydown", this.onKeyDown);
            this.keyboardListening = false;
        }
    }

    public componentWillUnmount() {
        this.unCaptureKeys();
    }

    private onKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === KEY_UP) {
            this.viewSource.prev();
        } else if (event.keyCode === KEY_DOWN) {
            this.viewSource.next();
        } else if (event.key.toLowerCase() === "enter") {
            if (this.props.onItemSelected) {
                this.props.onItemSelected();
            }
        } else if (event.key.toLowerCase() === "escape") {
            if (this.props.onEscPressed) {
                this.props.onEscPressed();
            }
        }/* else {
            console.log(event);
            console.log(event.key);
            console.log(event.keyCode);
        }*/
    }

    public render() {
        if (!this.configuration.isLoaded) {
            return <Callout intent={Intent.WARNING}>Loading, please wait...</Callout>;
        }

        const rowIdx = this.viewSource.rowIndex || 0;
        return <div style={{ maxHeight: "50vh", overflow: "scroll" }}
            onFocus={this.captureKeys} onBlur={this.unCaptureKeys}
        >
            {/*enableFocusedCell={true}*/}
            <Table2
                key={"rows-" + this.viewSource.dataKey}
                numRows={this.viewSource.rowCount}
                columnWidths={this.colConfig.visibleWidths}
                enableColumnReordering={true}
                onColumnsReordered={this.onColumnsReordered}
                onColumnWidthChanged={this.onColumnWidthChanged}

                loadingOptions={this.viewSource.loading ? [TableLoadingOption.CELLS, TableLoadingOption.COLUMN_HEADERS] : []}

                enableMultipleSelection={false}
                selectionModes={SelectionModes.ROWS_AND_CELLS}
                onSelection={this.onRowSelection}
                selectedRegions={[{ cols: null, rows: [rowIdx, rowIdx] }]}
            >
                {this.colConfig.visible.map(columnName => this.createColumn(columnName))}
            </Table2></div>;
    }

    public renderHotkeys() {
        return <Hotkeys>
            <Hotkey
                combo="up"
                label="Prev row"
                onKeyDown={this.viewSource.prev}
            />
            <Hotkey
                combo="down"
                label="Next row"
                onKeyDown={this.viewSource.next}
            />
        </Hotkeys>;
    }

    protected columnMenuRenderer = (attr_idx: number, columnIndex?: number | undefined): JSX.Element => {
        const attr = this.meta.attrs[attr_idx]
        const isText = attr.type === "text"; // TODO: add other text types?
        const ascIcon = isText ? IconNames.SORT_ALPHABETICAL : IconNames.SORT_ASC;
        const descIcon = isText ? IconNames.SORT_ALPHABETICAL_DESC : IconNames.SORT_DESC;
        return <Menu>
            <MenuItem text="Sort asc" icon={ascIcon}
                onClick={() => this.setOrderBy({ column_name: attr.name, ascending: true })}
            />
            <MenuItem text="Sort desc" icon={descIcon}
                onClick={() => this.setOrderBy({ column_name: attr.name, ascending: false })}
            />
            <MenuDivider />
            {columnIndex !== undefined ?
                <MenuItem text="Hide" icon={IconNames.EYE_OFF}
                    onClick={() => { this.hideColumn(columnIndex) }}
                />
                : null}
            <MenuItem text="Columns..." icon={IconNames.COLUMN_LAYOUT}
                onClick={this.onSelectColumns}
            />
            <MenuItem text="Default columns" icon={IconNames.COLUMN_LAYOUT}
                onClick={this.onSetDefaultColumns}
            />
        </Menu>
    }

    private setOrderBy = (item: IViewOrderBy) => {
        this.viewSource.setOrderBy([item]);
        this.viewSource.saveConfig().then(() => { showSuccess(null, "Sort saved."); })
        // this.viewSource.reload(); In theory, this results in auto-reload?
    }

    public get meta(): RelationMeta {
        return Api.meta.get_relation_meta(this.oid)
    }

    protected columnHeaderRenderer = (columnIndex: number): React.ReactElement<ColumnHeaderCellProps> => {
        const vs = this.meta;
        const cn = this.getColumName(columnIndex);
        const aidx = vs.attr_nti[cn]
        const cs = vs.attrs[aidx];
        const cc = this.colConfig;
        const displaylabel = cc.labels[cn] || cs.displaylabel;

        /*
        This was taken from ruag-timer-frontend but it is obsolete.

        let displaylabel = this.colConfig.labels[cs.name]
        if (!displaylabel) {
            displaylabel = cs.displaylabel;
            if (cs.table_alias !== vs.main_alias) {
                if (!vs.join_tree[cs.table_alias]) {
                    displaylabel = cs.table_alias + "?????";
                    console.log(vs.join_tree, vs.main_alias, cs.table_alias, "???");
                } else {
                    let [ref_alias, ref_fname] = vs.join_tree[cs.table_alias]; // This table was joined from a master
                    let ref_table_path = vs.table_aliases[ref_alias]; // Path of the master
                    let ref_ts = api.meta.get_table_struct(ref_table_path); // Structure of the master
                    let ref_field = ref_ts.fields[ref_fname]; // Referencing field
                    if (cs.field_name === "fullname" || cs.field_name === "title") {
                        displaylabel = ref_field.displaylabel; // Use foreign key label instead.
                    } else {
                        displaylabel = ref_field.displaylabel + "." + displaylabel;
                    }
                }
            }
        }
        */
        const columnName = cs.name;
        const isText = cs.type === "text"; // TODO: add other text types?
        const ascIcon = isText ? IconNames.SORT_ALPHABETICAL : IconNames.SORT_ASC;
        const descIcon = isText ? IconNames.SORT_ALPHABETICAL_DESC : IconNames.SORT_DESC;
        let icon: string | null = null;
        this.viewSource.orderBy.forEach(item => {
            if (item.column_name === columnName) {
                icon = item.ascending ? ascIcon : descIcon;
            }
        })
        const style: React.CSSProperties = {};
        if (cs.type === "boolean") {
            style.textAlign = "center";
        }
        return <ColumnHeaderCell
            name={displaylabel}
            nameRenderer={(name, index) => {
                return <div style={style}>{icon ? <Icon icon={icon as any} /> : null}{name}</div>;
            }}
            menuRenderer={this.columnMenuRenderer.bind(this, aidx)}
        />;
    };

    protected cellRenderer = (rowIndex: number, columnIndex: number): React.ReactElement<CellProps> => {
        const vs = this.meta;
        const cn = this.getColumName(columnIndex)
        const cs = vs.attrs[vs.attr_nti[cn]];
        if (cs.type === "bool") {
            return this.cellBooleanRenderer(rowIndex, this.getColumName(columnIndex));
        } else {
            return this.cellTextRenderer(rowIndex, this.getColumName(columnIndex));
        }
    }

    onCellDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (this.props.onItemSelected) {
            this.props.onItemSelected();
        }
    }

    // TODO: create a global function that can convert a column's value into a string!
    protected cellTextRenderer = (rowIndex: number, columnName: string): React.ReactElement<CellProps> => {
        const row = this.viewSource.getRow(rowIndex);
        const value = row[columnName];
        let textValue;
        if (value) {
            textValue = anyToText(value);
        }
        // Indicate deleted lines
        const style: React.CSSProperties = {};
        if (row.is_active === false) {
            style.textDecoration = "line-through gray";
            style.color = "gray";
        }
        // For the extra fragment, see: https://github.com/palantir/blueprint/issues/2446#issuecomment-430789298
        return <Cell style={style}>
            <>
                <div onDoubleClick={this.onCellDoubleClick}>
                    {textValue}
                </div>
            </>
        </Cell>;
    };


    protected cellBooleanRenderer = (rowIndex: number, columnName: string): React.ReactElement<CellProps> => {
        const row = this.viewSource.getRow(rowIndex);
        const value = row[columnName];
        let textValue: string = "";
        if (value === true) {
            textValue = "✔"; // https://www.compart.com/en/unicode/U+2714
        } else if (value === false) {
            textValue = "✘"; // https://www.compart.com/en/unicode/U+2718
        }
        // TODO: use a CSS class instead!
        // For the extra fragment, see: https://github.com/palantir/blueprint/issues/2446#issuecomment-430789298
        return <Cell>
            <>
                <div style={{ textAlign: "center" }} onDoubleClick={this.onCellDoubleClick}>{textValue}</div>
            </>
        </Cell>;
    };

    protected createColumn = (columnName: string) => {
        const cs = this.meta.attrs[this.meta.attr_nti[columnName]]
        return <Column key={"column-" + cs.name}
            cellRenderer={this.cellRenderer}
            columnHeaderCellRenderer={this.columnHeaderRenderer}
        />;
    }

    private onColumnsReordered = (oldIndex: number, newIndex: number, length: number) => {
        this.colConfig.moveColumns(oldIndex, newIndex, length);
        this.saveColConfig();
    }

    private onColumnWidthChanged = (index: number, size: number) => {
        this.colConfig.resizeVisibleColumn(index, size);
        this.saveColConfig();
    }

    private hideColumn = (columnIndex: number) => {
        this.colConfig.hideVisibleColumn(columnIndex);
        this.saveColConfig();
    }

    private onSelectColumns = async () => {
        SelectColumnsDialog.open(this.oid, this.storagePath);
    }

    private onSetDefaultColumns = async () => {
        if (await ConfirmDialog.open(
            "Confirm",
            "This will reset all column settings to the defaults. " +
            "Are you sure?"
        )) {
            this.resetColConfig();
        }
    }

    protected onRowSelection = (selectedRegions: Region[]) => {
        if (selectedRegions.length) {
            this.onRowSelected(selectedRegions[0].rows![0]);
        } else {
            this.onRowSelected(null);
        }
    };

    private onRowSelected = async (rowIndex: number | null) => {
        this.viewSource.goTo(rowIndex);
    };

}
