import React from 'react';

interface MainPageProps {}

interface MainPageState {}

class MainPage extends React.Component<MainPageProps, MainPageState> {
    private cnt : number = 0;

    constructor(props: MainPageProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <img src="/logo.svg" alt="Imagella Admin Logo" title="Imagella Admin"/>;
    }
}

export default MainPage;
