import { AbcConverter, ICustomType } from "./types";
import { IDate, dateToAbc } from './date';
import { format, formatISO, parseISO } from "date-fns";
import { currentFnsLocale } from "./locales";


const TIMESTAMPTZ_LOCAL_FORMAT: {
    format?: 'extended' | 'basic'
    representation?: 'complete' | 'date' | 'time'
} = {
    format: "extended",
    representation: "complete"
}

const isValidDate = (dateObject: any) => new Date(dateObject).toString() !== 'Invalid Date';


export interface ITimestamp extends ICustomType {
    __type__: "timestamptz"; // Must match with the native PostgreSQL type!
    value: string;
}

class TimestampConverter extends AbcConverter<ITimestamp> {
    public getTypeCode(): string {
        return "timestamptz";
    }
    public stringToAbc(value: string | null): ITimestamp | null {
        return stringToTimestamp(value);
    }
    public abcToString(abcValue: ITimestamp | null): string {
        return timestampToString(abcValue);
    }
    public abcToStringHuman(abcValue: ITimestamp | null): string {
        return timestampToShortString(abcValue);
    }    
    public equals(timestamp1: ITimestamp | null, timestamp2: ITimestamp | null): boolean {
        return sameTimestamp(timestamp1, timestamp2);
    }
}

export const timestampConverter = new TimestampConverter();

export const timestampToString = (timestamp: ITimestamp | null, options?: {
    format?: 'extended' | 'basic'
    representation?: 'complete' | 'date' | 'time'
  }): string => {
    if (timestamp) {
        if (options===undefined) {
            options = TIMESTAMPTZ_LOCAL_FORMAT
        }
        return formatISO(parseISO(timestamp.value, { additionalDigits: 1 }), options);
    } else {
        return "";
    }
};

export const timestampToShortString = (timestamp: ITimestamp | null): string => {
    if (!timestamp) { return "" }
    return format(parseISO(timestamp.value, { additionalDigits: 1 }), "P p", {locale: currentFnsLocale() })
}


const stringToTimestamp = (value: string | null): ITimestamp | null => {
    if (!value || !value.trim()) {
        return null;
    } else {
        // ISO 8601, no fractional second see https://momentjs.com/docs/#/displaying/format/
        const newValue = parseISO(value, { additionalDigits: 1 });
        if (isValidDate(newValue)) {
            return { __type__: "timestamptz", value: formatISO(newValue, TIMESTAMPTZ_LOCAL_FORMAT) };
        } else {
            return null;
        }

    }
};

/**
 * Returns if the given (possibly null) timestamps are equal.
 *
 * Note: when passing two null values, it returns true.
 *
 */
const sameTimestamp = (timestamp1: ITimestamp | null, timestamp2: ITimestamp | null): boolean => {
    if ((timestamp1 === null) !== (timestamp2 === null)) {
        return false;
    } else if (!timestamp1 || !timestamp2) {
        return true;
    } else {
        // Compare ISO 8601 representation after parsing and reformatting.
        return formatISO(parseISO(timestamp1.value), TIMESTAMPTZ_LOCAL_FORMAT) ===
            formatISO(parseISO(timestamp2.value), TIMESTAMPTZ_LOCAL_FORMAT);
    }
};

/** Convert a native Date object into an ITimestamp */
export const timestampToAbc = (date: Date | null): ITimestamp | null => {
    if (date === null) {
        return null;
    } else {
        return { __type__: "timestamptz", value: formatISO(date, TIMESTAMPTZ_LOCAL_FORMAT) }
    }
}

/** Convert an ITimestamp into native Date */
export const abcToTimestamp = (value: ITimestamp | null): Date | null => {
    if (value === null) {
        return null;
    } else {
        return parseISO(value.value);
    }
}

/** Truncate time part. */
export const timestampDatePart = (value: ITimestamp | Date | null): IDate | null => {
    if (value === null) {
        return null;
    }
    let nativeTs;
    if (value instanceof Date) {
        nativeTs = value;
    } else {
        nativeTs = abcToTimestamp(value)!;
    }
    // First we convert to a native date object, so that the date part is correct in our local time zone.
    // Truncate the date part.
    return dateToAbc(nativeTs);
}

/** Format ITimestamp into local human-readable format, always returns a string. */
export const abcToLocalTimestampString = (value: ITimestamp | null): string => {
    if (value === null) {
        return ""
    }
    const d = abcToTimestamp(value)
    if (d===null) {
        return ""
    }
    // long localized date and time, see https://date-fns.org/docs/format
    return format(d, "PPppp", { locale: currentFnsLocale() })
}