import * as React from 'react';
import { IRecordSourceRelatedProps } from './RecordSourceRelated';
import { appStore } from '../../../store/AppStore';
import RecordSource from '../../../store/record/RecordSource';
import { Intent, Checkbox, InputGroup, FormGroup } from '@blueprintjs/core';
import { isAbcConverterRegistered } from '../../../data/abc/registertypes';
import { AbcInput } from '../../inputs/AbcInput';
import { IconNames } from '@blueprintjs/icons';
import { computed, action, makeObservable } from 'mobx';
import { IComponentProvider, COMPONENT_CATEGORY_DATA_FIELD } from '../layout/ComponentProvider';
import { observer } from 'mobx-react';
import { AsyncViewSelect } from '../../inputs/AsyncViewSelect';
import { IViewFilter, ViewFilterExprParams } from '../../../api/View';
import { AttributeMeta, RelationMeta } from '../../../api/Meta';
import { RecordId } from '../../../api/Record';

export interface IFieldInputRendererProps extends IRecordSourceRelatedProps {
    fieldName: string;
    key: string;
    disabled ?: boolean;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export abstract class CustomFieldInputRenderer<T extends IFieldInputRendererProps> extends React.Component<T, {}> implements IComponentProvider {
    /** Get storage path for the connected recordSource. It also determines the configuration key prefix. */
    public get storagePath(): string {
        return this.props.storagePath! || ""+this.props.oid;
    }

    protected get recordSource(): RecordSource {
        return appStore.getRecordSource(this.storagePath, this.props.oid);
    }

    protected get tableMeta(): RelationMeta {
        return this.recordSource.meta;
    }

    protected get attrMeta(): AttributeMeta {
        const m = this.recordSource.meta
        return m.attrs[m.attr_nti[this.props.fieldName]];
    }

    protected get fieldValue() :any {
        return this.recordSource.getFieldValue(this.props.fieldName);
    }

    @action.bound setFieldValue(value: any) {
        return this.recordSource.setFieldValue(this.props.fieldName, value);
    }

    protected get isFieldChanged() {
        return this.recordSource.isFieldChanged(this.props.fieldName);
    }

    protected get loading() {
        return this.recordSource.loading;
    }

    protected get intent() {
        let intent: Intent = Intent.NONE;
        const fs = this.attrMeta;
        const value = this.fieldValue;
        const isEmpty = value === null || (value === undefined || (fs.type === "text" && value === ""));
        if (fs.not_null) {
            // flags.push("kötelező");
            if (isEmpty) {
                intent = Intent.DANGER;
            }
        /* TODO! How to create desired fields ?
        } else if (fs.reqlevel === "desired") {
            // flags.push("javasolt");
            if (isEmpty) {
                intent = Intent.WARNING;
            }
        */
        }
        return intent;        
    }

    /** These are needed to implement IComponentProvider. */
    @computed get key(): string { return this.attrMeta.name; }
    public readonly category: string = COMPONENT_CATEGORY_DATA_FIELD;
    @computed get displaylabel(): string { return this.attrMeta.displaylabel; }
    @computed get description(): string | undefined { return this.attrMeta.description; }
    abstract createElement():React.ReactElement;
}

export type CustomFieldInputRendererFactory = (props: IFieldInputRendererProps) => IComponentProvider;

const customFieldInputRenderers = new Map<string,CustomFieldInputRendererFactory>();

export const registerCustomFieldInputRenderer = (tablePath: string, fieldName: string, factory: CustomFieldInputRendererFactory) => {
    const key = tablePath + "." + fieldName;
    /* We cannot do this here, registerCustomFieldInputRenderer is called before metadata is loaded.
    const tableStruct = api.meta.get_table_struct(tablePath);
    if (!tableStruct.field_names.includes(fieldName)) {
        throw new Error(`registerCustomFieldInputRenderer: no such field: ${key}.`)
    }
    */
    customFieldInputRenderers.set(key, factory);
}


/**
 * The default FieldInputRenderer can render most field types by default, and it also can
 * use the registered custom renderers.
 */
@observer
export class FieldInputRenderer extends CustomFieldInputRenderer<IFieldInputRendererProps> {
    constructor(props:IFieldInputRendererProps) {
        super(props)
        makeObservable(this)
    }

    public createElement() { return React.createElement(FieldInputRenderer, this.props, null); }

    public render() {
        const fieldName = this.props.fieldName;
        const ts = this.tableMeta;
        const fs = this.attrMeta;
        const disabled : boolean = this.loading || this.props.disabled===true || fs.readonly===true || (this.recordSource.exists && fs.immutable===true);

        const regKey = "" + ts.oid + "." + fieldName;
        if (customFieldInputRenderers.has(regKey)) {
            // Pass pre-calculated "disabled" so that it does not need to be recalculated in every customFieldInputRenderers
            return customFieldInputRenderers.get(regKey)!({...this.props, disabled:disabled}).createElement();
        }

        const value = this.fieldValue;
        const inputId = "input-" + fieldName;
        const flags = [];
        if (this.isFieldChanged) {
            flags.push("változott");
        }
        const labelInfo = flags.length ? "(" + flags.join(", ") + ")" : "";        
        let input;
        if (isAbcConverterRegistered(fs.type)) {
            input = (
                <AbcInput
                    typeCode={fs.type}
                    abcValue={value}
                    onAbcValueChange={this.abcInputValueChange}
                    onKeyPress={this.onKeyPress}
                    intent={this.intent}
                    disabled={disabled}
                />
            );
        } else if (fs.references) {
            // TODO: showFilterFrame ?: boolean;   prop ???
            // TODO: Same storage path for multiple lookups for the same table???
            const storagePath = fs.references + ".lookup"; /*this.props.storagePath!+"."+fs.field_name*/
            let masterFilter: IViewFilter|undefined = undefined;
            if (fs.lookupfilter) {
                const masterTs = this.recordSource.meta;
                const params : ViewFilterExprParams = {};
                const regex = /%\(([^)]+)\)s/gm;
                let m = regex.exec(fs.lookupfilter);
                while (m !== null) {
                    // This is necessary to avoid infinite loops with zero-width matches
                    if (m.index === regex.lastIndex) {
                        regex.lastIndex++;
                    }                    
                    const masterFieldName = m[1];
                    const masterFieldStruct = masterTs.attrs[masterTs.attr_nti[masterFieldName]]
                    if (masterFieldStruct) {
                        const masterFieldValue = this.recordSource.getFieldValue(masterFieldName);                        
                        params[masterFieldName] = masterFieldValue;
                    }
                    // The result can be accessed through the `m`-variable.
                    m = regex.exec(fs.lookupfilter);
                }
                masterFilter = { expr: fs.lookupfilter, params: params, equals: {} };
            }
            input = (                
                <AsyncViewSelect
                    oid={fs.lookupView}
                    storagePath={storagePath}
                    value={value}
                    onChange={this.onAsyncSelectChange}
                    intent={this.intent}
                    disabled={disabled}
                    masterFilter={masterFilter}
                />
            );
        } else if (fs.type === "boolean") {
            let props = {};
            if (value === null) {
                props = { checked: false, indeterminate: true };
            } else {
                props = { checked: !!value, indeterminate: false };
            }
            input = (
                <Checkbox
                    {...props}
                    disabled={disabled}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        let boolValue: boolean | null;
                        if (fs.not_null) {
                            boolValue = value ? false : true;
                        } else {
                            // Three state, cycle through all 3 values.
                            if (value === null) {
                                boolValue = true;
                            } else if (value === true) {
                                boolValue = false;
                            } else {
                                boolValue = null;
                            }
                        }
                        this.setFieldValue(boolValue);
                    }}
                />
            );
        } else {
            input = (
                <InputGroup
                    leftIcon={IconNames.EDIT}
                    large={true}
                    type="text"
                    value={value || ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        this.setFieldValue(event.target.value || null);
                    }}
                    onKeyPress={this.onKeyPress}
                    id={inputId}
                    intent={this.intent}
                    disabled={disabled}
                />
            );
        }
        return (
            <FormGroup label={fs.displaylabel} labelFor={inputId} labelInfo={labelInfo} helperText={fs.hint} key={this.key}>
                {input}
            </FormGroup>
        );
    }


    private abcInputValueChange = (value: any, event?: React.KeyboardEvent<HTMLInputElement>) => {
        this.recordSource.setFieldValue(this.props.fieldName, value);
    };

    private onAsyncSelectChange = (value: RecordId | null) => {
        this.recordSource.setFieldValue(this.props.fieldName, value);
    };


    private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.props.onKeyPress) {
            this.props.onKeyPress(event);
        }
    };

}