import { HTMLInputProps, InputGroupProps, InputGroup } from "@blueprintjs/core";
import * as React from "react";
import { getAbcConverter } from "../../data/abc/registertypes";
import { AbcConverter, ICustomType } from "../../data/abc/types";

type IInputGroupPropsBase = InputGroupProps & HTMLInputProps;

// This helps auto completion, you can add more here
export type AbcTypeCode = "timestamptz" | "interval" | "date" 

export interface IAbcInputProps<T extends ICustomType> extends IInputGroupPropsBase {
    typeCode: AbcTypeCode | string;
    abcValue: T | null;
    onAbcValueChange ?: (value: T | null, event?: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface IAbcInputState<T extends ICustomType> {
    value: string;
    inputFocused: boolean;
    editedValue: T | null;
}

export class AbcInput<T extends ICustomType> extends React.Component<IAbcInputProps<T>, IAbcInputState<T>> {
    private getConverter = (): AbcConverter<T> => {
        return getAbcConverter(this.props.typeCode) as AbcConverter<T>;
    }

    private abcToString = (abcValue: T | null): string => {
        return this.getConverter().abcToString(abcValue)
    }

    private stringToAbc = (value: string): T | null => {
        return this.getConverter().stringToAbc(value);
    }

    private abcEquals = (value1: T | null, value2: T | null): boolean => {
        return this.getConverter().equals(value1, value2);
    }

    constructor(props: IAbcInputProps<T>) {
        super(props);        
        this.state = {
            editedValue: this.props.abcValue,
            inputFocused: false,
            value: this.abcToString(this.props.abcValue),
        };
    }

    public componentDidUpdate(prevProps: IAbcInputProps<T>, prevState: IAbcInputState<T>) {
        const prev = prevProps.abcValue;
        const curr = this.props.abcValue;
        if (!this.abcEquals(prev, curr)) {
            this.setState({ value: this.abcToString(this.props.abcValue) });
        }
    }

    public render() {
        const props: any = { ...this.props };
        ["abcValue", "onAbcValueChange", "onFocus", "onBlur", "value", "onChange", "onKeyPress", "typeCode"].forEach(
            fname => {
                delete props[fname];
            },
        );
        return (
            <InputGroup
                {...props}
                onFocus={this.setInputFocused}
                onBlur={this.clearInputFocused}
                value={this.state.value}
                onChange={this.onChange}
                onKeyPress={this.onKeyPress}
            />
        );
    }

    private setInputFocused = () => this.setState({ inputFocused: true, editedValue: this.stringToAbc(this.state.value) });
    private clearInputFocused = () => this.setState({ inputFocused: false }, this.validate);

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        const editedValue = this.stringToAbc(value);
        this.setState({ value, editedValue });
    };

    /* Parse and validate the current value and update the abc value to either null or the validated value. */
    private validate = (event?: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.props.onAbcValueChange) {
            if (!this.abcEquals(this.state.editedValue, this.props.abcValue)) {            
                this.props.onAbcValueChange(this.state.editedValue, event);
            }
        }
    };

    private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key.toLowerCase() === "enter") {
            this.validate(event);
        }
        if (this.props.onKeyPress) {
            this.props.onKeyPress(event);
        }
    };
}
