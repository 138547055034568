import { IComponentProvider } from "./ComponentProvider";

/** 
 * This can provide a set of components. 
 * 
 * Please note that Map<string, IComponentProvider> perfectly implements IComponentCollection.
 * 
 */
export interface IComponentCollection {
    get(key: string): IComponentProvider | undefined;
    has(key: string): boolean;
    readonly size: number;
    forEach(callbackfn: (value: IComponentProvider, key: string) => void, thisArg?: any): void;
}

/**
 * 
 * Chained collections. 
 * 
 * With this class, you can chain multiple component collections into one.
 * Contained collections CAN be mutable. Keys should be unique, but it is not checked.
 * 
 * 
 */
export class ChainedComponentCollection implements IComponentCollection {
    private collections: IComponentCollection[] = [];

    public addCollection(collection: IComponentCollection) {
        this.collections.push(collection);
    }

    public forEach(callbackfn: (value: IComponentProvider, key: string) => void, thisArg?: any): void {
        this.collections.forEach(collection => collection.forEach(callbackfn));
    }

    public get(key: string): IComponentProvider | undefined {
        for (let i = 0; i < this.collections.length; i++) {
            if (this.collections[i].has(key)) {
                return this.collections[i].get(key);
            }
        }
        return undefined;
    }

    public has(key: string): boolean {
        for (let i = 0; i < this.collections.length; i++) {
            if (this.collections[i].has(key)) {
                return true;
            }
        }
        return false;
    }

    public get size(): number {
        let result = 0;
        this.collections.forEach(collection => result += collection.size);
        return result;
    }

}
