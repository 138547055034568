import * as React from 'react';

export const COMPONENT_CATEGORY_DATA_FIELD = 'data_field';
export const COMPONENT_CATEGORY_ACTION = 'action';
export const COMPONENT_CATEGORY_DETAIL_DATA = 'detail_data';

export const COMPONENT_CATEGORIES = {
	COMPONENT_CATEGORY_DATAFIELD: 'Adatmező',
	COMPONENT_CATEGORY_ACTION: 'Művelet',
	COMPONENT_CATEGORY_DETAIL_DATA: 'Részlet adat',
}

/** This can provide a single component that has a key and a displaylabel. */
export interface IComponentProvider {
	key : string;
	category : string;
	displaylabel : string;
	description ?: string;
	createElement(): React.ReactElement;
}

