import { format, formatISO, parseISO } from "date-fns";
import { AbcConverter, ICustomType } from "./types";
import { currentFnsLocale } from "./locales";

const DATE_FORMAT_OPTS: {
    format?: 'extended' | 'basic'
    representation?: 'complete' | 'date' | 'time'
} = {
    format: "extended",
    representation: "date"
}

const isValidDate = (dateObject: any) => new Date(dateObject).toString() !== 'Invalid Date';

export interface IDate extends ICustomType {
    __type__: "date"; // Must match with the native PostgreSQL type!
    value: string;
}

class DateConverter extends AbcConverter<IDate> {
    public getTypeCode(): string {
        return "date";
    }
    public stringToAbc(value: string | null): IDate | null {
        return stringToDate(value);
    }
    public abcToString(abcValue: IDate | null): string {
        return dateToString(abcValue);
    }
    public abcToStringHuman(abcValue: IDate | null): string {
        return dateToStringHuman(abcValue);
    }      
    public equals(date1: IDate | null, date2: IDate | null): boolean {
        return sameDate(date1, date2);
    }
}

export const dateConverter = new DateConverter();

export const dateToAbc = (date: Date | null): IDate | null => {
    if (date === null) {
        return null;
    } else {
        return { __type__: "date", value: formatISO(date, DATE_FORMAT_OPTS) }
    }
}

export const abcToDate = (value: IDate | null): Date | null => {
    if (value === null) {
        return null;
    } else {
        return parseISO(value.value);
    }
}

const dateToString = (date: IDate | null): string => {
    if (date) {
        return formatISO(parseISO(date.value), DATE_FORMAT_OPTS);
    } else {
        return "";
    }
};

const stringToDate = (value: string | null): IDate | null => {
    if (!value || !value.trim()) {
        return null;
    } else {
        // ISO 8601, no fractional second
        const newValue = parseISO(value, { additionalDigits: 0 });
        if (isValidDate(newValue)) {
            return { __type__: "date", value: formatISO(newValue, DATE_FORMAT_OPTS) };
        } else {
            return null;
        }

    }
};

/**
 * Returns if the given (possibly null) timestamps are equal.
 *
 * Note: when passing two null values, it returns true.
 *
 */
const sameDate = (date1: IDate | null, date2: IDate | null): boolean => {
    if ((date1 === null) !== (date2 === null)) {
        return false;
    } else if (!date1 || !date2) {
        return true;
    } else {
        // Compare ISO 8601 representation after parsing and reformatting.
        return formatISO(parseISO(date1.value), DATE_FORMAT_OPTS) ===
            formatISO(parseISO(date2.value), DATE_FORMAT_OPTS);
    }
};


/** Format IDate into local human-readable format, always returns a string. */
export const abcToLocalDateString = (value: IDate | null): string => {
    if (value === null) {
        return ""
    }
    const d = abcToDate(value)
    if (d===null) {
        return ""
    }
    // long localized date, see https://date-fns.org/docs/format
    return format(d, "PP", { locale: currentFnsLocale() })
}

const dateToStringHuman = (date: IDate | null): string => {
    if (date) {
        return parseISO(date.value).toDateString();
    } else {
        return "";
    }
};
