import prettyBytes from "pretty-bytes";
import { currentIsoCode } from "./data/abc/locales";
import { getAbcConverter } from "./data/abc/registertypes";

/* Create a new type by substracting a field from an existing type. */
export type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

/* Current time in ticks (whole seconds) */
export const now = (): number => {
    return (new Date()).getTime() / 1000;
}

export const isValidEmail = (email: string) : boolean => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const formatSize = (size:number|null|undefined) => {
    if (typeof size === "number") {
        return prettyBytes(size, { locale: currentIsoCode()})
    } else {
        return ""
    }
}

export const anyToText = (value: any) => {
    if (value===null || value===undefined) { return "" }
    if (typeof value === "string") { return value }
    if (typeof value === "number") { return "" + value }
    if (typeof value === "object") {
        if (value && value.__type__) {
            const converter = getAbcConverter(value.__type__)
            return converter.abcToStringHuman(value);
        }
        const ic = currentIsoCode()
        let ret = value[ic]
        if (ret) { return ret }
        if (ic!=="en") {
            ret = value["en"]
        }
        if (ret) { return ret }
        return value.toString()
    }
    return "???"
}