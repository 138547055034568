import * as React from "react";

import { uiStore } from '../store/UIStore';

import { Button, Classes, Intent, Dialog } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { IComponentCollection } from "../components/frames/layout/ComponentCollection";
import { DynamicLayoutEditor } from "../components/frames/layout/DynamicLayoutEditor";
import { DynamicLayoutConfiguration } from "../store/layout/DynamicLayoutConfiguration";

interface IDynamicLayoutEditorDialogProps {
    layoutConfiguration :DynamicLayoutConfiguration;
    components : IComponentCollection;
    onResolve : (value:boolean) => void;
}

export class DynamicLayoutEditorDialog extends React.PureComponent<IDynamicLayoutEditorDialogProps> {

    public static open = async (layoutConfiguration: DynamicLayoutConfiguration,
        components:IComponentCollection): Promise<boolean> => {
        return new Promise<boolean>( async (resolve, reject) => {
            const dynamicLayoutEditorDialog = <DynamicLayoutEditorDialog
                layoutConfiguration={layoutConfiguration}
                components={components}
                onResolve={resolve}/>;
            uiStore.dialogStack.addDialog(()=>dynamicLayoutEditorDialog);
        });
    }

    public render() {
        return <Dialog
                    isOpen={true}
                    icon={IconNames.CONTROL}
                    title="Elrendezés szerkesztése"
                    onClose={this.onCloseDialog}
                    style={{width:"90%"}}
            >
                <div className={Classes.DIALOG_BODY}>
                    <DynamicLayoutEditor
                        layoutConfiguration={this.props.layoutConfiguration}
                        components={this.props.components}
                    />
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button icon={IconNames.CROSS} onClick={this.onCloseDialog}>
                                Mégse
                            </Button>
                            <Button intent={Intent.PRIMARY} icon={IconNames.CONFIRM} onClick={this.onOkClicked}>
                                OK
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
    }

    private onCloseDialog = (event?: React.SyntheticEvent<HTMLElement>) => {
        uiStore.dialogStack.popLastDialog();
        this.props.onResolve(false);
    }

    private onOkClicked = (event?: React.SyntheticEvent<HTMLElement>) => {
        uiStore.dialogStack.popLastDialog();
        this.props.onResolve(true);
    }
}
