import * as React from "react";

import { Button, Callout, ControlGroup, FormGroup, InputGroup, Intent, Radio, RadioGroup, Spinner, Tag } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';
import { UUID } from "crypto";
import { IObservableArray, action, makeObservable, observable, reaction, runInAction, toJS } from "mobx";
import { observer } from 'mobx-react';

import { dispatcher } from "../../App";
import { showError, showSuccess } from "../../dialog/Notification";

import { Cell, Column, Table2 } from "@blueprintjs/table";
import _ from "lodash";
import { Col, Container, Row } from "react-grid-system";
import { RecordId } from "../../api/Record";
import { AbcInput } from "../../components/inputs/AbcInput";
import { HStore } from "../../data/abc/record";
import { ITimestamp, abcToLocalTimestampString } from "../../data/abc/timestamptz";
import { Link } from "react-router-dom";
import { ROUTE_PRODUCT_DETAILS } from "../../navigation/Routes";


// TODO!!!
const LANGUAGE_CODE = "en"

const loading = observable.box<boolean>(false)
const setLoading = (value: boolean) => { runInAction(() => loading.set(value)) }

type HasImage = "" | "1" | "0"

class Filters {
    // Tells if the product list is currently loading.
    @observable public searchPhrase = ""
    @observable public productId = ""
    @observable public categoryId: RecordId | null = null
    @observable public hasImage: HasImage = ""

    @observable public minCreated: ITimestamp | null = null
    @observable public maxCreated: ITimestamp | null = null
    @observable public minModified: ITimestamp | null = null
    @observable public maxModified: ITimestamp | null = null

    @action.bound public setSearchPhrase(value: string) { this.searchPhrase = value }
    @action.bound public setSearchProductId(value: string) { this.productId = value }
    @action.bound public setCategoryId(value: RecordId | null) { this.categoryId = value }
    @action.bound public setHasImage(value: HasImage) { this.hasImage = value }
    public getHasImage() {
        switch (this.hasImage) {
            case "1":
                return true
            case "0":
                return false
            default:
                return null
        }
    }

    @action.bound public setMinCreated(value: ITimestamp | null) { this.minCreated = value }
    @action.bound public setMaxCreated(value: ITimestamp | null) { this.maxCreated = value }
    @action.bound public setMinModified(value: ITimestamp | null) { this.minModified = value }
    @action.bound public setMaxModified(value: ITimestamp | null) { this.maxModified = value }


    constructor() { makeObservable(this) }
}
const filters = new Filters()


type ProductRow = {
    id: UUID // product.id
    c_tim: ITimestamp
    m_tim: ITimestamp | null
    keyphrase_id: UUID
    keyphrase: HStore
    keyphrase_stem: HStore
    main_category_id: UUID
    category_title: string
    product_status_id: UUID
    product_status: string
    title: string | null
    seo_title: string | null
    description: string | null
    seo_description: string | null
    thumb_256_id: UUID | null
    mj_orig_id: UUID | null
    headline: string | null
    ts_rank: number
}

export type CodeTitleRecord = {
    id: UUID;
    code: string;
    title: HStore;
    description: HStore;
}

export type CodeTitleDescRecord = CodeTitleRecord & {
    description: HStore;
}

type ProductSearchResult = {
    products: ProductRow[]
    ts_query: string
    warnings: string[]

    product_statuses: { [product_status_id: string]: CodeTitleDescRecord }
    upload_statuses: { [upload_status_id: string]: CodeTitleDescRecord }
    enable_statuses: { [enable_status_id: string]: CodeTitleDescRecord }
    categories: { [category_id: string]: CodeTitleRecord }
}

const EMPTY_SEARCH_RESULTS: ProductSearchResult = {
    products: [], ts_query: "", warnings: [],
    product_statuses: {}, enable_statuses: {}, upload_statuses: {}, categories: {}
}

const search = observable.box<ProductSearchResult>(EMPTY_SEARCH_RESULTS);
const rowHeights: IObservableArray<number> = observable([]);


const reloadSearch = async () => {
    try {
        setLoading(true)
        const sp = filters.searchPhrase.trim();
        if (sp || filters.productId) {
            const params: any = {
                lang_id: '9cfefed8-fb94-97ba-a5cd-519d7d2bb5d7', // TODO
                product_id: filters.productId,
                search_phrase: sp,
                main_category_id: filters.categoryId,
                min_created: filters.minCreated,
                max_created: filters.maxCreated,
                min_modified: filters.minModified,
                max_modified: filters.maxModified,
                has_image: filters.getHasImage(),
            }
            const data = await dispatcher.call<ProductSearchResult>("product.search", params)
            runInAction(() => {
                search.set(data);
                let rh = Array.from({ length: data.products.length }, (x, i) => 256);
                rowHeights.replace(rh)
                setLoading(false)
            })
        } else {
            runInAction(() => {
                search.set(EMPTY_SEARCH_RESULTS);
                rowHeights.replace([])
                setLoading(false)
            })
        }
    } catch (error) {
        showError(error)
    }
}
const debouncedReloadSearch = _.debounce(reloadSearch, 1000, { maxWait: 100000 })

reaction(
    () => toJS(filters),
    () => {
        setLoading(true)
        // Note: mobx reaction throttle is not the same as a debounce
        debouncedReloadSearch()
    }
)

const imageCellRenderer = (rowIndex: number) => {
    const p = search.get().products[rowIndex];
    return <Cell>{
        p.thumb_256_id ?
            <a href={`/media/file/${p.mj_orig_id}`} target="_blank" rel="noreferrer"><img
                src={`/media/file/${p.thumb_256_id}`}
                alt=""
            /></a> : null}
    </Cell>
}

const copyHeadline = (event: React.MouseEvent<HTMLElement>) => {
    const rowIndex = parseInt(event.currentTarget.getAttribute("data-row-index")!);
    const p = search.get().products[rowIndex];
    const headline = p.headline!.replaceAll("<b>"," ").replaceAll("</b>", " ").replaceAll("  "," ").trim()
    navigator.clipboard.writeText(headline);
    showSuccess(headline, "Copied");
}

const titleCellRenderer = (rowIndex: number) => {
    const p = search.get().products[rowIndex];
    return <Cell wrapText={true}>
        <b>Title</b>: {p.title}<br />
        <b>Rank:</b> {p.ts_rank}<br />
        <Tag round={true} icon={IconNames.TAG} title="Product status">{p.product_status}</Tag>
        <br />
        {p.headline ?
            <>
                <div className="bp5-text-muted bp5-text-small" dangerouslySetInnerHTML={{ __html: p.headline }} />
                <Button icon={IconNames.CLIPBOARD} 
                    data-row-index={rowIndex}
                    onClick={copyHeadline}
                    title="Copy to clipboard"
                />
            </>
            : null}
    </Cell>
}

const keyphraseCellRenderer = (rowIndex: number) => {
    const p = search.get().products[rowIndex];
    return <Cell>
        <b>Id:</b> <Link to={ROUTE_PRODUCT_DETAILS + "/" + p.id}>{p.id}</Link><br />
        <b>Keyphrase</b>: {p.keyphrase && p.keyphrase["en"]}<br />
        <b>Stem:</b>:  {p.keyphrase_stem && p.keyphrase_stem["en"]}<br />
        <b>Main category:</b>:  {p.category_title}<br />
    </Cell>
}

const datesRenderer = (rowIndex: number) => {
    const p = search.get().products[rowIndex];
    return <Cell>
        <b>Prod. created:</b>:  {abcToLocalTimestampString(p.c_tim)}<br />
        <b>Prod. modified:</b>:  {abcToLocalTimestampString(p.m_tim)}<br />
    </Cell>
}

@observer
export default class ProductSearchPage extends React.PureComponent<{}> {

    constructor(props: {}) {
        super(props)
        makeObservable(this, {})
    }

    componentDidMount(): void { reloadSearch() }

    onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        filters.setSearchPhrase(event.target.value)
    }

    onProductIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        filters.setSearchProductId(event.target.value)
    }


    getResults = () => {
        const ps = search.get()!.products;
        return <Table2 numRows={ps.length} rowHeights={rowHeights}
            columnWidths={[
                256, // image
                500, // title and statuses
                300, // Id, Keyphrase and category
                300, // Dates
            ]}
        >
            <Column name="Image" cellRenderer={imageCellRenderer} />
            <Column name="Title, Rank, Statuses" cellRenderer={titleCellRenderer} />
            <Column name="Id, Keyphrase, Category" cellRenderer={keyphraseCellRenderer} />
            <Column name="Dates" cellRenderer={datesRenderer} />
        </Table2>
    }

    onFilterCategorySelected = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value || null
        filters.setCategoryId(value as RecordId)
    }

    onHasImageSelected = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value
        filters.setHasImage(value as HasImage)
    }

    renderCategoryRadio = () => {
        const cats = search.get().categories;
        const filterId = filters.categoryId
        const filterValue = filterId || "";
        return <RadioGroup
            label="Category"
            onChange={this.onFilterCategorySelected}
            selectedValue={filterValue}
            inline={true}
        >
            <Radio label="<Any category>" value={""} key={"_any_"} />
            {Object.keys(cats).map((value, index) => {
                const cat = cats[value];
                return <Radio label={cat.title[LANGUAGE_CODE]} value={cat.id} key={cat.code} />
            })}
        </RadioGroup>
    }

    render(): React.ReactNode {
        const res = search.get()
        let helperText = res.ts_query
        if (!helperText && !filters.productId) {
            helperText = "Enter your search phrase or a valid product id";
        }
        return <Container fluid>
            <Row>
                <Col lg={3}>
                    <FormGroup
                        helperText={helperText}
                        intent={Intent.PRIMARY}
                        labelFor="search-terms"
                        label="Search phrase"
                    >
                        <InputGroup
                            id="search-terms" placeholder="Type in some words to search for prices..." intent={Intent.PRIMARY}
                            value={filters.searchPhrase}
                            onChange={this.onSearchTermChange}
                        />
                    </FormGroup>
                </Col>
                <Col lg={2}>
                    <FormGroup
                        helperText={"Should be an UUID"}
                        intent={Intent.PRIMARY}
                        labelFor="product-id"
                        label="Product id"
                    >
                        <InputGroup
                            id="product-id" placeholder="Type in product UUID" intent={Intent.PRIMARY}
                            value={filters.productId}
                            onChange={this.onProductIdChange}
                        />
                    </FormGroup>
                </Col>
                <Col lg={2}>
                    <FormGroup
                        label="Price min/max created"
                        labelFor="min-max-created"
                    >
                        <ControlGroup vertical={true} id="min-max-created">
                            <AbcInput
                                id="min-created"
                                typeCode="timestamptz"
                                abcValue={filters.minCreated}
                                onAbcValueChange={filters.setMinCreated}
                            />
                            <AbcInput
                                id="max-created"
                                typeCode="timestamptz"
                                abcValue={filters.maxCreated}
                                onAbcValueChange={filters.setMaxCreated}
                            />
                        </ControlGroup>
                    </FormGroup>
                </Col>
                <Col lg={2}>
                    <FormGroup
                        label="Price min/max modified"
                        labelFor="min-max-modified"
                    >
                        <ControlGroup vertical={true} id="min-max-modified">
                            <AbcInput
                                id="min-modified"
                                typeCode="timestamptz"
                                abcValue={filters.minModified}
                                onAbcValueChange={filters.setMinModified}
                            />
                            <AbcInput
                                id="max-modified"
                                typeCode="timestamptz"
                                abcValue={filters.maxModified}
                                onAbcValueChange={filters.setMaxModified}
                            />
                        </ControlGroup>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    {this.renderCategoryRadio()}
                </Col>
                <Col>
                    <RadioGroup
                        label="Has image?"
                        onChange={this.onHasImageSelected}
                        selectedValue={filters.hasImage}
                        inline={true}
                    >
                        <Radio label="All" value={""} />
                        <Radio label="Has image" value={"1"} />
                        <Radio label="No image" value={"0"} />
                    </RadioGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    {res.warnings.length ? <Callout intent={Intent.WARNING}><ul>{
                        "" + res.warnings
                    }</ul></Callout> : null}
                </Col>
            </Row>
            <Row>
                {loading.get() ? <Spinner /> : this.getResults()}
            </Row>


        </Container>
    }
}
