import { ObservableMap, action, makeObservable, observable, runInAction } from "mobx";
import { DialogStack } from "./DialogStack";
import { ViewFrameConfiguration } from "./view/ViewFrameConfiguration";
import { Api } from "../api/Api";
import { CustomRecordEditorComponentFactory } from "../components/frames/record/CustomRecordEditorComponent";
import { DynamicLayoutConfiguration } from "./layout/DynamicLayoutConfiguration";

class UIStore {
    @observable public designMode: boolean = false;
    public dialogStack: DialogStack;
    @observable private viewFrameConfigurations: ObservableMap<string, ViewFrameConfiguration> = observable.map({});
    @observable private layoutConfigurations: ObservableMap<string, DynamicLayoutConfiguration> = observable.map({});
    @observable private customRecordEditorComponentFactories : ObservableMap<number, CustomRecordEditorComponentFactory[]> = observable.map({});


    constructor() {
        makeObservable(this)
        this.dialogStack = new DialogStack();
    }


    /** Clear all data from the store (except 'dialogStack'). Useful for logging out. */
    @action.bound clearAll() {
        this.viewFrameConfigurations.clear();
    }


    @action('UIStore.createViewFrameConfiguration') private createViewFrameConfiguration(path: string, oid: number) {
        this.viewFrameConfigurations.set(path, new ViewFrameConfiguration(path, oid));
    }
    /** Return a viewFrameConfiguration for a given path. Will create if it does not exist yet. */
    public getViewFrameConfiguration = (path: string, oid: number): ViewFrameConfiguration => {
        if (!this.viewFrameConfigurations.has(path)) {
            this.createViewFrameConfiguration(path, oid);
        }
        return this.viewFrameConfigurations.get(path)!;
    }    

    /** Return a layoutConfiguration for a given path. Will create if it does not exist yet. */
    public getlayoutConfiguration = async (path: string): Promise<DynamicLayoutConfiguration> => {
        if (!this.layoutConfigurations.has(path)) {
            try {
                const raw = await Api.userConfig.get_default_cached(path, null);
                const cfg = (raw===null)?new DynamicLayoutConfiguration():DynamicLayoutConfiguration.fromJS(raw);
                runInAction(()=> { this.layoutConfigurations.set(path, cfg);})                
            } catch (error) {
                return Promise.reject(error);
            }
        }
        return this.layoutConfigurations.get(path)!;
    }

    public savelayoutConfiguration = async (path:string, layoutConfiguration: DynamicLayoutConfiguration): Promise<void> => {
        return Api.userConfig.put(path, layoutConfiguration.toJS());
    }

    public getCustomRecordEditorComponentFactories = (oid: number): CustomRecordEditorComponentFactory[] => {
        if (this.customRecordEditorComponentFactories.has(oid)) {
            return this.customRecordEditorComponentFactories.get(oid)!;
        } else {
            return [];
        }        
    }

    public registerCustomRecordEditorComponentFactory = (oid: number, factory: CustomRecordEditorComponentFactory) => {
        if (!this.customRecordEditorComponentFactories.has(oid)) {
            this.customRecordEditorComponentFactories.set(oid, [factory]);
        } else {
            this.customRecordEditorComponentFactories.get(oid)!.push(factory);
        }
    }

}

export const uiStore = new UIStore();