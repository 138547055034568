import * as React from "react";
import { observer } from "mobx-react";
import { RecordSourceRelated, IRecordSourceRelatedProps } from "./RecordSourceRelated";
import { IComponentCollection, ChainedComponentCollection } from "../layout/ComponentCollection";
import { IComponentProvider } from "../layout/ComponentProvider";
import { DynamicLayout } from "../layout/DynamicLayout";
import { FieldInputRenderer } from "./FieldInputRenderer";
import { RecordActionButtons } from "./RecordActionButtons";
import { IReactionDisposer, runInAction, reaction, makeObservable } from "mobx";
import { ListEditorFrame } from "../composite/ListEditorFrame";
import { uiStore } from "../../../store/UIStore";
import { Api } from "../../../api/Api";

@observer
export class RecordEditorFrame extends RecordSourceRelated implements IComponentCollection {
    private actionButtons: RecordActionButtons;

    // Components generated for individual fields
    protected fieldComponents: Map<string, IComponentProvider> = new Map<string, IComponentProvider>();

    // Components generated for detail tables
    protected detailComponents: Map<string, IComponentProvider> = new Map<string, IComponentProvider>();
    private detailReactions: IReactionDisposer[] = [];

    // Custom components registered in the uiStore
    protected customComponents: Map<string, IComponentProvider> = new Map<string, IComponentProvider>();

    // This is a chained collection that contains all of the above, plus the ones passed in props.
    private components: IComponentCollection;

    constructor(props: IRecordSourceRelatedProps) {
        super(props);
        this.actionButtons = new RecordActionButtons(props);
        this.addDetailComponentProviders();
        this.addCustomComponentProviders();
        this.components = this._createComponentCollection(props);
        makeObservable(this, {})
    }

    public componentDidUpdate(prevProps: IRecordSourceRelatedProps) {
        if (prevProps.components !== this.props.components) {
            this.components = this._createComponentCollection(this.props);
        }
    }

    private _createComponentCollection(props: IRecordSourceRelatedProps): IComponentCollection {
        const chained = new ChainedComponentCollection();
        chained.addCollection(this);
        if (props.components !== undefined) {
            chained.addCollection(props.components);
        }
        chained.addCollection(this.detailComponents);
        chained.addCollection(this.customComponents);
        return chained;
    }

    protected addDetailComponentProviders() {
        /* For all fields that are referencing this table */
        this.meta.referenced_by.forEach(fkMeta => {
            const rel = Api.meta.get_relation_meta(fkMeta.referenced)
            const attrs = fkMeta.referring_attrs()
            if (attrs.length === 1) {
                console.log("TODO !!!! exclude addDetailComponentProviders is field not technical !!! ???")
                const attr = attrs[0]
                // Create a sub-storePath for the detail page. This defined its ViewSource storage path in the store.
                const storagePath = this.storagePath + "<" + rel.oid + "." + attr.name;
                // Create the detail page itself.
                console.log("TODO!!! addDetailComponentProviders: we should get the default view for this table here!")
                const detailPage = new ListEditorFrame({ tableOid: rel.oid, viewOid: rel.oid, storagePath });
                runInAction(() => {
                    detailPage.viewSource.needMasterFilterColumn = attr.name;
                })
                // Connect our master with the detail.
                this.detailReactions.push(reaction(
                    () => this.recordId,
                    () => {
                        runInAction(() => {
                            detailPage.masterFilter.equals[attr.name] = this.recordId;
                        })
                    }
                ));
                // We provide this "details" component to our DynamicLayout.
                this.detailComponents.set(detailPage.key, detailPage);
            }
        });
    }

    /* TODO: this would never be called because there is no "destructor" concept. This is a HUGE problem! */
    protected removeDetailComponentProviders() {
        this.detailReactions.forEach(disposer => disposer());
        this.detailReactions.splice(0);
    }

    protected addCustomComponentProviders() {
        uiStore.getCustomRecordEditorComponentFactories(this.props.oid).forEach(factory => {
            const component = factory(this.props);
            // console.log("Adding ",component.key, "to", this.props.tablePath);
            this.customComponents.set(component.key, component);
        });
    }

    public forEach(callbackfn: (value: IComponentProvider, key: string) => void, thisArg?: any): void {
        this.meta.attrs.forEach(attr => {
            callbackfn(this.componentProvider(attr.name), attr.name);
        });
        callbackfn(this.actionButtons, this.actionButtons.key);
    }

    public get(key: string): IComponentProvider | undefined {
        return this.componentProvider(key);
    }

    public has(key: string): boolean {
        return (key === this.actionButtons.key) || !!this.meta.attr_nti[key];
    }

    public get size(): number {
        return this.meta.attrs.length + 1;
    }

    private componentProvider(key: string): IComponentProvider {
        if (key === this.actionButtons.key) {
            return this.actionButtons;
        } else {
            return new FieldInputRenderer({ ...this.props, fieldName: key, key, onKeyPress: this.notifyIfEnterPressed });
        }
    }

    public renderFieldInput = (fieldName: string, key: string) => {
        return <FieldInputRenderer
            key={key}
            oid={this.props.oid}
            storagePath={this.props.storagePath}
            fieldName={fieldName}
            onKeyPress={this.notifyIfEnterPressed}
        />;
    }

    private notifyIfEnterPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event && event.key.toLowerCase() === "enter") {
            this.saveChanges();
        }
    };

    public render() {
        return <DynamicLayout storagePath={this.storagePath + ".layout"} collection={this.components} />
    }

}
