import { Button, Callout, Classes, Dialog, FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import ReCAPTCHA from "react-google-recaptcha";

import { computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { ChangeEvent, SyntheticEvent } from "react";
import { Api } from "../api/Api";
import { dispatcher } from "../App";
import { isValidEmail } from "../util";
import { getServerConfig } from "../connection/comm";

interface ILoginPageProps {
    onLoginSuccess: () => void;
}

@observer
export default class LoginPage extends React.PureComponent<ILoginPageProps> {
    @observable private email: string = process.env.REACT_APP_DEFAULT_LOGIN_EMAIL || "";
    @observable private password: string = process.env.REACT_APP_DEFAULT_LOGIN_PASSWORD || "";
    @observable private captcha_token: string = "";
    @observable private errormessage: string = "";
    @observable private overlaymessage: string = "";
    @observable private checking: boolean = false;

    @observable private catpchaKey: number = 1;

    constructor(props: ILoginPageProps) {
        super(props)
        makeObservable(this)
    }

    @computed get captchaIsMissing(): boolean {
        if (getServerConfig().recaptcha_site_key_v2) {
            return !this.captcha_token;
        } else {
            return false;
        }
    }

    @computed get forgotEnabled(): boolean {
        return !!this.email && isValidEmail(this.email)  && !this.captchaIsMissing;
    }

    @computed get loginEnabled(): boolean {
        return this.forgotEnabled && !!this.password && this.password.length > 4;
    }

    onCaptchaChange = (token: string|null) => {
        runInAction(() => { this.captcha_token = token || "" })
    }

    public render() {
        const errormessage = this.errormessage ? this.errormessage.toString() : null;
        if (this.overlaymessage) {
            return <Dialog
                isOpen={true}
                icon={IconNames.LOG_IN}
                title="Login"
                onClose={this.closeDialog}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        label="E-mail"
                        labelFor="login-input"
                    >
                        <InputGroup
                            id="login-input"
                            value={this.email}
                            leftIcon={IconNames.TAG}
                            disabled={true}
                        />
                    </FormGroup>
                    <Callout intent={Intent.WARNING}>
                        {this.overlaymessage}
                    </Callout>
                </div>
            </Dialog>

        }
        return <Dialog
            isOpen={true}
            icon={IconNames.LOG_IN}
            title="Login"
            onClose={this.closeDialog}
        >
            <div className={Classes.DIALOG_BODY}>
                <FormGroup
                    label="E-mail"
                    labelFor="login-input"
                    labelInfo="(required)"
                >
                    <InputGroup
                        id="login-input"
                        value={this.email}
                        leftIcon={IconNames.TAG}
                        onChange={this.onLoginNameChange}
                        onKeyPress={this.onKeyPress}
                    />
                </FormGroup>
                <FormGroup
                    helperText="Type in your password"
                    label="Password"
                    labelFor="password-input"
                    labelInfo="(required)"
                >
                    <InputGroup
                        id="password-input"
                        placeholder="Type in your password"
                        type="password"
                        leftIcon={IconNames.KEY}
                        value={this.password}
                        onChange={this.onPasswordChange}
                        onKeyPress={this.onKeyPress}
                    />
                </FormGroup>
                {getServerConfig().recaptcha_site_key_v2?
                <ReCAPTCHA
                        sitekey={getServerConfig().recaptcha_site_key_v2}
                        onChange={this.onCaptchaChange}
                        key={this.catpchaKey}
                    />    
                :null}
            </div>            
            <div className={Classes.DIALOG_FOOTER}>
                {errormessage ? (
                    <Callout intent={Intent.DANGER}>{errormessage}</Callout>
                ) : null}
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        intent={Intent.WARNING}
                        icon={IconNames.ENVELOPE}
                        onClick={this.onForgotPassword}
                        loading={this.checking}
                        disabled={!this.forgotEnabled}
                    >
                        Forgot my password
                    </Button>
                    <Button
                        intent={Intent.PRIMARY}
                        icon={IconNames.LOG_IN}
                        onClick={this.onTryLogin}
                        loading={this.checking}
                        disabled={!this.loginEnabled}
                    >
                        Login!
                    </Button>
                </div>
            </div>
        </Dialog>;
    }


    private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key.toLowerCase() === "enter") {
            if (this.loginEnabled) {
                this.onTryLogin(null);
            }
        }
    };

    private onTryLogin = async (event: any) => {
        const captcha_token = this.captcha_token;
        runInAction(() => { this.checking = true; this.captcha_token = ""  })
        try {
            await dispatcher.login(this.email, this.password, captcha_token);
            runInAction(() => { this.checking = false; })
            this.props.onLoginSuccess();
        } catch (error) {
            runInAction(() => { this.checking = false; this.catpchaKey += 1; this.errormessage = "" + error; })
        }
    };

    private onForgotPassword = async (event: any) => {
        const captcha_token = this.captcha_token;
        runInAction(() => { this.checking = true; this.captcha_token = "" })
        try {
            await Api.security.request_password_reset(this.email, captcha_token);
            runInAction(() => {
                this.checking = false;
                this.overlaymessage = "An e-mail with a password reset link has been sent. Please check your inbox.";
            })
        } catch (error) {
            runInAction(() => { this.checking = false; this.catpchaKey += 1; this.errormessage = "" + error; })
        }
    };

    private onLoginNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        runInAction(() => {
            this.email = event.target.value;
            this.errormessage = "";
        })
    };

    private onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        runInAction(() => {
            this.password = event.target.value;
            this.errormessage = "";
        })
    };

    private closeDialog = (event?: SyntheticEvent<HTMLElement, Event> | undefined) => { };
}
