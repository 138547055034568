import * as React from "react";

import { Button, ButtonGroup, Classes, H5, Icon, Intent, MenuItem, Popover, ProgressBar, Spinner, Tooltip } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';
import { UUID } from "crypto";
import { ObservableMap, makeObservable, observable, runInAction, toJS } from "mobx";
import { observer } from 'mobx-react';
import { Col, Container, Row } from "react-grid-system";
import { dispatcher } from "../../App";
import { HStore, JournaledRecord } from "../../data/abc/record";
import { showError } from "../../dialog/Notification";

import Lightbox from 'react-spring-lightbox';

import { differenceInSeconds } from "date-fns";
import _ from "lodash";
import { ImagesListItem } from "react-spring-lightbox/dist/types/ImagesList";
import { timestampConverter } from "../../data/abc/timestamptz";
import "./ProductMjRev1Page.css";
import { ItemRenderer, Select } from "@blueprintjs/select";
//import { UUID } from "crypto"; !!!! USE THIS INSTEAD OF IUUID!

const LANG_CODE = "en"; // TODO!
const AUTO_SAVE_SECONDS = 20;

enum ProductFileStatus {
    NEED_REVIEW = 'need_review',
    COMPLETE = 'complete',
    BAD = 'bad'
}

/*
enum ProductStatus {
    GEN_1 = 'gen_1',
    REV_1 = 'rev_1',
    BAD_1 = 'bad_1',
    GEN_2 = 'gen_2',
    GEN_OK = 'gen_ok'
}
*/

export type ProductRev1Category = {
    id: UUID
    code: string
    title: HStore
    template_cnt : number
}

export type ProductRev1 = JournaledRecord & {
    title: HStore;
    seo_title: HStore;
    description: HStore;
    keyphrase_id: UUID;
    gen_batch_id: UUID;
    prompt: string;
    product_status_id: UUID;
    keyphrase: HStore;
    images: ProductImageRecord[];
}

export type ProductImageRecord = JournaledRecord & {
    product_id: UUID;
    product_file_type_id: UUID;
    file_id: UUID;
    product_file_status_id: UUID;
    dl_url: string;
    file_tag_ids: UUID[];
}

export type CodeTitleDescRecord = {
    id: UUID;
    code: string;
    title: HStore;
    description: HStore;
}

export type FileTagRecord = CodeTitleDescRecord & { icon: string; style: React.CSSProperties }

export type ProductRev1Result = {
    products: ProductRev1[];

    // Static data, provided for looking up status/flag/tag values

    product_statuses: { [product_status_id: string]: CodeTitleDescRecord }
    product_file_statuses: { [product_file_status_id: string]: CodeTitleDescRecord }
    product_file_types: { [product_file_type_id: string]: CodeTitleDescRecord }
    file_tags: FileTagRecord[] // uuid values for assigned tags
}

type ProductImagesItem = ImagesListItem & {
    productIdx: number;
    imageIdx: number;
}

type ImageChange = {
    imageIdx: number;

    product_file_status_id: UUID | null;
    status_changed: Date | null;
    file_tag_ids: UUID[] | null; // List of tags, or null - where null represents "do not change"
    tags_changed: Date | null;

}

type ProductChange = {
    // Connects to rev1 array, see below
    productIdx: number;


    // Actual changes, requested by user
    imagechanges: ImageChange[]; // List of image changes - number of records must equal to product.images.length

    // This is calculated by _pcUpdateHasChange, tells if the product has a change,
    // and how much time is left to revert it.
    changed: Date | null;
    remainingSeconds: number | null;
    remainingRatio: number | null;
    // reserved
    saving: boolean;
    saved: boolean;
}

type ProductChangeSave = {
    product_id: string;
    product_files: ProductFileChange[]
}

type ProductFileChange = {
    product_file_id: UUID;
    product_file_status_id: UUID | null;
    product_file_tag_ids: UUID[] | null;
}

const EMPTY_PRODUCT_CHANGE: Omit<ProductChange, "productIdx"> = { imagechanges: [], changed: null, remainingRatio: null, remainingSeconds: null, saving: false, saved: false }
const EMPTY_IMAGE_CHANGE: Omit<ImageChange, "imageIdx"> = { product_file_status_id: null, status_changed: null, file_tag_ids: null, tags_changed: null }

// Tells if the product list is currently loading.
const loading = observable.box<boolean>(true);


const rev1cats = observable.box<ProductRev1Category[]>([])
const selectedCategory =  observable.box<ProductRev1Category|null>(null)

// This is the main database, returned by the main API of this page.
// This is (re)loaded by reloadRev1()
const rev1 = observable.box<ProductRev1Result | null>(null);
// Maps file status codes to file statuses. Updated by reloadRev1()
const fileStatuses: ObservableMap<string, CodeTitleDescRecord> = observable.map({});
// Maps product status codes to product statuses. Updated by reloadRev1()
const productStatuses: ObservableMap<string, CodeTitleDescRecord> = observable.map({});
// This keeps track of all unsaved product changes, keyed by product.id.value
const productChanges: ObservableMap<string, ProductChange> = observable.map({});

const reloadRev1Cats = async () => {
    try {
        runInAction(() => {
            loading.set(true);
        });

        const data = await dispatcher.call<ProductRev1Category[]>("product.get_rev_1_categories")

        runInAction(() => {
            rev1cats.set(data)
            loading.set(false);
        })

    } catch (error) {
        runInAction(() => {
            loading.set(false);
        })
        showError(error)
    }
}


// Load products from server to be reviewed
const reloadRev1 = async () => {
    try {
        runInAction(() => {
            loading.set(true);
        });
        const data = await dispatcher.call<ProductRev1Result>("product.get_rev_1", {category_id: selectedCategory.get()!.id})
        runInAction(() => {
            rev1.set(data);
            productChanges.clear();
            // Set all images
            const i: ProductImagesItem[] = [];
            const im = new Map<string, number>();
            data.products.forEach((product, productIdx) => {
                product.images.forEach((image, imageIdx) => {
                    i.push({
                        src: `/media/file/${image.file_id}`,
                        loading: 'lazy',
                        alt: image.dl_url,
                        productIdx, imageIdx
                    })
                    im.set(hashProductImageIdx(productIdx, imageIdx), i.length - 1);
                })
            })
            const fsc = new Map<string, CodeTitleDescRecord>();
            Object.values(data.product_file_statuses).forEach((status) => {
                fsc.set(status.code, status);
            })
            const psc = new Map<string, CodeTitleDescRecord>();
            Object.values(data.product_statuses).forEach((status) => {
                psc.set(status.code, status);
            })
            
            fileStatuses.replace(fsc);
            productStatuses.replace(psc);
            lbImages.replace(i)
            lbImageMap.replace(im);
            lbImageIdx.set(0);
            loading.set(false);
        })
    } catch (error) {
        showError(error)
    }
}

// utility function to select maximum of dates
const maxDate = (dates: (Date | null)[]): Date | null => {
    let result: Date | null = null;
    dates.forEach(d => {
        if (
            result === null
            || (d !== null && result !== null && d.getTime() > result.getTime())
        ) {
            result = d;
        }
    })
    return result;
}

// Automatically save products that have changes, after AUTO_SAVE_SECONDS
const autoSaver = () => {
    if (!loading.get()) {
        const now = new Date();
        productChanges.forEach((pc: ProductChange, product_id: string) => {
            if (pc.changed !== null && !pc.saving && !pc.saved) {
                const diffSec = differenceInSeconds(now, pc.changed);
                const remSec = AUTO_SAVE_SECONDS - diffSec;
                runInAction(() => {
                    pc.remainingSeconds = remSec < 0 ? 0 : remSec;
                    pc.remainingRatio = pc.remainingSeconds / AUTO_SAVE_SECONDS
                })
                if (remSec < 0) {
                    saveProductChange(pc)
                }
            }
        })
    }
    setTimeout(autoSaver, 500);
}
setTimeout(autoSaver, 500);

const saveProductChange = async (pc: ProductChange) => {
    runInAction(() => { pc.saving = true; });
    const product = toJS(rev1.get()!.products[pc.productIdx]);
    let toSave: ProductChangeSave = { product_id: toJS(product.id), product_files: [] }
    const imagechanges = toJS(pc.imagechanges)
    imagechanges.forEach((imagechange, imgIdx) => {
        const image = product.images[imgIdx]
        if (imagechange.product_file_status_id !== null || imagechange.file_tag_ids !== null) {
            toSave.product_files.push({
                product_file_id: image.id,
                product_file_status_id: imagechange.product_file_status_id,
                product_file_tag_ids: imagechange.file_tag_ids
            });
        }
    })
    console.log("toSave", toSave)
    try {
        await dispatcher.call<void>("product.put_rev_1", { rev1_data: toSave })
        runInAction(() => { 
            pc.saving = false; 
            pc.saved = true; 
        });
    } catch (error) {
        showError(error)
        runInAction(() => { pc.saving = false; });
    }
}

// Revert all changes for a product, this includes all file flags, all status changes etc.
const revertProductChanges = (productId: UUID) => {
    runInAction(() => {
        const pc = productChanges.get(productId);
        if (pc) {
            const imagechanges: ImageChange[] = [];
            pc.imagechanges.forEach(ic => {
                imagechanges.push({ ...EMPTY_IMAGE_CHANGE, imageIdx: ic.imageIdx });
            })
            Object.assign(pc, { ...EMPTY_PRODUCT_CHANGE, imagechanges });
        }
    })
}

// Revert changes for a product image, this includes file status and file flag changes
const revertProductImage = (productIdx: number, imageIdx: number) => {
    //const product = rev1.get()!.products[productIdx]
    runInAction(() => {
        const pc = _obtainProductChange(productIdx);
        pc.imagechanges[imageIdx] = { ...EMPTY_IMAGE_CHANGE, imageIdx }
        // Calculate changes
        _pcUpdateHasChange(pc)
        //productChanges.set(product.id.value, pc);
    })
}

/* Obtain a ProductChange for a product index. It does not store back the change, just gets or creates. */
const _obtainProductChange = (productIdx: number): ProductChange => {
    const product = rev1.get()!.products[productIdx]
    let pc: ProductChange | undefined = productChanges.get(product.id);
    if (pc === undefined) {
        const imagechanges: ImageChange[] = [];
        product.images.forEach((image, imageIdx) => { imagechanges.push({ ...EMPTY_IMAGE_CHANGE, imageIdx }) })
        return { ...EMPTY_PRODUCT_CHANGE, imagechanges, productIdx };
    } else {
        return pc
    }
}

// Helper function that tells if a file tag set is different from the original
// orig_tag_uuids should be taken from ProductImageRecord.file_tag_ids
// new_tag_ids should be taken from ImageChange.file_tags
const _fileTagSetIsDifferent = (orig_tag_uuids: UUID[], new_tag_ids: string[] | null) => {
    if (new_tag_ids === null) {
        return false; // This represents "no change"
    }
    const orig_tag_ids = orig_tag_uuids.map(uuid => uuid);
    const diff = _.xor(orig_tag_ids, new_tag_ids);
    return diff.length > 0
}

/* Calculate if a product was changed, set changed, remainingSeconds, remainingRatio etc. */
const _pcUpdateHasChange = (pc: ProductChange) => {
    runInAction(() => {
        let changed: Date | null = null;
        pc.imagechanges.forEach(ic => {
            changed = maxDate([changed, ic.status_changed, ic.tags_changed]);
        })
        pc.changed = changed;
        if (changed === null) {
            pc.remainingSeconds = null;
            pc.remainingRatio = null;
        } else {
            pc.remainingSeconds = AUTO_SAVE_SECONDS;
            pc.remainingRatio = 1.0;
        }
    })
}

// Set file status of a product_file (image)
const setProductImageStatus = (productIdx: number, imageIdx: number, newStatusId: UUID, force:boolean) => {
    const product = rev1.get()!.products[productIdx]
    const image = product.images[imageIdx];
    const origStatusId = image.product_file_status_id;
    const isRevert = !force && (origStatusId === newStatusId);
    runInAction(() => {
        const now = new Date();
        const pc = _obtainProductChange(productIdx);
        const imagechange = pc.imagechanges[imageIdx];
        // Set status of image as requested
        if (isRevert) {
            imagechange.product_file_status_id = null;
            imagechange.status_changed = null;
        } else {
            imagechange.product_file_status_id = newStatusId;
            imagechange.status_changed = now;
        }
        // might have changed, store back
        pc.imagechanges[imageIdx] = imagechange
        // Calculate changes
        _pcUpdateHasChange(pc)
        // Store changes into local db
        productChanges.set(product.id, pc);
    })
}

// Add or remove a file_tag for a given product_file (image).
// tagId is the uuid value for the tag, value tells if it needs to be added or removed.
// value=null means toggle
const setProductImageTag = (productIdx: number, imageIdx: number, tagId: UUID, value: boolean | null) => {
    const product = rev1.get()!.products[productIdx]
    const image = product.images[imageIdx]
    //console.log("setProductImageTag", productIdx, imageIdx, tagId)
    runInAction(() => {
        const pc = _obtainProductChange(productIdx);
        const imagechanges = pc.imagechanges[imageIdx];
        // Current state that needs to be changed
        const changedTagIds = (imagechanges.file_tag_ids || image.file_tag_ids).concat();
        const ftIdx = changedTagIds.indexOf(tagId);
        // Changed (not yet saved) has the flag
        const changedHasit = ftIdx>=0;
        // Do we want it? toggle/value
        const wantIt = value===null? !changedHasit : value;
        
        if (wantIt && !changedHasit) {
            changedTagIds.push(tagId)
        }
        if (!wantIt && changedHasit) {
            changedTagIds.splice(ftIdx, 1) 
        }
        // Compare the original with the new
        if (_fileTagSetIsDifferent(image.file_tag_ids, changedTagIds)) {
            imagechanges.file_tag_ids = changedTagIds;
            imagechanges.tags_changed = new Date();
        } else {
            imagechanges.file_tag_ids = null;
            imagechanges.tags_changed = null;
        }
        // store back, something might have changed
        pc.imagechanges[imageIdx] = imagechanges
        // Calculate changes
        _pcUpdateHasChange(pc)
        // Store changes into local db
        productChanges.set(product.id, pc);
    })
}

const setAllProductImageStatus = (productIdx: number, fileStatusId: UUID) => {
    const product = rev1.get()!.products[productIdx];
    product.images.forEach( (image, imageIdx) => {
        console.log("setProductImageStatus", productIdx, imageIdx, fileStatusId)
        setProductImageStatus(productIdx, imageIdx, fileStatusId, true)
    })
}


const setAllProductImageStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    const productIdx = parseInt(event.currentTarget.getAttribute("data-product-idx")!);
    const fileStatusId = event.currentTarget.getAttribute("data-product-file-status-id")! as UUID;
    setAllProductImageStatus(productIdx, fileStatusId);
}

const setAllProductImageStatusClickToTheLeft = (event: React.MouseEvent<HTMLElement>) => {
    const productIdx = parseInt(event.currentTarget.getAttribute("data-product-idx")!);
    const completeStatus = fileStatuses.get(ProductFileStatus.COMPLETE)!;
    const completeStatusId = completeStatus.id;
    console.log("setAllProductImageStatusClickToTheLeft ???")
    for (let pidx=0; pidx <= productIdx; pidx++) {
        const product = rev1.get()!.products[pidx];
        // very important, we only change products that are visible on the screen
        // visible products are the ones that has no change yet, or are not saved
        const pc = productChanges.get(product.id)
        if (!pc || !pc.saved) {
            product.images.forEach( (image, imageIdx) => {
                console.log("setProductImageStatus", pidx, imageIdx, completeStatusId)
                setProductImageStatus(pidx, imageIdx, completeStatusId, true)
            })
        }
    }
}

/*
const generateMoreClick = async (event: React.MouseEvent<HTMLElement>) => {
    const productIdx = parseInt(event.currentTarget.getAttribute("data-product-idx")!);
    const productStatusId = event.currentTarget.getAttribute("data-product-status-id")!;
    const product = rev1.get()!.products[productIdx];
    const pc = _obtainProductChange(productIdx);
    runInAction(() => { pc.saving = true; });

    if (await ConfirmDialog.open("Confirm", 
        "This operation will send back the product and generate more images. " +
        "After that, the product will come back to this page for review. " +
        "This cannot be revoked. Are you sure??")) {

        try {
            await dispatcher.call<void>("product.set_product_status", { product_id: product.id, product_status_id: productStatusId })
            runInAction(() => { 
                pc.saving = false; 
                pc.saved = true; 
                productChanges.set(product.id, pc);
            });
        } catch (error) {
            showError(error)
            runInAction(() => { 
                pc.saving = false; 
                productChanges.set(product.id, pc);
            });
        }    
    } else {
        runInAction(() => { pc.saving = false; });
    }
}
*/

// Fancy display - change intent (color) when revert window is near
const getAutoSaveIntent = (remainingSeconds: number) =>
    remainingSeconds! > 10 ? Intent.PRIMARY
        : remainingSeconds! > 5 ? Intent.WARNING
            : Intent.DANGER;
// Fancy display - change text when revert window is near
const getAutoSaveText = (remainingSeconds: number) =>
    remainingSeconds > 0
        ? `Autosave in ${remainingSeconds}s, click to revert`
        : 'Autosave imminent';

// This is an UI database that flattens rev1 into a flat array, used by lightbox
// flattened array of images
const lbImages = observable.array<ProductImagesItem>([]);
// maps hashProductImageIdx(productId,imageIdx) to flattened array index
const lbImageMap: ObservableMap<string, number> = observable.map({});
// is lighbox opened
const lbIsOpen = observable.box(false);
// index of the currently displayed image (in lightbox)
const lbImageIdx = observable.box(0);

// Hashed productIdx,imageIdx to a string that is used to map images to flattened array incides.
const hashProductImageIdx = (productIdx: number, imageIdx: number): string => {
    return `${productIdx}_${imageIdx}`;
}

// Reverse map: convert flattened image idx to productIdx + imageIdx
const lbImageIdxToProductImageIdx = (lbImageIdx: number): { productIdx: number, imageIdx: number } => {
    const item = lbImages[lbImageIdx];
    return { productIdx: item.productIdx, imageIdx: item.imageIdx }
}

// Close lightbox
const closeLightbox = () => { runInAction(() => { lbIsOpen.set(false) }) }
// Open a specific image in lightbox
const openLightbox = (productIdx: number, imageIdx: number) => {
    runInAction(() => {
        const idx = lbImageMap.get(hashProductImageIdx(productIdx, imageIdx));
        if (idx !== undefined) {
            lbImageIdx.set(idx);
            lbIsOpen.set(true)
        }
    }
    )
}
// Lightbox, go to next image
const gotoNextImage = () => {
    const idx = lbImageIdx.get();
    if (idx >= 0 && lbImages.length > 1 && idx + 1 < lbImages.length) {
        runInAction(() => { lbImageIdx.set(idx + 1); })
    }
}
// Lightbox, go to prev image
const gotoPreviousImage = () => {
    const idx = lbImageIdx.get();
    if (idx > 0 && lbImages.length > 1) {
        runInAction(() => { lbImageIdx.set(idx - 1); })
    }
}

// Produces an image overlay for the image that is currently in lightbox
const currentLbProductImageOverlay = () => <ProductImageOverlay {...lbImageIdxToProductImageIdx(lbImageIdx.get())} showCountdown={true} />

// This component displays the lightbox "dialog"
@observer
export class ProductsLightbox extends React.PureComponent<{}> {
    constructor(props:{}) {
        super(props)
        makeObservable(this, {})
    }

    render() {
        return (
            <Lightbox
                isOpen={lbIsOpen.get()}
                onPrev={gotoPreviousImage}
                onNext={gotoNextImage}
                images={lbImages}
                currentIndex={lbImageIdx.get()}
                onClose={closeLightbox}
                /* Add your own UI */
                renderHeader={() => (<LightboxHeader />)}
                // renderFooter={() => (<CustomFooter />)}
                // renderPrevButton={() => (<CustomLeftArrowButton />)}
                // renderNextButton={() => (<CustomRightArrowButton />)}
                renderImageOverlay={currentLbProductImageOverlay}

            /* Add styling */
            // style={{ background: "grey" }}

            /* Use single or double click to zoom */
            // singleClickToZoom

            /* react-spring config for open/close animation */
            // pageTransitionConfig={{
            //   from: { transform: "scale(0.75)", opacity: 0 },
            //   enter: { transform: "scale(1)", opacity: 1 },
            //   leave: { transform: "scale(0.75)", opacity: 0 },
            //   config: { mass: 1, tension: 320, friction: 32 }
            // }}
            />
        );
    }
}

const LightboxHeader = () => {
    const img = lbImages[lbImageIdx.get()];
    const product = rev1.get()!.products[img.productIdx];
    return <H5 className={Classes.DARK}><b>{product.keyphrase && product.keyphrase["en"]}</b> | {product.prompt}</H5>
}

// Open lightbox when an image is clicked
const onProductImageClick = (event: React.MouseEvent<HTMLElement>) => {
    const productIdx = event.currentTarget.getAttribute("data-product-idx");
    const imageIdx = event.currentTarget.getAttribute("data-image-idx");
    if (productIdx !== null && imageIdx !== null) {
        openLightbox(parseInt(productIdx), parseInt(imageIdx));
    }
}

// Toggle file tag
const onProductImageTagClick = (event: React.MouseEvent<HTMLElement>) => {
    const productIdx = event.currentTarget.getAttribute("data-product-idx");
    const imageIdx = event.currentTarget.getAttribute("data-image-idx");
    const tagId = event.currentTarget.getAttribute("data-tag-id")! as UUID;
    if (productIdx !== null && imageIdx !== null) {
        setProductImageTag(parseInt(productIdx), parseInt(imageIdx), tagId, null);
    }
}


type ProductImageOverlayProps = { productIdx: number, imageIdx: number, showCountdown?: boolean }

// Action buttons, on top over every product image
@observer
export class ProductImageOverlay extends React.PureComponent<ProductImageOverlayProps> {

    constructor(props:ProductImageOverlayProps) {
        super(props)
        makeObservable(this, {})
    }


    render() {
        const product = rev1.get()!.products[this.props.productIdx];
        const image = product.images[this.props.imageIdx];
        const origStatusId = image.product_file_status_id;
        const pc = productChanges.get(product.id);
        let newStatusId = origStatusId;
        let file_tag_ids = image.file_tag_ids;
        if (pc) {
            const imagechange = pc.imagechanges[this.props.imageIdx];
            if (imagechange.file_tag_ids != null) {
                file_tag_ids = imagechange.file_tag_ids;
            }
            if (imagechange.product_file_status_id !== null) {
                newStatusId = imagechange.product_file_status_id
            }
        }

        const reviewStatus = fileStatuses.get(ProductFileStatus.NEED_REVIEW)!;
        const completeStatus = fileStatuses.get(ProductFileStatus.COMPLETE)!;
        const badStatus = fileStatuses.get(ProductFileStatus.BAD)!;

        const genStatusButton = (status: CodeTitleDescRecord, icon: any, intent: Intent) => <Button
            text={status.title[LANG_CODE]}
            title={status.description[LANG_CODE]}
            icon={icon}
            intent={newStatusId === status.id ? intent : undefined}
            onClick={() => setProductImageStatus(this.props.productIdx, this.props.imageIdx, status.id, false)}
        />

        const getFileTagButton = (tag: FileTagRecord) => {
            // "Pressed" style
            let pressed = file_tag_ids !== null && file_tag_ids.includes(tag.id);
            const style = pressed ? toJS(tag.style) : undefined;
            return <Button
                /*text={flag.title[LANG_CODE]}*/
                title={tag.description[LANG_CODE] + " " + (tag.description[LANG_CODE] || "")}
                style={style}
                icon={<Icon icon={tag.icon as any} style={style} />}
                data-product-idx={this.props.productIdx}
                data-image-idx={this.props.imageIdx}
                data-tag-id={tag.id}
                onClick={onProductImageTagClick}
            />;
        }
        const fileTags = rev1.get()!.file_tags;

        let revertButton: any = null;
        if (pc && pc.changed !== null) {
            const remSec = pc!.remainingSeconds!;
            const intent = getAutoSaveIntent(remSec)
            let text = "Revert";
            if (this.props.showCountdown) {
                text = getAutoSaveText(remSec);
            }
            revertButton = <Button
                text={text} icon={IconNames.RESET} intent={intent}
                onClick={() => revertProductImage(this.props.productIdx, this.props.imageIdx)}
            />

        }

        return <div className="product_image_overlay">
            <ButtonGroup>
                {genStatusButton(completeStatus, IconNames.TICK, Intent.SUCCESS)}
                {genStatusButton(reviewStatus, IconNames.HELP, Intent.PRIMARY)}
                {genStatusButton(badStatus, IconNames.CROSS, Intent.DANGER)}
                {fileTags.map(ft => getFileTagButton(ft))}
            </ButtonGroup>
            {revertButton}

        </div>
    }
}

type ProductImageProps = { productIdx: number, imageIdx: number }
@observer
export class ProductImage extends React.PureComponent<ProductImageProps> {

    constructor(props:ProductImageProps) {
        super(props)
        makeObservable(this, {})
    }

    /*
    private get imageIdx() {
        return lbImageMap.get(hashProductImageIdx(this.props.productIdx, this.props.imageIdx))
    }
    */

    render() {
        const product = rev1.get()!.products[this.props.productIdx];
        const image = product.images[this.props.imageIdx];
        const status_id = image.product_file_status_id;
        //console.log("image", image, "status_id", status_id);
        const statuses = toJS(rev1.get()!.product_file_statuses);
        //console.log("statuses", statuses);
        const status = statuses[status_id]!;
        return <img
            data-product-idx={this.props.productIdx}
            data-image-idx={this.props.imageIdx}
            onClick={onProductImageClick}
            src={`/media/file/${image.file_id}`}
            title={status.code}
            className="product_image"
            alt=""
        />
    }
}

const productPopover = (product: ProductRev1) => {
    return (
        <span>TODO</span>
    );
}


const productTooltip = (product: ProductRev1) => {
    return (
        <span>
            <b>Created: </b> {timestampConverter.abcToString(product.c_tim)}
            &nbsp;
            <b>Modified: </b>{timestampConverter.abcToString(product.m_tim)}
        </span>
    );
}

type ProductProps = { productIdx: number }

@observer
export class Product extends React.PureComponent<ProductProps> {

    constructor(props:ProductProps) {
        super(props)
        makeObservable(this, {})
    }

    private get product() {
        return rev1.get()!.products[this.props.productIdx];
    }

    /*
    private get images() {
        return this.product.images;
    }
    */



    render() {
        const product = this.product;
        const pc = productChanges.get(product.id);
        let revertButton: any = null;
        let saveButton : any = null;
        let saveProgressBar: any = null;
        if (pc && pc.remainingRatio !== null && pc.remainingSeconds != null) {
            const intent = getAutoSaveIntent(pc.remainingSeconds!);
            const text = getAutoSaveText(pc.remainingSeconds!);
            revertButton = <Button
                large
                intent={intent}
                title="Revert all changes"
                onClick={() => revertProductChanges(product.id)}
                icon={IconNames.RESET}
                text={text}
            />
            saveButton = <Button
                large
                intent={Intent.SUCCESS}
                text="Save now"
                icon={IconNames.FLOPPY_DISK}
                onClick={() => saveProductChange(pc)}
            />
            saveProgressBar = <ProgressBar
                animate
                intent={intent}
                value={pc.remainingRatio}
            />
        }
        const completeFileStatus = fileStatuses.get(ProductFileStatus.COMPLETE)!;
        const badFileStatus = fileStatuses.get(ProductFileStatus.BAD)!;
        //const gen1FileStatus = productStatuses.get(ProductStatus.GEN_1)!;

        return <Col key={product.id} className="product" xxxl={2} xxl={3} xl={3} lg={3} md={4} sm={12}>
            <Popover content={productPopover(product)} popoverClassName={Classes.POPOVER_CONTENT_SIZING}>
                <Tooltip content={productTooltip(product)} className={Classes.TOOLTIP_INDICATOR}>
                    <Button large><b>{product.keyphrase && product.keyphrase["en"]}</b> | {product.prompt}</Button>
                </Tooltip>
            </Popover>
            <Button 
                large
                text=""
                title="Set status to 'Complete' for all images of this product and all products before it in the list"
                icon={IconNames.KEY_ENTER}
                intent={Intent.PRIMARY}
                data-product-idx={this.props.productIdx}
                data-product-file-status-id={completeFileStatus.id}
                onClick={setAllProductImageStatusClickToTheLeft}
            />
            <Button 
                large
                text=""
                title="Set status to 'Complete' for all images of this product"
                icon={IconNames.TICK_CIRCLE}
                intent={Intent.SUCCESS}
                data-product-idx={this.props.productIdx}
                data-product-file-status-id={completeFileStatus.id}
                onClick={setAllProductImageStatusClick}
            />
            <Button 
                large
                text=""
                title="Set status to 'Bad' for all images of this product"
                icon={IconNames.CROSS_CIRCLE}
                intent={Intent.DANGER}
                data-product-idx={this.props.productIdx}
                data-product-file-status-id={badFileStatus.id}
                onClick={setAllProductImageStatusClick}
            />
            {/*
            <Button 
                large
                text=""
                title="Press this to generate more images"
                icon={IconNames.MEDIA}
                intent={Intent.NONE}
                data-product-idx={this.props.productIdx}
                data-product-status-id={gen1FileStatus.id}
                onClick={generateMoreClick}
            />  
            */}          
            {revertButton}
            {saveButton}
            {saveProgressBar}
            <Container fluid>
                <Row>
                    {this.product.images.map((image, imageIdx) =>
                        <Col className="product_image_container" sm={12}
                        >
                            <ProductImage key={image.id} productIdx={this.props.productIdx} imageIdx={imageIdx} />
                            <ProductImageOverlay productIdx={this.props.productIdx} imageIdx={imageIdx} />
                        </Col>
                    )}
                </Row>
            </Container>
        </Col>
    }
}



const renderRevCat: ItemRenderer<ProductRev1Category> = (category, { handleClick, handleFocus, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }

    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={category.id}
            intent={category.template_cnt?Intent.NONE:Intent.DANGER}
            label={category.code + " " + category.title["en"] + " templates=" + category.template_cnt}
            onClick={handleClick}
            onFocus={handleFocus}
            roleStructure="listoption"
            text={`${category.code}. ${category.title["en"]}`}
        />
    );
};

const setSelectedCategory = (item: ProductRev1Category|null) => {
    runInAction(() => {
        selectedCategory.set(item)
        reloadRev1()
    })

}

@observer
export default class ProductMjRev1Page extends React.PureComponent<{}> {

    constructor(props:{}) {
        super(props)
        makeObservable(this, {})
    }

    componentDidMount(): void { 
        reloadRev1Cats() 
    }

    render(): React.ReactNode {
        if (loading.get()) {
            return <Spinner />
        } else {
            return <Container fluid>
                <Row>
                    <Select<ProductRev1Category>
                            items={rev1cats.get()}
                            itemRenderer={renderRevCat}
                            noResults={<MenuItem disabled={true} text="No categories." roleStructure="listoption" />}
                            onItemSelect={setSelectedCategory}
                    >
                            <Button text={
                                selectedCategory.get()?(

                                selectedCategory.get()!.code + " " + selectedCategory.get()!.title["en"]
                                + " templates=" + selectedCategory.get()!.template_cnt

                                ):"Please select a category"

                            } rightIcon="double-caret-vertical" placeholder="Select a category" />
                    </Select>                            
                </Row>
                {selectedCategory.get()&&rev1.get()&&rev1.get()!.products?
                <>
                <Row>
                    {rev1.get()!.products.map((product, productIdx) => {
                        const pc = productChanges.get(product.id)
                        if (!pc || !pc.saved) {
                            return <Product key={product.id} productIdx={productIdx} />;
                        } else {
                            return null;
                        }
                    })}
                </Row>
                <ProductsLightbox /></>:null}
            </Container>
        }
    }
}
