import { Crud } from "./Crud";
import { Meta } from "./Meta";
import { Security } from "./Security";
import { Shelf } from "./Shelf";
import { UserConfig } from "./UserConfig";
import { View } from "./View";


/** 
 * This class provides a namespace and allows you to access all APIs with a single import 
 */
export class Api {
    public static meta = new Meta()
    public static security = new Security()
    public static userConfig = new UserConfig()
    public static view = new View()
    public static crud = new Crud()
    public static shelf = new Shelf()
}
